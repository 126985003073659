<template>
  <v-row class="align-center mb-4" style="justify-content: space-between">
    <v-col cols="12" md="10" sm="12">
      <b-autocomplete
        v-model="selectRelatedId"
        :items="$directory.get('taskList')"
        :loading="$loading.isLoading('taskList')"
        hide-details
        item-title="topic"
        item-value="id"
        label="Задача"
        placeholder="Оберіть зі списку" />
    </v-col>
    <v-col cols="12" md="2" sm="12">
      <v-btn class="mt-4" color="secondary" @click="createRelatedTask">
        <v-icon class="mr-1">{{ 'mdi-plus-box-multiple-outline' }}</v-icon>
        Додати
      </v-btn>
    </v-col>
    <v-col v-if="relatedTasks.length" cols="12" md="12" sm="12">
      <CustomItem
        v-for="task in relatedTasks"
        :key="task.id"
        :item="task"
        hidePost>
        <template #actions>
          <v-btn
            icon
            size="small"
            variant="text"
            @click="unrelatedTask(task.id)">
            <v-icon icon="mdi-delete" size="large" />
          </v-btn>
        </template>
      </CustomItem>
    </v-col>
  </v-row>
</template>

<script>
import CustomItem from '@/dialog/components/templateGroup/CustomItem.vue'
import { urlCreateRelatedTask, urlUnrelatedTask } from '@/utils/urls'
import { ref } from 'vue'
import { useRoute } from '@/plugins'
import { axios } from '@/plugins/index'
import { fillDirectory } from '@/directory/index.ts'

export default {
  name: 'RelatedTask',
  components: { CustomItem },
  props: {
    dialog: { type: Object },
  },
  setup(props) {
    const route = useRoute()
    const relatedTasks = ref(props.dialog.dialogData.task?.related_tasks || [])
    const selectRelatedId = ref(null)

    fillDirectory('taskList')

    const unrelatedTask = id => {
      return axios
        .post(urlUnrelatedTask(+route.params.id), {
          relatedId: id,
        })
        .then(res => {
          if (res.data.related_tasks) {
            relatedTasks.value = res.data?.related_tasks
          } else {
            relatedTasks.value = []
          }
          return res
        })
    }
    const createRelatedTask = () => {
      return axios
        .post(urlCreateRelatedTask(+route.params.id), {
          relatedId: selectRelatedId.value,
        })
        .then(res => {
          selectRelatedId.value = null
          relatedTasks.value = []
          relatedTasks.value = res.data?.related_tasks
          return res
        })
    }

    return {
      selectRelatedId,
      unrelatedTask,
      createRelatedTask,
      relatedTasks,
    }
  },
}
</script>

<style lang="scss" scoped></style>
