<template>
  <Skeleton v-if="!group" />
  <div v-else class="mb-2">
    <v-row class="align-center">
      <v-col cols="12" md="8" sm="12">
        <b-input
          v-model="groupData.name"
          hide-details
          label="Назва групи"
          placeholder="Введіть текст" />
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <slot name="queue-number"></slot>
      </v-col>
      <v-col cols="12" md="1" sm="12">
        <v-tooltip text="Видалити групу">
          <template #activator="{ props }">
            <v-btn
              class="mt-3"
              density="comfortable"
              icon
              v-bind="props"
              @click="$emit('delete-group')">
              <v-icon>{{ 'mdi-delete-empty-outline' }}</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="align-end">
      <v-col cols="12" md="8" sm="12">
        <b-autocomplete
          v-model="selectedUser"
          :items="$directory.get('userList')"
          :loading="$loading.isLoading('userList')"
          hide-details
          item-title="name"
          item-value="id"
          label="Користувачі"
          placeholder="Оберіть користувача"
          @focus="$directory.fill('userList')" />
      </v-col>
      <v-col class="pt-5" cols="12" md="4" sm="12">
        <v-btn class="w-100" color="secondary" @click="handleAccessAddUser">
          <v-icon class="mr-1">{{ 'mdi-plus-box-multiple-outline' }}</v-icon>
          Додати користувача
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col v-if="groupData.users.length" cols="12" md="12" sm="12">
        <CustomItem v-for="user in groupData.users" :key="user.id" :item="user">
          <template #actions>
            <v-btn
              icon
              size="small"
              variant="text"
              @click="removeRouteUser(user.id)">
              <v-icon icon="mdi-delete" size="large" />
            </v-btn>
          </template>
        </CustomItem>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <b-select
          v-model="groupData.agreementId"
          :items="
            $directory.get('templateRouteAgreements', groupData.agreement)
          "
          :loading="$loading.isLoading('templateRouteAgreements')"
          hide-details
          item-title="name"
          item-value="id"
          label="Ким погоджено"
          placeholder="Оберіть зі списку"
          @focus="$directory.fill('templateRouteAgreements')" />
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <b-select
          v-model="groupData.actionId"
          :items="$directory.get('templateRouteActions', groupData.action)"
          :loading="$loading.isLoading('templateRouteActions')"
          hide-details
          item-title="name"
          item-value="id"
          label="Тип"
          placeholder="Оберіть зі списку"
          @focus="$directory.fill('templateRouteActions')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import CustomItem from '@/dialog/components/templateGroup/CustomItem.vue'
import { computed, ref } from 'vue'
import { axios } from '@/plugins'
import {
  urlCreateDocumentTemplateRouteUser,
  urlDeleteDocumentTemplateRouteUser,
} from '@/utils/urls'
import { setSnackbar } from 'best-modules/plugins'

export default {
  name: 'TemplateGroupItem',
  components: { CustomItem, Skeleton },
  emits: ['update:group', 'delete-group'],
  props: {
    group: { type: Object },
  },
  setup(props, { emit }) {
    const addUserAccess = ref(false)
    const selectedUser = ref(null)
    const groupData = computed({
      get: () => {
        return props.group
      },
      set: val => {
        emit('update:group', val)
      },
    })

    const addRouteUser = e => {
      return axios
        .post(urlCreateDocumentTemplateRouteUser(), {
          routeId: groupData.value.id,
          userId: e,
        })
        .then(res => {
          groupData.value.users = [...groupData.value.users, res.data]
          selectedUser.value = null
          return res
        })
    }

    const removeRouteUser = id => {
      return axios.delete(urlDeleteDocumentTemplateRouteUser(id)).then(res => {
        groupData.value.users = groupData.value.users.filter(
          item => item.id !== id
        )
        setSnackbar({ text: 'Користувача видалено', color: 'success' })
        return res
      })
    }

    const handleAccessAddUser = () => {
      if (!selectedUser.value) {
        setSnackbar({ text: 'Спочатку оберіть користувача', color: 'error' })
      } else addRouteUser(selectedUser.value)
    }

    return {
      groupData,
      addRouteUser,
      removeRouteUser,
      addUserAccess,
      handleAccessAddUser,
      selectedUser,
    }
  },
}
</script>

<style lang="scss" scoped></style>
