<template>
  <auth-layout title="Авторизація" subtitle="Електронний підпис документів">
    <div>
      <div style="font-size: 20px">Вітаємо в <b>inTime</b></div>
      <b-form hide-action-buttons @submit="inputCode">
        <div class="text-grey-darken-4 mb-3 mt-3 text-disabled">
          Введіть код, відправлений вам на пошту/телефон
        </div>
        <v-otp-input
          class="justify-start"
          v-model="authData.code"
          :submit="inputCode"
          :error="invalidCode"
          length="6"
          type="number"></v-otp-input>
        <div>
          <v-btn
            class="mb-2"
            variant="plain"
            :disabled="sendCodeTimeout > 0"
            :loading="$loading.isLoading('sendCode')"
            @click="sendCode">
            Відправити код
            <span v-if="sendCodeTimeout > 0">({{ sendCodeTimeout }} сек)</span>
          </v-btn>
          <v-btn
            class="w-100"
            type="submit"
            size="large"
            :loading="$loading.isLoading('inputCode')">
            Підтвердити
          </v-btn>
        </div>
      </b-form>
    </div>
  </auth-layout>
</template>

<script lang="ts">
import AuthLayout from '@/layout/AuthLayout.vue'
import { BForm } from 'best-modules/components'
import { urlClientAccessSendCode, urlClientAccessInputCode } from '@/utils/urls'
import { reactive, ref } from 'vue'
import axios from '@/plugins/axios/index.js'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { handleAsync } from 'best-modules/plugins'

export default {
  name: 'Auth',
  components: { BForm, AuthLayout },
  emits: ['auth-token'],
  props: {
    accessToken: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const authData = reactive({ code: '' })
    const rules = { code: { required } }
    const v$ = useVuelidate(rules, authData)
    const invalidCode = ref(false)

    const getDefaultSendCodeTimeout = () => {
      const t = localStorage.getItem('shareDocumentCodeTimeout')
      if (t) {
        return Number(t)
      } else {
        return 0
      }
    }

    let sendCodeTimeout = ref(getDefaultSendCodeTimeout())
    let interval: number

    const setSendCodeInterval = () => {
      clearInterval(interval)
      interval = setInterval(() => {
        if (sendCodeTimeout.value === 0) {
          clearInterval(interval)
          return
        }
        sendCodeTimeout.value -= 1
        localStorage.setItem(
          'shareDocumentCodeTimeout',
          String(sendCodeTimeout.value)
        )
      }, 1000)
    }

    const sendCode = () => {
      return handleAsync('sendCode', () => {
        return axios
          .get(urlClientAccessSendCode(props.accessToken))
          .then(() => {
            sendCodeTimeout.value = 60
            setSendCodeInterval()
          })
      })
    }

    if (sendCodeTimeout.value === 0) {
      sendCode()
    }
    if (sendCodeTimeout.value > 0) {
      setSendCodeInterval()
    }

    const inputCode = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return handleAsync('inputCode', () => {
          return axios
            .post(urlClientAccessInputCode(), authData)
            .then(res => {
              invalidCode.value = false
              emit('auth-token', res.data.token)
            })
            .catch(e => {
              invalidCode.value = true
              return e
            })
        })
      }
    }

    return { authData, v$, sendCode, inputCode, invalidCode, sendCodeTimeout }
  },
}
</script>

<style scoped lang="scss"></style>
