import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import { auth } from './modules/auth'
import { user } from './modules/user'
import { notifications } from './modules/notifications'
import { files } from './modules/files'

const modules = {
  auth,
  user,
  notifications,
  files,
}

export const key: InjectionKey<Store<typeof modules>> = Symbol()

const store = createStore<typeof modules>({
  modules,
})

export default store
