<template>
  <div>
    <span class="d-flex align-center justify-center" style="font-size: 12px"
      >Порядок</span
    >
    <div class="d-flex align-center justify-center gap-2">
      <v-btn
        :disabled="value === 1"
        icon
        size="small"
        variant="text"
        @click="$emit('decrement')">
        <v-icon>{{ 'mdi-arrow-left' }}</v-icon>
      </v-btn>
      <div class="counter" style="cursor: default">
        <span>{{ value }}</span>
      </div>
      <v-btn
        :disabled="length === value"
        icon
        size="small"
        variant="text"
        @click="$emit('increment')">
        <v-icon>{{ 'mdi-arrow-right' }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QueueNumber',
  emits: ['increment', 'decrement'],
  props: {
    value: {
      type: Number,
    },
    length: {
      type: Number,
    },
  },
}
</script>

<style lang="scss" scoped>
.counter {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #106ac1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}
</style>
