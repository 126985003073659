<template>
  <div>
    <div v-for="(item, idx) in fields" :key="idx" class="info-block">
      <span class="info-block__label">{{ item.label }}</span>
      <div v-if="item.value && item.to" class="info-block__value">
        <div v-if="Array.isArray(item.value)">
          <div v-for="itm in item.value" :key="itm.id">
            <span
              class="link"
              @click="
                $router.push({
                  name: 'single-contractor',
                  params: { id: itm.contractor.id },
                })
              "
              >{{ itm.contractor.name }} ({{ itm.contractor.code }})</span
            >
          </div>
        </div>
        <span
          v-else
          @click="
            $router.push({
              name: 'single-contractor',
              params: { id: itm.id },
            })
          "
          >{{ item.value }}</span
        >
      </div>
      <span v-else class="info-block__value">{{ item.value }}</span>
      <slot :name="`append-${idx + 1}`"></slot>
    </div>
  </div>
</template>

<script>
import { getUserName } from '@/utils/helpers.js'
import { computed, inject } from 'vue'
import { documentActionKey } from '@/pages/documents/injectionKeys.ts'

export default {
  props: {
    document: { type: Object, required: true },
  },
  setup(props) {
    const documentAction = inject(documentActionKey)
    const fields = computed(() => {
      if (documentAction.value === 'agreement') {
        return [
          {
            label: 'Статус',
            value: props.document?.status?.name,
          },
          {
            label: 'Контрагенти',
            value: props.document?.contractors,
            to: {
              name: 'single-contractor',
              params: { id: props.document?.contractor?.id },
            },
          },
          {
            label: 'Автор',
            value: getUserName(props.document?.author),
          },
        ]
      } else {
        return [
          {
            label: 'Статус',
            value: props.document?.status?.name,
          },
          {
            label: 'Автор',
            value: getUserName(props.document?.author),
          },
        ]
      }
    })

    return { fields }
  },
}
</script>

<style lang="scss" scoped></style>
