<template>
  <div>
    <div v-if="!absences.length">
      <div class="d-flex flex-column align-center">
        <v-icon height="45px" icon="best:vacation" width="45px" />
        <span class="opacity-40 font-normal">Відпустки не додані</span>
      </div>
    </div>
    <div v-else>
      <v-data-table
        :disable-pagination="true"
        :headers="columns"
        :hide-default-footer="true"
        :hide-default-header="!absences.length"
        :items="absences"
        :items-per-page="-1"
        dense>
        <template #item.type="{ item }">
          {{ item.type?.name }}
        </template>
        <template #item.deputy="{ item }">
          {{ getUserName(item.deputy) || '---' }}
        </template>
        <template #item.delete="{ item }">
          <div class="d-flex justify-end">
            <v-btn
              :disabled="user.roleId !== 1"
              icon
              size="small"
              variant="text"
              @click="deleteAbsence(item.id)">
              <v-icon size="small" icon="mdi-delete" />
            </v-btn>
          </div>
        </template>
        <template #bottom></template>
      </v-data-table>
    </div>
    <div
      v-if="user.roleId === 1"
      :class="{
        'd-flex': true,
        'justify-center': !absences.length,
        'justify-end': absences.length,
      }">
      <v-btn append-icon="mdi-plus" class="mt-3" @click="createAbsence"
        >Додати
      </v-btn>
    </div>
  </div>
</template>

<script>
import { axios } from '@/plugins'
import { urlUserAbsenceDelete } from '@/utils/urls.js'
import { computed, ref } from 'vue'
import { confirm } from '@/plugins/confirm/index.ts'
import { getUserName } from '@/utils/helpers'
import { openDialog } from '@/dialog/index.ts'
import { setSnackbar } from 'best-modules/plugins'

export default {
  name: 'UserAbsence',
  props: {
    modelValue: { type: Array },
    user: { type: Object },
  },
  methods: { getUserName },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const columns = ref([
      { key: 'type', title: 'Тип' },
      { key: 'startDate', title: 'Дата початку' },
      { key: 'endDate', title: 'Дата закінчення' },
      { key: 'deputy', title: 'Замісник' },
      { key: 'delete', title: 'Дії', align: 'end' },
    ])
    const absences = computed({
      get() {
        return props.modelValue || []
      },
      set(val) {
        emit('update:model-value', val)
      },
    })

    const createAbsence = () => {
      openDialog({
        name: 'Absence',
        action: 'create',
        dialogData: {
          userId: props.user.id,
        },
        params: {
          cb: newAbsence => {
            absences.value = [...absences.value, newAbsence]
          },
        },
      })
    }

    const deleteAbsence = absenceId => {
      confirm({
        text: 'Ви впевненні що хочете видалити відпустку на завжди?',
      }).then(() => {
        return axios.delete(urlUserAbsenceDelete(absenceId)).then(() => {
          absences.value = absences.value.filter(a => a.id !== absenceId)
          setSnackbar({ text: 'Відпустку видалено' })
        })
      })
    }

    return {
      absences,
      columns,
      deleteAbsence,
      createAbsence,
    }
  },
}
</script>
