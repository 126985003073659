<template>
  <div>
    <div class="label">{{ label }}</div>
    <v-textarea v-model="value" v-bind="inputProps"></v-textarea>
  </div>
</template>

<script>
import { useInput } from './input.ts'

export default {
  name: 'BaseTextarea',
  emits: ['update:model-value'],
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: [Number, String, null],
    },
  },
  setup(props, context) {
    return {
      ...useInput(props, context),
    }
  },
}
</script>
