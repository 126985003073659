<template>
  <div>
    <div v-if="!dropper" class="label">
      <span v-if="label" :class="{ disabled: disabled }">{{ label }}</span>
      <v-btn
        v-if="!readonly"
        :disabled="
          disabled || (showFiles && !multiple && localFiles.length === 1)
        "
        :loading="$loading.isLoading(`uploadFile_${id}`)"
        icon
        size="x-small"
        variant="text"
        @click="
          () => selectFile({ accept, multiple, confirmMsg }).then(uploadFile)
        ">
        <v-icon icon="mdi-cloud-download" size="large" />
      </v-btn>
    </div>

    <div v-if="showFiles" class="files-wrapper">
      <ul v-if="localFiles.length" :class="{ 'pl-0': readonly }" class="files">
        <FileItem
          v-for="file in localFiles"
          :key="file.path"
          :disabled="disabled || readonly"
          :file="file"
          @remove-file="removeFile($event)" />
      </ul>
      <div v-else-if="showEmpty" class="text-center disabled">
        Список порожній
      </div>
    </div>

    <FileDropper
      v-if="
        dropper &&
        !disabled &&
        !readonly &&
        (multiple ? true : !localFiles.length)
      "
      :dropper-variant="dropperVariant"
      :loading="$loading.isLoading(`uploadFile_${id}`)"
      :outline="outline"
      :select-options="{ multiple, accept, confirmMsg }"
      @select="uploadFile($event)" />

    <ValidationError :v$="v$" />
  </div>
</template>

<script lang="ts">
import FileItem from '@/components/filesUploader/FileItem.vue'
import FileDropper from '@/components/filesUploader/FileDropper.vue'
import ValidationError from '@/components/ValidationError.vue'
import { PropType, ref, watch } from 'vue'
import { generateId } from '@/utils/helpers.js'
import { selectFile } from './selectFile'
import { DropperVariant } from './FileDropper.vue'
import { FileAccept } from './selectFile/types'
import { uploadFiles as upload, MainFolder, NameFolder } from './uploadFiles'
import { handleAsync } from 'best-modules/plugins'
import { urlDeleteFile } from '@/utils/urls.js'
import axios from '@/plugins/axios/index.js'

export default {
  components: { FileDropper, FileItem, ValidationError },
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: null, //setting another value against null only for type 'files'
    dropper: { type: Boolean, default: false },
    dropperVariant: {
      type: String as PropType<DropperVariant>,
      default: 'default',
    },
    outline: { type: Boolean, default: false },
    type: {
      type: String as PropType<'paths' | 'files'>,
      default: 'files',
      validator: val => ['paths', 'files'].includes(val),
    },
    mainFolder: {
      type: String as PropType<MainFolder>,
      required: true,
      validator: val =>
        ['Документы', 'Приказы', 'Пользователи', 'Корреспонденция'].includes(
          val
        ),
    },
    nameFolder: {
      type: String as PropType<NameFolder>,
      required: true,
    },
    prefixFolder: {
      type: String as PropType<string | undefined>,
    },
    multiple: { type: Boolean, default: true },
    label: { type: [String, Boolean] },
    tooltipLabel: { type: [String, Boolean], default: 'Завантажити документи' },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    accept: { type: Array as PropType<FileAccept[]> },
    showFiles: { type: Boolean, default: true },
    showEmpty: { type: Boolean },
    hideDetails: { type: Boolean },
    v$: {
      type: Object,
      default: () => ({ $validate: () => false }),
    },
    confirmMsg: { type: [String, Boolean], default: false },
  },
  setup(props, { emit }) {
    const id = generateId()
    const localFiles = ref([])
    const typePaths = props.type === 'paths'

    watch(
      () => props.modelValue,
      files => {
        if (!files) {
          return localFiles.value.splice(0)
        }
        if (!typePaths) {
          localFiles.value.splice(0)
          localFiles.value.push(...(props.modelValue || []))
        }
      },
      { immediate: true }
    )

    const removeFile = file => {
      return handleAsync(`deleteFile__${file.path}`, () => {
        return axios
          .post(urlDeleteFile(), {
            file: file.path,
          })
          .then(() => {
            const emitedVal = props.modelValue.filter(
              f => (typePaths ? f : f.path) !== file.path
            )

            localFiles.value = localFiles.value.filter(
              f => f.path !== file.path
            )
            emit('update:modelValue', emitedVal)
            emit('change', emitedVal)
          })
      })
    }

    const uploadFile = async event => {
      // add loading item to files list
      localFiles.value.push({ loading: true })
      !props.multiple && localFiles.value.splice(0)

      return handleAsync(`uploadFile_${id}`, () => {
        return upload({
          files: event.blob,
          mainFolder: props.mainFolder,
          nameFolder: props.nameFolder,
          prefixFolder: props.prefixFolder,
        }).then(uploadedFiles => {
          localFiles.value.unshift(...uploadedFiles.files)

          const emitedVal = [
            ...(props.modelValue || []),
            ...uploadedFiles[props.type],
          ]

          emit('update:modelValue', emitedVal)
          emit('change', emitedVal)

          localFiles.value = localFiles.value.filter(f => !f.loading)
        })
      })
    }

    return {
      uploadFile,
      selectFile,
      removeFile,
      localFiles,
      id,
    }
  },
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;

  & > span {
    margin-right: 0.7rem;
    font-weight: 600;
  }
}

.wrapper {
  border-radius: 4px;
  padding: 16px 8px;
  text-align: center;
  transition: 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;

  .uploader-icon {
    border-radius: 50%;
    background: #c7e2ff;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: #eefafc;
  }

  .label {
    color: #49a2f4;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
