<template>
  <div class="datepicker">
    <div :class="{ datepicker__range: range }" class="datepicker__input">
      <div class="label">{{ label }}</div>
      <v-hover>
        <template #default="{ isHovering, props: hoverProps }">
          <v-text-field
            v-model="inputValue"
            v-mask:[dateMask(timePicker,range)]
            :disabled="localReadonly || disabled"
            :loading="loading"
            :placeholder="`дд.мм.рррр ${timePicker ? ', гг:мм' : ''}`"
            :readonly="range || localReadonly || disabled"
            v-bind="{ ...$attrs, ...hoverProps }"
            @blur="$emit('blur')"
            @focus="$emit('focus')"
            @click.stop="range && (isOpen = !isOpen)">
            <template #prepend-inner>
              <v-menu
                v-model="isOpen"
                :disabled="disabled || localReadonly"
                offset="15">
                <template #activator="{ props: menuProps }">
                  <div v-bind="menuProps">
                    <v-icon
                      :color="
                        $attrs['error-messages'] &&
                        $attrs['error-messages'].length
                          ? 'error'
                          : '#adadad'
                      "
                      style="cursor: pointer">
                      mdi-calendar-month
                    </v-icon>
                  </div>
                </template>

                <VueDatePicker
                  :id="id"
                  v-model="value"
                  :enable-time-picker="timePicker"
                  :min-date="minDate"
                  :month-change-on-scroll="false"
                  :multi-calendars="range"
                  :range="range"
                  inline
                  minutes-increment="10"
                  select-text="Обрати"
                  time-picker-inline
                  v-bind="$attrs"
                  @blur="$emit('blur')"
                  @focus="$emit('focus')"
                  @update:model-value="isOpen = false">
                  <template #action-row="{ selectDate, internalModelValue }">
                    <div
                      class="w-100 d-flex justify-space-between align-center">
                      <span>{{
                        keepArray(internalModelValue)
                          .map(getInputStr)
                          .join(' - ') || ''
                      }}</span>
                      <v-btn
                        density="compact"
                        variant="plain"
                        @click="selectDate()">
                        Обрати
                      </v-btn>
                    </div>
                  </template>
                </VueDatePicker>
              </v-menu>
            </template>
            <template v-if="closeIcon && !localReadonly" #append-inner>
              <v-fade-transition>
                <v-btn
                  v-show="isHovering"
                  color="#6750A4"
                  density="compact"
                  icon
                  v-bind="hoverProps"
                  variant="text"
                  @click="value = null">
                  <v-icon color="grey" size="small">mdi-close</v-icon>
                </v-btn>
              </v-fade-transition>
            </template>
          </v-text-field>
        </template>
      </v-hover>
    </div>
  </div>
</template>

<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import {
  computed,
  ComputedRef,
  inject,
  PropType,
  Ref,
  ref,
  watch,
  WritableComputedRef,
} from 'vue'
import { generateId, keepArray } from '@/utils/helpers.js'
import { dateMask } from '@/utils/masks.js'
import { CustomDate, Format, ISODate, LocaleDate } from '@/utils/date'

export default {
  name: 'BaseDatePicker',
  components: { VueDatePicker },
  emits: [
    'update:modelValue',
    'update:startDate',
    'update:endDate',
    'focus',
    'blur',
  ],
  props: {
    modelValue: { type: [Object, String, Array] },
    modelValueFormat: {
      type: String as PropType<Format>,
      default: 'locale-numeric',
    },
    startDate: { type: [String, Object] },
    endDate: { type: [String, Object] },
    timePicker: { type: Boolean as PropType<boolean> },
    range: { type: Boolean as PropType<boolean> },
    textInput: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    readonly: { type: Boolean as PropType<boolean> },
    disabled: { type: Boolean as PropType<boolean> },
    closeIcon: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    label: {
      type: String as PropType<string>,
    },
    minDate: {
      type: Object as PropType<Date>,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  methods: { dateMask, keepArray },
  setup(props, { emit }) {
    const readonly: Ref<boolean> | ComputedRef<boolean> = inject(
      'readonly',
      ref(false)
    )
    const localReadonly = computed(() => props.readonly || readonly.value)
    // type Range<T extends string> = `${T} - ${T}`

    const id = generateId()
    const isOpen = ref(false)
    const getInputStr = (date: Date | LocaleDate | ISODate): LocaleDate => {
      // @ts-ignore
      return new CustomDate(date).toString({
        format: 'locale-numeric',
        time: props.timePicker,
      })
    }
    const getEmittedStr = (date): LocaleDate | ISODate => {
      return new CustomDate(date).toString({
        format: props.modelValueFormat,
        time: props.timePicker,
      })
    }

    const inputValue: Ref<string> = ref(null)

    // set inputValue
    watch(
      computed(() => props.modelValue),
      () => {
        if (props.range) {
          const parseRange = arr => {
            return arr.filter(Boolean).map(getInputStr).join(' - ')
          }
          if (Array.isArray(props.modelValue)) {
            inputValue.value = parseRange(props.modelValue)
          } else {
            inputValue.value = parseRange([props.startDate, props.endDate])
          }
        } else {
          inputValue.value = getInputStr(props.modelValue)
        }
      },
      { immediate: true, deep: true }
    )
    watch(inputValue, (val: LocaleDate) => {
      if (!val) {
        value.value = null
        return
      }
      if (props.timePicker ? val.length === 17 : val.length === 10) {
        const minDate = props.minDate
        const inputDate = new CustomDate(val).date.toDate()
        value.value = inputDate && minDate > inputDate ? minDate : inputDate
      }
    })

    const value: WritableComputedRef<[Date | null, Date | null] | Date | null> =
      computed({
        get() {
          const getDate = val =>
            val ? new CustomDate(val).date.toDate() : null

          if (props.range) {
            if (Array.isArray(props.modelValue)) {
              return props.modelValue.filter(Boolean).map(getDate)
            } else {
              const startDate = getDate(props.startDate)
              const endDate = getDate(props.endDate)
              return startDate && endDate ? [startDate, endDate] : null
            }
          } else {
            return getDate(props.modelValue)
          }
        },
        set(val: [Date | null, Date | null]): void {
          if (!val) {
            emit('update:modelValue', null)
            emit('update:startDate', null)
            emit('update:endDate', null)
            inputValue.value = null

            return
          }
          if (Array.isArray(val)) {
            inputValue.value = val.map(getInputStr).join(' - ')

            const [startDate, endDate] = val
            emit('update:startDate', getEmittedStr(startDate))
            emit('update:endDate', getEmittedStr(endDate))
            emit('update:modelValue', val.map(getEmittedStr))
          } else {
            inputValue.value = getInputStr(val)
            emit('update:modelValue', getEmittedStr(val))
          }
        },
      })

    return {
      localReadonly,
      id,
      value,
      inputValue,
      isOpen,
      CustomDate,
      getInputStr,
    }
  },
}
</script>

<style lang="scss">
.datepicker {
  position: relative;

  &__range input {
    cursor: pointer;
  }
}
</style>
