import router from '@/router'
import { axios } from '@/plugins'
import { handleAsync } from 'best-modules/plugins'
import { computed, nextTick } from 'vue'
import { formatDate, isDate } from '@/plugins/axios/helpers.js'
import { urlMicrosoftPreview } from '@/utils/urls.js'
import { CustomDate } from 'best-modules/utils'

function getErrorMessages(v$) {
  if (!v$ || typeof v$ !== 'object') {
    throw new Error(
      `function getErrorMessages expect v$ as Object, but got ${typeof v$}`
    )
  }

  return v$.$errors.map(() => {
    return 'Обов`язкове поле'
  })
}

function getItems(url) {
  return axios.get(url).then(res => res.data)
}

function getUserName(user, params = {}) {
  if (!user) return ''
  const { initials = false, isFullName = false } = params

  const getUserParts = () => {
    if (isFullName) {
      const [secondName, name, patronymic] = user.split(' ')
      return { secondName, name, patronymic }
    } else {
      return user
    }
  }

  const { secondName, name, patronymic } = getUserParts()

  const getInitials = () => {
    const short = el => (el ? `${el.toUpperCase()[0]}.` : '')
    return `${secondName} ${short(name)}${short(patronymic)}`
  }

  return initials ? getInitials() : `${secondName} ${name} ${patronymic}`
}

async function rerenderComponent(ref) {
  ref.value = false
  await nextTick()
  ref.value = true
}

function generateId(length = 13) {
  return Math.random()
    .toString(16)
    .substring(2, Math.min(length + 2, 13))
}

function download(path, fileName = 'noNamed') {
  return getFile({ path, type: 'path' }).then(blobPath => {
    const link = document.createElement('a')
    link.href = blobPath
    link.setAttribute('download', fileName)
    link.click()
    link.remove()
  })
}

function getFile({ path, type = 'blob' /* blob | path */, token }) {
  return handleAsync(`getFile_${path}`, () => {
    const Authorization = token
      ? `Bearer ${token.replace('Bearer ', '')}`
      : null
    return axios
      .get(toPath(path), {
        responseType: 'blob',
        headers: { Authorization },
      })
      .then(res => {
        switch (type) {
          case 'path':
            return URL.createObjectURL(res.data)
          case 'blob':
            return res.data
        }
      })
  })
}

function getBlobExt(blob) {
  switch (blob.type) {
    case 'application/msword':
      return 'doc'
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx'
    case 'application/vnd.ms-excel':
      return 'xls'
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx'
    case 'application/pdf':
      return 'pdf'
    case 'application/vnd.rar':
      return 'rar'
    case 'application/zip':
      return 'zip'
    case 'image/png':
      return 'png'
    case 'image/jpeg':
      return 'jpeg'
    case 'image/svg+xml':
      return 'svg'
  }
}

function isMicrosoftFile(file, type = 'path') {
  const microsoftExt = ['doc', 'docx', 'xls', 'xlsx']

  if (type === 'path') {
    return microsoftExt.includes(file.split('.').at(-1))
  }
  if (type === 'blob') {
    return getBlobExt(file)
  }
  if (type === 'ext') {
    return microsoftExt.includes(file)
  }
}

function documentPathHandler(path, options = {}) {
  if (isMicrosoftFile(path)) {
    return urlMicrosoftPreview(path)
  } else {
    const { token } = options
    return getFile({ path, type: 'blob', token }).then(blob => {
      console.log('blob', blob)
      return URL.createObjectURL(blob)
    })
  }
}

function convertFileFormatToLowerCase(path) {
  const parts = path.split('.')
  const extension = parts.pop().toLowerCase()
  return parts.join('.') + '.' + extension
}

async function openDocument(path) {
  const url = await documentPathHandler(path)
  window.open(url, '_blank')
}

function getQueryStr(queryObj, params = {}) {
  if (!queryObj) return ''
  const { ampersand = false } = params
  return Object.entries(queryObj)
    .filter(f => ![null, ''].includes(f[1]))
    .map((q, index) => {
      const key = q[0]
      let value = q[1]
      const separator = index === 0 && !ampersand ? '?' : '&'
      if (
        Array.isArray(value) &&
        value.length &&
        value.reduce((acc, v) => (acc ? isDate(v, 'request') : false), true)
      ) {
        return getQueryStr(
          {
            startDate: formatDate(value[0], 'request'),
            endDate: formatDate(value[1], 'request'),
          },
          { ampersand: true }
        )
      }
      if (isDate(value, 'request')) {
        value = formatDate(value, 'request')
      }
      if (Array.isArray(value)) {
        if (!value.length) {
          return ''
        }
        return `${separator}${value.map(v => `${key}=${v}`).join('&')}`
      }
      return `${separator}${key}=${value}`
    })
    .join('')
}

function getFullName(person) {
  if (!person) return null
  const { surname, name, patronymic } = person.contractor || person
  return [surname, name, patronymic].filter(Boolean).join(' ')
}

function getCurrentDate() {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  let month = currentDate.getMonth() + 1
  let day = currentDate.getDate()
  let hours = currentDate.getHours()
  let minutes = currentDate.getMinutes()

  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes

  return `${day}.${month}.${year}, ${hours}:${minutes}`
}

function isCurrentDate(date) {
  const commaIndex = date.indexOf(',')
  const onlyDate =
    commaIndex !== -1 ? date.substring(0, commaIndex).trim() : date
  const curDate = new CustomDate(new Date()).toString()

  if (onlyDate === curDate) {
    return date.substring(date.indexOf(',') + 1).trim()
  }

  return onlyDate
}

function navigateToItem(name, id) {
  const route = { name }
  if (id) {
    route.params = typeof id === 'object' ? id : { id }
  }
  router.push(route)
}

function getEntityId(entity) {
  const e = entity && entity.entity ? entity.entity : entity

  if (e && e.entityTypeId && e.entityId) {
    return `${e.entityTypeId}${e.entityId}`
  } else if (e && e.id) {
    return e.id
  }

  console.error('Invalid entity:', e)
  return null
}

function reactiveProp(
  props,
  emit = () => false,
  propName = 'modelValue',
  defaultValue = null,
  formatter = null,
  setFunc = null
) {
  return computed({
    get: () => props[propName] || defaultValue,
    set: val => {
      const res = formatter ? formatter(val) : val
      setFunc ? setFunc(res) : emit(`update:${propName}`, res)
    },
  })
}

function getBeautyNum(number, params = {}) {
  number = String(number).replaceAll(' ', '')
  const n = Number(number)
  if (typeof n !== 'number' || Number.isNaN(n)) return number

  const { float = 0 } = params
  return new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: float,
    maximumFractionDigits: float,
  })
    .format(number)
    .replace(',', '.')
}

function toPath(p) {
  if (p.startsWith('blob:') || p.startsWith('http')) {
    return p
  }
  return p[0] === '/' ? p : `/${p}`
}

function hideTime(date) {
  if (!date) return date
  return date.substring(0, 10)
}

function keepArray(value) {
  return Array.isArray(value) ? value : [value].filter(Boolean)
}

function filterDuplicates(arr, key = 'id') {
  return arr.filter((itm, idx, arr) => {
    return itm && arr.findIndex(i => i[key] === itm[key]) === idx
  })
}

function getCurrentYearDeclension(numberDate) {
  const age = parseInt(numberDate)

  if (isNaN(age)) {
    return 'Невірний формат числа'
  }

  let years
  if (age % 10 === 1 && age % 100 !== 11) {
    years = 'рік'
  } else if (
    age % 10 >= 2 &&
    age % 10 <= 4 &&
    (age % 100 < 10 || age % 100 >= 20)
  ) {
    years = 'роки'
  } else {
    years = 'років'
  }

  return `${age} ${years}`
}

function getAddressStr(address) {
  if (!address) return 'Адреса вiдсутня'

  const joinParties = (...args) =>
    args.filter(v => v !== null && v !== undefined && v !== '').join(', ')

  const setString = o =>
    joinParties(
      o.country?.name,
      o?.region?.region,
      o?.district?.district,
      o.index,
      o.address
    )

  const setStringSpec = a => {
    const streetType = a.street?.StreetType || ''
    const streetName = a.street?.Street || ''
    const houseNum = a.house?.HouseNum || ''
    const index = a.house?.Index_ || ''

    return joinParties(
      a.country?.name,
      index,
      a.city?.Region || '',
      a.city?.City || '',
      streetType,
      streetName,
      houseNum
    )
  }

  if (Array.isArray(address)) {
    if (!address?.length) return 'Адреса вiдсутня'
    const ra = address.find(a => a.type.id === 4)
    const a = address[0]
    if (ra) {
      return typeof ra.country === 'string' ? setStringSpec(ra) : setString(ra)
    } else {
      return setString(a)
    }
  } else if (
    address?.constructor?.name === 'Object' &&
    Object.keys(address).length
  ) {
    const c = address?.city || {}
    const h = address?.house || {}
    const s = address?.street || {}

    const country = address?.country?.name
    const city = `${c.SettlementType || ''} ${c.City}`
    const region = c.IsOCentre ? null : c.Region
    const district = c.Area
    const index = h.Index_
    const streetType = s.StreetType
    const streetName = s.Street
    const houseNum = [h.HouseNum, h.HouseNumAdd]
      .filter(v => v !== null && v !== void [0] && v !== '')
      .join(' ')

    if (!address?.city) return 'Адресу не вказано'
    return joinParties(
      country,
      index,
      region,
      district,
      city,
      streetType,
      streetName,
      houseNum
    )
  } else if (typeof address === 'string') {
    return address
  }
}

function playSound(url) {
  const myAudioElement = new Audio(url)
  myAudioElement.addEventListener('canplaythrough', () => {
    try {
      return myAudioElement.play()
    } catch (e) {
      console.warn(e)
    }
  })
}

export {
  getItems,
  getUserName,
  rerenderComponent,
  generateId,
  download,
  getFile,
  documentPathHandler,
  openDocument,
  getBlobExt,
  getCurrentDate,
  isMicrosoftFile,
  getQueryStr,
  navigateToItem,
  getEntityId,
  reactiveProp,
  getBeautyNum,
  toPath,
  hideTime,
  keepArray,
  getFullName,
  filterDuplicates,
  getAddressStr,
  getErrorMessages,
  getCurrentYearDeclension,
  playSound,
  convertFileFormatToLowerCase,
  isCurrentDate,
}
