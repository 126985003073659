<template>
  <div>
    <Filter
      v-if="$route.name === 'order-list'"
      v-model="filters"
      v-model:loading="loading"
      section="order"
      @filter="() => pgTrigger++">
      <template v-if="[ADMIN, CLERKSHIP].includes(user.roleId)" #append-search>
        <div class="flex-grow-1 d-flex justify-end gap-3 flex-wrap">
          <v-btn class="mb-3" @click="familiarizationReportDialog">
            <v-icon class="mr-2" style="color: #fff"
              >{{ 'mdi-cloud-print-outline' }}
            </v-icon>
            Сформувати звіт
          </v-btn>
          <v-btn class="mb-2" @click="createOrder">
            <v-icon>mdi-plus</v-icon>
            Створити наказ
          </v-btn>
        </div>
      </template>
    </Filter>
    <Skeleton v-if="loading" />

    <div v-if="!loading">
      <v-data-table
        :disable-pagination="true"
        :headers="headers"
        :hide-default-footer="true"
        :hide-default-header="!ordersList.length"
        :items="ordersList"
        :items-per-page="-1"
        dense
        @dblclick:row="openRow">
        <!-- eslint-disable-next-line -->
        <template #item.issued_user="{ item }">
          <span v-if="item.issued_user">
            {{ getUserName(item.issued_user) }}
          </span>
          <span v-else class="text-disabled">Не вказано</span>
        </template>
        <template #item.isActive="{ item }">
          <span>{{ item.isActive ? 'Активний' : 'Неактивний' }}</span>
        </template>
        <template #item.name="{ item }">
          <v-tooltip :text="item.name" bottom>
            <template #activator="{ props }">
              <span
                style="
                  display: block;
                  width: 350px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                v-bind="props"
                >{{ item.name }}</span
              >
            </template>
          </v-tooltip>
        </template>
        <template #bottom></template>
      </v-data-table>
    </div>

    <Pagination :getItems="getOrders" :trigger="pgTrigger" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import Filter from '@/components/Filter.vue'
import { axios, useRoute } from '@/plugins/index.js'
import { computed, reactive, ref } from 'vue'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { urlOrderFamiliarization, urlOrderList } from '@/utils/urls.js'
import Skeleton from '@/components/Skeleton.vue'
import { openDialog } from '@/dialog/index.ts'
import { useStore } from 'vuex'
import { CLERKSHIP, ADMIN } from '@/utils/roles.ts'

export default {
  components: { Filter, Pagination, Skeleton },
  data: () => ({
    CLERKSHIP,
    ADMIN,
    headers: [
      {
        title: 'Номер',
        align: 'start',
        key: 'number',
        sortable: false,
        maxWidth: '60px',
      },
      { title: 'Статус', key: 'isActive', sortable: false, align: 'start' },
      { title: 'Тема', key: 'name', sortable: false, align: 'start' },
      {
        title: 'Хто підготовив',
        key: 'issued_user',
        sortable: false,
        align: 'start',
      },
    ],
  }),
  methods: { getUserName },
  setup() {
    const route = useRoute()
    const store = useStore()

    const user = computed(() => store.state.user.userData || {})

    const loading = ref(true)
    const ordersList = ref([])
    const filters = reactive({
      search: null,
      issuedUserId: null,
      typeId: null,
      departmentId: null,
      directionId: null,
      isActive: null,
      toDate: null,
      fromDate: null,
    })

    const openRow = (e, row) => {
      navigateToItem('single-order', row.item.id)
    }

    const isFamiliarization = computed(() => {
      return [
        'document-familiarization-me',
        'document-familiarization-familiarized',
      ].includes(route.name)
    })

    const url = computed(() => {
      switch (route.name) {
        case 'document-familiarization-me':
          return ({ page }) =>
            urlOrderFamiliarization({
              page,
              filters: { isFamiliarization: false },
            })
        case 'document-familiarization-familiarized':
          return ({ page }) =>
            urlOrderFamiliarization({
              page,
              filters: { isFamiliarization: true },
            })
        case 'order-list':
          return urlOrderList
        default:
          throw new Error(
            '[OrderList.vue] url: ComputedRef - unknown route name'
          )
      }
    })

    const getOrders = page => {
      loading.value = true
      return axios
        .get(url.value({ page, filters }))
        .then(res => {
          ordersList.value = res.data.data
          loading.value = false
          return res.data
        })
        .catch(() => {
          loading.value = false
        })
    }
    const pgTrigger = ref(1)

    const familiarizationReportDialog = () => {
      openDialog({
        name: 'FamiliarizationReport',
        action: 'create',
      })
    }

    const createOrder = () => {
      openDialog({
        name: 'Order',
        action: 'create',
        params: {
          cb: order => {
            navigateToItem('single-order', order.id)
          },
          submitLabel: 'Створити',
        },
      })
    }

    return {
      ordersList,
      getOrders,
      navigateToItem,
      loading,
      filters,
      pgTrigger,
      familiarizationReportDialog,
      openRow,
      user,
      isFamiliarization,
      createOrder,
    }
  },
}
</script>

<style lang="scss" scoped></style>
