<template>
  <div class="w-100">
    <div class="comment-component">
      <div class="comment-component__main">
        <div class="comment-component__main header">
          <div class="d-flex align-center w-100">
            <BaseImage
              v-if="!hidePhoto"
              :src="comment.user.photo"
              alt="user"
              class="mr-1"
              rounded
              width="36px" />
            <span>{{ getUserName(comment.user, { initials: true }) }}</span>
          </div>

          <div class="comment-component__delete">
            <v-btn
              v-if="!hideDelete"
              :disabled="!isAuthor"
              :loading="loading === comment.id"
              icon
              size="x-small"
              variant="text"
              @click="$emit('delete', comment.id)">
              <v-icon icon="mdi-delete" size="small" />
            </v-btn>
          </div>
        </div>
        <div class="comment-component__main comment">
          <span v-for="(row, idx) in comment.comment.split('\n')" :key="idx">
            {{ row }} <br />
          </span>
        </div>
        <div
          class="comment-component__main date d-flex align-center justify-space-between">
          <span class="d-block">{{
            formatDate(comment.created_at, 'response')
          }}</span>
          <slot name="showAllComments"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/plugins/index.js'
import { computed, inject } from 'vue'
import { getUserName } from '@/utils/helpers.js'
import { formatDate } from '@/plugins/axios/helpers.js'

export default {
  props: {
    comment: { type: Object, required: true },
    loading: { type: [Number, Boolean] },
    hideDelete: { type: Boolean },
    hidePhoto: { type: Boolean },
  },
  emits: ['delete'],
  methods: { formatDate, getUserName },

  setup(props) {
    const store = useStore()
    const loadingState = inject('loadingState', {})

    const userId = computed(() => store.state.user.userData?.id)
    const isAuthor = computed(() => {
      return props.comment.userId === userId.value
    })

    return { loadingState, isAuthor }
  },
}
</script>

<style lang="scss">
.comment-component {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__main {
    min-width: 100%;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      margin-bottom: 8px;

      span {
        color: #010e30;
        font-weight: 600;
      }
    }

    .comment {
      color: #656567;
    }

    .date {
      color: #656567;
      margin-top: 8px;
    }
  }
}
</style>
