<template>
  <ExpansionPanel :label="`Наказ: ${item.name}`">
    <template #default>
      <div v-if="item.children.length">
        <RecursiveExpansionPanel
          v-for="child in item.children"
          :key="child.id"
          :item="child" />
      </div>
    </template>
  </ExpansionPanel>
</template>

<script>
import ExpansionPanel from '@/components/expansion-panel/Expansion-panel.vue'

export default {
  name: 'RecursiveExpansionPanel',
  components: {
    ExpansionPanel,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
