<template>
  <v-card>
    <v-card-text>
      <RouterView />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RouterViewCard',
}
</script>

<style lang="scss" scoped></style>
