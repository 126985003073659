import * as EUSignCP from 'euscp'
import { KSPs, keyMediaTypes, Ksp } from './keyTypes'
import { readFile } from './utils'
import { CustomDate } from 'best-modules/utils'
import { ref } from 'vue'
import { caList } from './caList'

const { EndUserConstants, EndUser, EndUserKeyMedia } = EUSignCP

type SignData = {
  signBinary: Uint8Array
  signTimeStamp: string
}

type KeyData = {
  id: string
  privateKeyCtx: EUSignCP.EndUserPrivateKeyContext
  ownerInfo: EUSignCP.EndUserCertificateInfoEx
}

let keys: KeyData[] = []

const pushKey = (
  id: string,
  privateKeyCtx: EUSignCP.EndUserPrivateKeyContext,
  ownerInfo: EUSignCP.EndUserCertificateInfoEx
): KeyData => {
  const key = {
    id,
    privateKeyCtx,
    ownerInfo,
    sign: null,
    signTimeStamp: null,
  }
  keys.push(key)

  return key
}
const removeKey = (id: string): void => {
  keys = keys.filter(k => k.id !== id)
}

const keyMedias = ref([])

const PK_FORM_TYPE_FILE = 'file'
const PK_FORM_TYPE_KM = 'media'
const PK_FORM_TYPE_KSP = 'cloud'

const euSettings = {
  language: 'uk',
  encoding: 'utf-8',
  httpProxyServiceURL: '/eu/ProxyHandler.php',
  directAccess: true,
  CAs: '/eu/CAs.json',
  CACertificates: '/eu/CACertificates.p7b',
  allowedKeyMediaTypes: keyMediaTypes,
  // Реєстрація хмарних провайдерів
  KSPs,
}

const euSignFile = new EndUser(
  '\\eu\\euscp.worker.ex-1.3.69.js',
  EndUserConstants.EndUserLibraryType.JS
)

const euSignKeyMedia = new EndUser(null, EndUserConstants.EndUserLibraryType.SW)

let euSign = euSignFile

function onConfirmKSPOperation(kspEvent) {
  console.log('onConfirmKSPOperation', kspEvent)
}

type InitializeResults = {
  file: null
  media: EUSignCP.EndUserKeyMedia[]
  cloud: Ksp[]
}

function initialize<T extends keyof InitializeResults>(
  type: T
): Promise<InitializeResults[T]> {
  return new Promise(function (resolve, reject) {
    let isInitialized = false
    keyMedias.value = []
    console.log('initialize with type', type)

    if ([PK_FORM_TYPE_FILE, PK_FORM_TYPE_KSP].includes(type)) {
      console.log('init')
      euSign = euSignFile
      euSign
        .IsInitialized()
        .then(function (result) {
          isInitialized = result
          if (isInitialized) {
            console.log('EndUser: JS library already initialized')
            return
          }

          console.log('EndUser: JS library initializing...')
          return euSign.Initialize(euSettings)
        })
        .then(function () {
          if (isInitialized) return

          console.log('EndUser: JS library initialized')

          console.log('EndUser: event listener for KSPs registering...')

          return euSign.AddEventListener(
            EndUserConstants.EndUserEventType.ConfirmKSPOperation,
            onConfirmKSPOperation
          )
        })
        .then(function () {
          if (!isInitialized)
            console.log('EndUser: event listener for KSPs registered')

          isInitialized = true
          if (type === PK_FORM_TYPE_KSP) {
            resolve(euSettings.KSPs as InitializeResults[T])
          } else {
            resolve(null as InitializeResults[T])
          }
        })
        .catch(function (e) {
          reject(e)
        })
    }
    if (type === PK_FORM_TYPE_KM) {
      euSign = euSignKeyMedia
      // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
      euSign
        .GetLibraryInfo()
        .then(function (result: EUSignCP.EndUserLibraryInfoSW) {
          if (!result.supported) {
            throw 'Бібліотека web-підпису не підтримується в вашому браузері або ОС'
          }

          if (!result.loaded) {
            // Бібліотека встановлена, але потребує оновлення
            if (result.isNativeLibraryNeedUpdate) {
              throw `Бібліотека web-підпису потребує оновлення. Будь ласка, встановіть оновлення за посиланням ${result.nativeLibraryInstallURL}`
            }

            // Якщо браузер підтримує web-розширення рекомендується
            // додатково до нативних модулів встановлювати web-розширення
            // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
            if (
              result.isWebExtensionSupported &&
              !result.isWebExtensionInstalled
            ) {
              throw (
                'Бібліотека web-підпису потребує встановлення web-розширення. ' +
                'Будь ласка, встановіть web-розширення за посиланням ' +
                result.webExtensionInstallURL +
                ' та оновіть сторінку'
              )
            }

            // Бібліотека (нативні модулі) не встановлені
            throw (
              'Бібліотека web-підпису потребує встановлення. ' +
              'Будь ласка, встановіть бібліотеку за посиланням ' +
              result.nativeLibraryInstallURL +
              ' та оновіть сторінку'
            )
          }

          return euSign.IsInitialized()
        })
        .then(function (result) {
          isInitialized = result
          if (isInitialized) {
            console.log('EndUser: SW library already initialized')
            return
          }

          console.log('EndUser: SW library initializing...')
          return euSign.Initialize(euSettings)
        })
        .then(function () {
          if (!isInitialized) console.log('EndUser: SW library initialized')

          return euSign.GetKeyMedias()
        })
        .then((kMedias: EUSignCP.EndUserKeyMedia[]) => {
          keyMedias.value = kMedias
          resolve(kMedias as InitializeResults[T])
        })
        .catch(function (e) {
          reject(e)
        })
    }
  })
}

function readPrivateKeyFile(
  id: string,
  privateKey: File,
  password: string,
  caAddress: string = null
): Promise<KeyData> {
  return new Promise(async (resolve, reject) => {
    if (!privateKey) {
      return reject('Не обрано файл з ос. ключем')
    }
    if (!password) {
      return reject('Не вказано пароль до ос. ключа')
    }

    const caCN = caAddress ? caList.getItem(caAddress)?.issuerCNs[0] : null
    let pkCertificates = null

    // await euSign.ResetPrivateKey()

    readFile(privateKey)
      .then(function (result) {
        console.log('Private key file readed', result)

        // Якщо файл з ос. ключем має розширення JKS, ключ може містити декілька ключів,
        // для зчитування такого ос. ключа необхіно обрати який ключ повинен зчитуватися
        if (result.file.name.endsWith('.jks')) {
          return euSign
            .GetJKSPrivateKeys(result.data)
            .then(async function (jksKeys) {
              console.log('EndUser: jks keys got')

              // Для спрощення прикладу обирається перший ключ
              const pkIndex = 0

              pkCertificates = []
              for (let i = 0; i < jksKeys[pkIndex].certificates.length; i++)
                pkCertificates.push(jksKeys[pkIndex].certificates[i].data)

              return euSign.CtxReadPrivateKeyBinary(
                null,
                jksKeys[pkIndex].privateKey,
                password,
                pkCertificates,
                caCN
              )
            })
        }

        return euSign.CtxReadPrivateKeyBinary(
          null,
          result.data,
          password,
          pkCertificates,
          caCN
        )
      })
      .then(async function (result) {
        const certInfoEx = await euSign.CtxGetOwnCertificates(result)

        console.log('certInfoEx', certInfoEx)
        const key = pushKey(id, result, certInfoEx[0].infoEx)
        resolve(key)
      })
      .catch(function (e) {
        reject(e)
      })
  })
}

function readPrivateKeyMedia(
  id: string,
  keyMedia: EUSignCP.EndUserKeyMedia,
  password: string
): Promise<KeyData> {
  return new Promise(async (resolve, reject) => {
    if (!keyMedia) {
      return reject('Не обрано файл з ос. ключем')
    }
    if (!password) {
      return reject('Не вказано пароль до ос. ключа')
    }

    await euSign.ResetPrivateKey()

    const km = new EndUserKeyMedia(keyMedia)
    km.password = password

    euSign
      .CtxReadPrivateKey(null, km)
      .then(async function (result) {
        const certInfoEx = await euSign.CtxGetOwnCertificates(result)

        console.log('certInfoEx', certInfoEx)
        const key = pushKey(id, result, certInfoEx[0].infoEx)

        resolve(key)
      })
      .catch(function (e) {
        reject(e)
      })
  })
}

function readPrivateKeyKSP(): Promise<EUSignCP.EndUserOwnerInfo> {
  return new Promise(resolve => {
    resolve({} as EUSignCP.EndUserOwnerInfo)
  })
}

function signFile(
  privateKeyCtx: EUSignCP.EndUserPrivateKeyContext,
  fileToSign: File | Uint8Array
): Promise<SignData> {
  return new Promise(async (resolve, reject) => {
    try {
      if (!fileToSign) {
        return reject('Не обрано файл для підпису')
      }

      const file: Uint8Array =
        fileToSign instanceof Uint8Array
          ? fileToSign
          : (await readFile(fileToSign)).data

      const signBinary: Uint8Array = (await euSign.CtxSignData(
        privateKeyCtx,
        1,
        file,
        true,
        true,
        false
      )) as Uint8Array

      console.log('signBinary', signBinary)

      const verifyData: EUSignCP.EndUserSignInfo = (await euSign.VerifyData(
        file,
        signBinary,
        0
      )) as EUSignCP.EndUserSignInfo

      console.log('verifyData', verifyData)
      resolve({
        signBinary,
        signTimeStamp: new CustomDate(verifyData.timeInfo.time).toString({
          format: 'iso',
          time: true,
        }),
      })
    } catch (e) {
      reject(e?.message || e)
    }
  })
}

export {
  initialize,
  readPrivateKeyFile,
  readPrivateKeyMedia,
  readPrivateKeyKSP,
  signFile,
  keyMedias,
  keys,
  pushKey,
  removeKey,
}
