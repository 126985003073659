<template>
  <SelectEntities
    :addEntityHandler="addEntity"
    :entities="accesses"
    :filters="['user', 'department']"
    :loading="entityLoading"
    :removeEntityHandler="removeEntity" />
</template>

<script>
import SelectEntities from '@/components/entities/SelectEntities.vue'
import { axios } from '@/plugins/index.js'
import { urlOrderAccessCreate, urlOrderAccessDelete } from '@/utils/urls.js'
import { getEntityId } from '@/utils/helpers.js'
import { computed, ref } from 'vue'

export default {
  components: { SelectEntities },
  emits: ['update:order'],
  props: {
    order: { type: Object },
    disabled: { type: Boolean },
  },
  setup(props, { emit }) {
    const entityLoading = ref(false)
    const accesses = computed({
      get() {
        return props.order.accesses
      },
      set(val) {
        emit('update:order', { ...props.order, accesses: val })
      },
    })
    const addEntity = entity => {
      return axios
        .post(urlOrderAccessCreate(), {
          entityId: entity.entityId,
          entityTypeId: entity.entityTypeId,
          orderId: props.order.id,
        })
        .then(res => {
          accesses.value = res.data.accesses
          return res.data.accesses
        })
    }
    const removeEntity = entity => {
      entityLoading.value = true
      const accessId = props.order.accesses.find(e => {
        return (
          getEntityId({
            entityId: e.entityId,
            entityTypeId: e.entityTypeId,
          }) === getEntityId(entity)
        )
      })?.id
      if (accessId) {
        return axios.delete(urlOrderAccessDelete(accessId)).then(res => {
          accesses.value = res.data.accesses

          entityLoading.value = false
          return res.data
        })
      } else {
        entityLoading.value = false
        throw new Error('Removed entity does not exist in order.accesses array')
      }
    }

    return {
      addEntity,
      removeEntity,
      accesses,
      entityLoading,
    }
  },
}
</script>

<style lang="scss" scoped></style>
