<template>
  <div class="tree__item">
    <v-tooltip :text="item.name" location="bottom">
      <template #activator="{ props }">
        <router-link
          :to="{ name: 'single-order', params: { id: item.id } }"
          v-bind="props">
          <span v-if="item.number">{{ item?.number || '' }} - </span>
          <span>{{ item.name }}}</span>
        </router-link>
      </template>
    </v-tooltip>
    <div v-if="item.children?.length" class="tree">
      <item v-for="itm in item.children" :key="itm.id" :item="itm"></item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Item',
  props: {
    item: {
      type: { Object },
      required: true,
    },
  },
}
</script>
