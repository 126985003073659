<template>
  <form class="forgot" @submit.prevent="submit">
    <b-label>
      <span class="text">Номер телефону</span>
    </b-label>
    <b-input-phone
      v-model="phone"
      :error-messages="getErrorMessages(v$.phone)"
      :readonly="isSendSms"
      class="mb-3"
      hide-details
      @keyup.enter="submit()">
    </b-input-phone>

    <div v-if="isSendSms">
      <b-input-password
        v-model="resetData.password"
        :error-messages="getErrorMessages(v$.resetData.password)"
        :submit="submit"
        class="mb-4"
        hide-details
        label="Пароль"
        @blur="v$.resetData.password.$touch()"></b-input-password>
      <b-input
        v-model="resetData.passwordRepeat"
        :error-messages="getErrorMessages(v$.resetData.passwordRepeat)"
        :submit="submit"
        class="mb-4"
        hide-details
        label="Повтор паролю"
        placeholder="********"
        type="password"
        @blur="v$.resetData.passwordRepeat.$touch()"></b-input>
      <b-input
        v-model="resetData.verificationCode"
        v-mask:[smsCode]
        :error-messages="getErrorMessages(v$.resetData.verificationCode)"
        :submit="submit"
        class="mb-4"
        hide-details
        label="Код із СМС"
        placeholder="Введіть код"
        @blur="v$.resetData.verificationCode.$touch()"></b-input>
    </div>

    <v-btn
      :style="{ width: isSendSms ? '12rem' : null }"
      :loading="loading"
      class="block w-100 btn"
      size="large"
      type="submit">
      <span>{{ isSendSms ? 'Змінити' : 'Відправити СМС' }}</span>
    </v-btn>
  </form>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import {
  axios,
  required,
  requiredIf,
  sameAs,
  useRouter,
  useVuelidate,
} from '@/plugins'
import { getErrorMessages } from '@/utils/helpers.js'
import { urlResetPassword, urlResetPasswordSendSms } from '@/utils/urls.js'
import { smsCode } from '@/utils/masks'
import { setSnackbar } from 'best-modules/plugins'
import { BInputPassword, BInputPhone, BLabel } from 'best-modules/components'
import { cloneDeep } from 'lodash'

export default {
  name: 'ForgotPasswordPage',
  components: { BInputPassword, BInputPhone, BLabel },
  methods: { getErrorMessages },
  setup() {
    const router = useRouter()

    /** State */
    const loading = ref(false)
    const isSendSms = ref(false)

    const resetData = reactive({
      password: null,
      passwordRepeat: null,
      userId: null,
      verificationCode: null,
    })
    const phone = ref('')

    /** Validation */
    const rules = {
      phone: { required },
      resetData: {
        password: {
          requiredIf: requiredIf(isSendSms),
        },
        passwordRepeat: {
          sameAs: sameAs(computed(() => resetData.password)),
        },
        userId: { requiredIf: requiredIf(isSendSms) },
        verificationCode: { requiredIf: requiredIf(isSendSms) },
      },
    }
    const v$ = useVuelidate(rules, { resetData, phone })

    /** Form submitting */
    const submit = () => {
      v$.value.$validate()
      if (v$.value.$error) return

      loading.value = true
      return isSendSms.value
        ? resetPassword().finally(() => (loading.value = false))
        : sendSms().finally(() => (loading.value = false))
    }
    const showMessage = (isSuccess, { message }) => {
      setSnackbar({
        color: isSuccess ? 'success' : 'error',
        text: message || '',
      })
    }
    const sendSms = () => {
      return axios
        .post(urlResetPasswordSendSms(), {
          phone: phone.value,
        })
        .then(({ status, data }) => {
          if (status === 200) {
            isSendSms.value = true
            resetData.userId = data.userId
            v$.value.$reset()
          }
        })
    }
    const resetPassword = () => {
      const req = cloneDeep(resetData)

      // prepare request object
      delete req.passwordRepeat
      req.verificationCode = req.verificationCode.toString()

      return axios.post(urlResetPassword(), req).then(({ data }) => {
        showMessage(data.status, data)
        data.status && router.push({ name: 'login' })
      })
    }

    return {
      smsCode,
      submit,
      resetData,
      loading,
      isSendSms,
      v$,
      phone,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn {
  background: #0f6ac1;
  color: #fff;
  border-radius: 4px !important;
}

.text {
  font-size: 16px;
  font-weight: 400;
  color: #323232;
  display: block;
  margin-bottom: 12px;
}
</style>
