<template>
  <div class="contact">
    <div class="d-flex align-center gap-3 mb-3">
      {{ contactType.name }}
      <v-btn
        icon="mdi-plus"
        density="compact"
        variant="plain"
        @click="createContact()"></v-btn>
    </div>
    <div v-if="localContacts.length">
      <div
        v-for="contact in localContacts"
        :key="contact.id"
        class="pl-5 contact__item">
        <v-row align="center">
          <v-col cols="3">
            <div style="border-right: 1px solid #ccc">
              {{ contact.type.name }}
            </div>
          </v-col>
          <v-col cols="8">
            <div class="pl-8">{{ contact[type] }}</div>
          </v-col>
          <v-col cols="1">
            <div class="d-flex justify-end">
              <b-action-menu
                :actions="[
                  {
                    title: 'Редагувати',
                    icon: 'mdi-pencil',
                    action: () => updateContact(contact),
                  },
                  {
                    title: 'Видалити',
                    icon: 'mdi-delete',
                    action: () => deleteContact(contact.id),
                  },
                ]"></b-action-menu>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else class="text-disabled text-center">
      {{ contactType.emptyText }}
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, ref } from 'vue'
import {
  urlContractorAddressDelete,
  urlContractorPhoneDelete,
  urlContractorEmailDelete,
} from '@/utils/urls.js'
import { openDialog } from '@/dialog'
import axios from '@/plugins/axios/index.js'
import { BActionMenu } from 'best-modules/components'

export default {
  name: 'ContractorContact',
  components: { BActionMenu },
  props: {
    contacts: {
      type: Object,
    },
    type: {
      type: String as PropType<'address' | 'phone' | 'email'>,
      required: true,
    },
    contractorId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const getContactType = () => {
      const types = {
        address: {
          name: 'Адреса',
          emptyText: 'Адреси відсутні',
          delete: urlContractorAddressDelete,
        },
        phone: {
          name: 'Телефон',
          emptyText: 'Телефони відсутні',
          delete: urlContractorPhoneDelete,
        },
        email: {
          name: 'Email',
          emptyText: 'Email відсутні',
          delete: urlContractorEmailDelete,
        },
      }
      return types[props.type]
    }
    const contactType = getContactType()
    const localContacts = ref([...(props.contacts || [])])

    const createContact = () => {
      openDialog({
        name: 'ContractorContact',
        action: 'create',
        dialogData: {
          type: props.type,
          contractorId: props.contractorId,
          title: contactType.name,
        },
        params: {
          cb: contact => {
            localContacts.value = [...localContacts.value, contact]
          },
        },
      })
    }
    const updateContact = contact => {
      openDialog({
        name: 'ContractorContact',
        action: 'update',
        dialogData: {
          contractorId: props.contractorId,
          type: props.type,
          contact,
          title: contactType.name,
        },
        params: {
          cb: newContact => {
            localContacts.value = localContacts.value.map(c => {
              if (c.id === contact.id) {
                return newContact
              } else {
                return c
              }
            })
          },
        },
      })
    }
    const deleteContact = (contactId: number): Promise<void> => {
      return axios.delete(contactType.delete(contactId)).then(() => {
        localContacts.value = localContacts.value.filter(c => {
          return c.id !== contactId
        })
      })
    }

    return {
      localContacts,
      contactType,
      createContact,
      updateContact,
      deleteContact,
    }
  },
}
</script>

<style lang="scss">
.contact {
  margin-bottom: 2rem;
  &__item {
    margin-bottom: 1rem;
    padding-right: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: #fcfcfc;
    }
  }
}
</style>
