<template>
  <div class="mb-2">
    <div
      class="d-flex align-center gap-2 py-1"
      style="cursor: pointer"
      @click="setExpanded(!isExpanded)">
      <v-btn
        :class="{ 'collapse-btn--active': isExpanded }"
        class="collapse-btn"
        density="compact"
        icon="mdi-plus"
        variant="plain"></v-btn>
      <slot name="prepend-label"></slot>
      <span>
        {{ label }}
      </span>
      <slot name="append-label"></slot>
    </div>
    <v-scroll-x-transition>
      <div
        v-show="isExpanded"
        class="pl-4 mt-2"
        style="border-left: 1px solid #ccc">
        <slot name="default"></slot>
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script lang="ts">
import { computed, inject, isRef, provide, ref, Ref, watch } from 'vue'

export default {
  name: 'ExpansionPanel',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const isExpanded: Ref<boolean> = ref(props.modelValue || false)
    provide('isExpanded', isExpanded)

    const setExpanded = (value: any): void => {
      isExpanded.value = !!value
      emit('update:modelValue', !!value)
    }

    watch(
      computed(() => props.modelValue),
      setExpanded,
      { immediate: true }
    )

    // collapse panel if parent is collapsed
    const isParentExpanded: Ref<boolean> | boolean = inject('isExpanded', false)
    if (isRef(isParentExpanded)) {
      watch(isParentExpanded, v => {
        !v && setExpanded(false)
      })
    }

    return { isExpanded, setExpanded }
  },
}
</script>

<style lang="scss">
.collapse-btn {
  transition: 0.2s transform;

  &--active {
    transform: rotate(45deg);
  }
}
</style>
