<template>
  <div>
    <b-select
      v-model="signVariant"
      :items="signVariantList"
      label="Тип сервісу">
      <template #item="{ item, props }">
        <v-list-item v-bind="props" :prepend-icon="item.raw.icon"></v-list-item>
      </template>
      <template #selection="{ item }">
        <v-icon class="mr-3">{{ item.raw.icon }}</v-icon>
        <span>{{ item.title }}</span>
      </template>
    </b-select>
    <div v-if="signVariant === 'diia'">
      <div class="text-center mx-auto mb-5" style="width: 450px">
        Для підписання за допомогою Дія.Підпису Вам потрібно встановити
        мобільний застосунок Дія.
      </div>

      <div class="d-flex align-center gap-4">
        <v-btn variant="outlined" color="secondary" @click="$emit('reject')">
          Назад
        </v-btn>
        <v-btn color="secondary" @click="signDiia"> Підписати </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { inject, ref } from 'vue'
import { openDialog } from '@/dialog'
import { AuthorizationKey, dialogKey, isClientKey } from '../injectionKeys'

export default {
  name: 'SignCloud',
  emits: ['reject'],
  setup() {
    const Authorization = inject(AuthorizationKey)
    const dialog = inject(dialogKey)
    const isClient = inject(isClientKey)

    const signVariant = ref<'diia'>('diia')
    const signVariantList = [
      {
        id: 'diia',
        name: 'Дія.Підпис',
        icon: 'best:diia',
      },
    ]

    const signDiia = () => {
      return openDialog({
        name: 'SignDiia',
        params: { hideActionButtons: true },
        dialogData: {
          Authorization: Authorization.value,
          documentId: dialog.dialogData.document.id,
          isClient: isClient.value,
        },
      })
    }

    return { signVariant, signVariantList, signDiia }
  },
}
</script>

<style scoped lang="scss"></style>
