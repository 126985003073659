<template>
  <div class="mt-2">
    <v-radio-group inline v-model="entityType">
      <v-radio value="user" label="Користувач"></v-radio>
      <v-radio value="department" label="Департамент"></v-radio>
    </v-radio-group>
    <b-autocomplete
      v-show="entityType === 'department'"
      v-model="reportData.departmentId"
      :error-messages="getErrorMessages(v$.departmentId)"
      :items="$directory.get('departmentList')"
      :loading="$loading.isLoading('departmentList')"
      class="mb-4"
      hide-details
      item-title="name"
      item-value="id"
      label="Департамент"
      placeholder="Оберіть департамент"
      @blur="v$.departmentId.$touch()" />
    <b-autocomplete
      v-show="entityType === 'user'"
      v-model="reportData.userId"
      :error-messages="getErrorMessages(v$.userId)"
      :items="$directory.get('userList')"
      :loading="$loading.isLoading('userList')"
      class="mb-4"
      hide-details
      item-title="name"
      item-value="id"
      label="Користувач"
      placeholder="Оберіть користувача"
      @blur="v$.userId.$touch()" />
    <BaseDatePicker
      v-model:end-date="reportData.dateTo"
      v-model:start-date="reportData.dateFrom"
      :error-messages="
        getErrorMessages(v$.dateFrom) || getErrorMessages(v$.dateTo)
      "
      hide-details
      label="Період"
      range
      @blur="v$.dateFrom.$touch()" />
  </div>
</template>

<script lang="ts">
import { reactive, ref, watch, computed } from 'vue'
import { axios, required, requiredIf, useVuelidate } from '@/plugins'
import {
  urlFamiliarizationReportUser,
  urlFamiliarizationReportDepartment,
} from '@/utils/urls.js'
import { formatDate } from '@/plugins/axios/helpers.js'
import { getErrorMessages } from '@/utils/helpers'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { fillDirectory } from '@/directory/index.ts'

export default {
  name: 'FamiliarizationReport',
  components: { BaseDatePicker },
  methods: { getErrorMessages },
  setup() {
    fillDirectory('userList')

    const entityType = ref<'user' | 'department'>('user')

    const reportData = reactive({
      userId: null,
      departmentId: null,
      dateFrom: null,
      dateTo: null,
    })

    const rules = {
      userId: {
        requiredIf: requiredIf(computed(() => entityType.value === 'user')),
      },
      departmentId: {
        requiredIf: requiredIf(
          computed(() => entityType.value === 'department')
        ),
      },
      dateFrom: { required },
      dateTo: { required },
    }

    const v$ = useVuelidate(rules, reportData)

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        const query = {
          userId: reportData.userId,
          departmentId: reportData.departmentId,
          dateFrom: formatDate(reportData.dateFrom, 'request'),
          dateTo: formatDate(reportData.dateTo, 'request'),
        }
        const url =
          entityType.value === 'user'
            ? urlFamiliarizationReportUser
            : urlFamiliarizationReportDepartment
        return axios.get(url(query)).then(res => {
          window.open(res.data.url, '_blank')
        })
      }
    }

    watch(entityType, () => {
      if (entityType.value === 'department') {
        fillDirectory('departmentList')
        reportData.userId = null
      }
      if (entityType.value === 'user') {
        reportData.departmentId = null
      }
    })

    return { v$, reportData, submit, entityType }
  },
}
</script>
