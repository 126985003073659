import axios from '@/plugins/axios/index.js'
import { handleAsync } from 'best-modules/plugins'
import { generateId, keepArray, toPath } from '@/utils/helpers.js'
import { urlUploadFiles, urlClientAccessUploadFile } from '@/utils/urls.js'

export type MainFolder =
  | 'Документы'
  | 'Корреспонденция'
  | 'Приказы'
  | 'Пользователи'
  | 'Задачи'
export type NameFolder = 'Документ' | 'фото' | 'template' | string
interface UploadFilesOptions {
  files?: Blob | Blob[]
  mainFolder: MainFolder
  nameFolder: NameFolder
  prefixFolder?: string
  Authorization?: string
  isClient?: boolean
}
export function uploadFiles({
  files,
  nameFolder,
  mainFolder,
  prefixFolder,
  Authorization,
  isClient,
}: UploadFilesOptions): Promise<{
  paths: string[]
  files: Record<string, any>[]
}> {
  if (keepArray(files).length === 0) {
    return Promise.reject({ files: [], paths: [] })
  }

  const formData = new FormData()

  formData.append('mainFolder', mainFolder)
  formData.append('nameFolder', nameFolder)
  prefixFolder && formData.append('prefixFolder', prefixFolder)

  for (const file of keepArray(files)) {
    formData.append('file[]', file)
  }

  const url = isClient ? urlClientAccessUploadFile : urlUploadFiles

  return handleAsync(`uploadFile_${mainFolder}_${nameFolder}`, () => {
    return axios
      .post(url(), formData, {
        headers: { DontFormatting: true, Authorization },
      })
      .then(res => {
        // formatting path to path with "/" in start
        const pathList = res.data.files.map(p => toPath(p))
        // formatting paths to Objects of files
        const fileList = pathList.map((p, i) => {
          return {
            id: generateId(),
            name: keepArray(files)[i].name,
            size: keepArray(files)[i].size,
            path: p,
          }
        })

        return { files: fileList, paths: pathList }
      })
  })
}
