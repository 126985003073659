<template>
  <form class="login" @submit.prevent="login">
    <div class="login__header">
      <div style="font-size: 20px">Вітаємо в <b>inTime</b></div>
      <div>
        <RouterLink :to="{ name: 'forgot-password' }" class="link">
          Забули пароль?
        </RouterLink>
      </div>
    </div>
    <span class="login__auth-text">Вхід</span>
    <div class="login__form">
      <b-label>
        <span class="login__form text">Номер телефону</span>
      </b-label>
      <b-input-phone
        v-model="credentials.phone"
        :error-messages="getErrorMessages(v$.phone)"
        class="mb-3">
      </b-input-phone>
      <b-label>
        <span class="login__form text">Пароль</span>
      </b-label>
      <b-input-password
        v-model="credentials.password"
        :error-messages="getErrorMessages(v$.password)"
        class="mb-3">
      </b-input-password>
      <v-btn
        :loading="loading"
        class="w-100 login__form"
        size="large"
        type="submit">
        <span>Ввійти в систему</span>
        <i class="pi pi-arrow-right ml-2"></i>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { required, useRouter, useStore, useVuelidate } from '@/plugins/index.js'
import { computed, reactive, ref } from 'vue'
import { getErrorMessages } from '@/utils/helpers.js'
import { BLabel, BInputPassword, BInputPhone } from 'best-modules/components'

export default {
  name: 'LoginPage',
  components: { BInputPhone, BInputPassword, BLabel },
  methods: { getErrorMessages },
  setup() {
    const store = useStore()
    const router = useRouter()

    /** State */
    const credentials = reactive({
      phone: '',
      password: '',
    })
    const loading = ref(false)
    const inputPasswordType = ref('password')

    const toggleInputPasswordType = () => {
      if (inputPasswordType.value === 'password') {
        inputPasswordType.value = 'text'
      } else {
        inputPasswordType.value = 'password'
      }
    }

    /** Validation */
    const rules = computed(() => {
      return {
        phone: { required },
        password: { required },
      }
    })
    const v$ = useVuelidate(rules, credentials)

    /** Authentication */
    const login = () => {
      v$.value.$validate()
      if (v$.value.$error) return

      loading.value = true
      store
        .dispatch('loginFirst', credentials)
        .then(res => {
          router.push({ name: 'auth-confirm' })
          return res
        })
        .finally(() => (loading.value = false))
    }

    return {
      login,
      loading,
      credentials,
      v$,
      inputPasswordType,
      toggleInputPasswordType,
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form {
    .text {
      font-size: 16px;
      font-weight: 400;
      color: #323232;
      display: block;
      margin-bottom: 12px;
    }

    .btn {
      background: #0f6ac1;
      color: #fff;
      border-radius: 4px !important;
    }
  }

  &__auth-text {
    font-size: 34px;
    color: #1f1f1f;
    font-weight: 700;
    display: block;
    margin: 15px 0;
  }

  &__auth-btn {
    background: #0f6ac1;
    border-radius: 4px;
  }
}
</style>
