import { computed, ref } from 'vue'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { axios } from '@/plugins/index.js'
import { urlCompanyStructure } from '@/utils/urls.js'
import { useDepartment } from './department.js'

export function useCompanyStructure() {
  const loading = ref(true)
  const companyStructure = ref()
  const expandedKeys = ref([])
  const expanded = ref([])
  const headers = [
    { key: 'name', title: 'Назва' },
    { key: 'actions', title: 'Дії', width: '68px', sortable: false },
  ]

  const setExpandedKeys = entity => {
    if (!entity) return
    entity.forEach(d => {
      expandedKeys.value.push(d.id)
      setExpandedKeys(d.all_children)
      setExpandedKeys(d.employees)
      d.supervisor && expandedKeys.value.push(d.supervisor.id)
    })
  }
  const prepareEntity = (entity, type = 'department') => {
    const isDep = type === 'department'
    const isSup = type === 'supervisor'
    // const isEmployee = type === 'employee'

    const getName = () => {
      if (isDep) {
        return entity.name
      } else {
        const getPost = () => `(${entity.post || 'Посаду не вказано'})`

        return `${getUserName(entity)} ${getPost()}`
      }
    }

    const items = []
    if (isDep) {
      items.push(...(entity.all_children || []).map(d => prepareEntity(d)))
      if (entity.supervisor) {
        items.push(prepareEntity(entity.supervisor, 'supervisor'))
      }
      items.push(
        ...(entity.employees || []).map(e => prepareEntity(e, 'employee'))
      )
    }

    return {
      ...entity,
      name: getName(),
      isSupervisor: isSup,
      items: items,
      type,
    }
  }

  const formattedCompanyStructure = computed(() => {
    return companyStructure.value.map(d => prepareEntity(d))
  })

  const getCompanyStructure = () => {
    return axios.get(urlCompanyStructure()).then(res => {
      companyStructure.value = res.data
      setExpandedKeys(companyStructure.value)
      loading.value = false
    })
  }

  const expandAll = () => {
    expanded.value = [...expandedKeys.value]
  }
  const collapseAll = () => {
    expanded.value = []
  }

  const navigateToUser = item => {
    if (item.type !== 'department') {
      navigateToItem('single-user', item.id)
    }
  }

  getCompanyStructure()

  return {
    loading,
    companyStructure,
    navigateToUser,
    expandAll,
    collapseAll,
    expanded,
    headers,
    formattedCompanyStructure,
    ...useDepartment(companyStructure),
  }
}
