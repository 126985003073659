<template>
  <div class="padding">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center mb-3">
        <v-btn class="mr-1" @click="addRelatedOrder">
          <v-icon class="mr-2">{{ 'mdi-link-box-outline' }}</v-icon>
          Додати пов'язаний наказ
        </v-btn>
        <div class="mr-1">
          <v-tooltip text="Відв'язати наказ">
            <template #activator="{ props: tooltipProps }">
              <div v-bind="tooltipProps">
                <v-menu :close-on-content-click="false">
                  <template #activator="{ props: menuProps }">
                    <v-btn v-bind="menuProps">
                      <v-icon>{{ 'mdi-transit-connection-variant' }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, idx) in connections"
                      :key="idx"
                      style="cursor: pointer">
                      <v-list-item-title>
                        <div class="d-flex align-center">
                          <span class="d-block mr-2">{{
                            item.topic || item.name
                          }}</span>
                          <v-btn
                            icon
                            size="small"
                            variant="text"
                            @click="deleteRelatedOrder(item.id)">
                            <v-icon>{{ 'mdi-close-box-outline' }}</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-tooltip>
        </div>
        <div v-if="order.not_actual_orders">
          <v-tooltip text="Переглянути неактуальні накази">
            <template #activator="{ props }">
              <v-btn v-bind="props" @click="viewActualOrder">
                <v-icon>{{ 'mdi-hub-outline' }}</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
      </div>
      <v-btn-toggle v-model="toggle" :rounded="false" variant="outlined">
        <v-tooltip text="Відобразити в форматі дерева">
          <template #activator="{ props }">
            <v-btn icon="mdi-sitemap-outline" v-bind="props"></v-btn>
          </template>
        </v-tooltip>
        <v-tooltip text="Відобразити в форматі списка">
          <template #activator="{ props }">
            <v-btn icon="mdi-format-list-bulleted" v-bind="props"></v-btn>
          </template>
        </v-tooltip>
      </v-btn-toggle>
    </div>

    <div v-if="toggle === 0">
      <related-orders :order="order"></related-orders>
    </div>
    <div v-if="toggle === 1">
      <div v-if="parentOrders.length">
        <div v-for="item in parentOrders" :key="item.id">
          <RecursiveExpansionPanel :item="item" />
        </div>
      </div>
      <span v-else>Список порожній</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { navigateToItem } from '@/utils/helpers.js'
import { axios } from '@/plugins'
import { urlOrderRelatedDelete } from '@/utils/urls.js'
import { mapKeys } from 'lodash'
import RecursiveExpansionPanel from '@/components/expansion-panel/RecursiveExpansionPanel.vue'
import { openDialog } from '@/dialog/index.ts'
import RelatedOrders from '../related-orders/RelatedOrders.vue'
import { useRelatedOrders } from '../related-orders/relatedOrders.ts'

export default {
  name: 'Connections',
  components: {
    RecursiveExpansionPanel,
    RelatedOrders,
  },
  emits: ['update:order'],
  props: {
    order: { type: Object },
  },
  data: () => {
    return {
      expanded: [],
      toggle: 0,
      headers: [
        {
          title: 'Наказ',
          align: 'start',
          key: 'item',
          sortable: false,
          width: '100%',
        },
      ],
    }
  },
  setup(props, { emit }) {
    const connections = computed(() => {
      const errands = (props.order.errands || []).map(e => ({
        ...e,
        routeName: 'single-task',
        task: true,
      }))
      const related = (props.order.related || []).map(e => ({
        ...e,
        routeName: 'single-order',
        order: true,
      }))
      return [...related, ...errands]
    })
    const addRelatedOrder = () => {
      openDialog({
        name: 'RelatedOrder',
        action: 'create',
        dialogData: {
          orderId: props.order?.id,
        },
        params: {
          cb(newRelatedOrder) {
            const related = [...(props.order.related || []), newRelatedOrder]
            emit('update:order', { ...props.order, related })
          },
          submitLabel: "Зв'язати",
        },
      })
    }

    const viewActualOrder = () => {
      openDialog({
        name: 'SimpleDialog',
        action: 'create',
        dialogData: {
          nonActualOrders: props.order?.not_actual_orders || [],
        },
      })
    }

    const deleteRelatedOrder = relatedOrderId => {
      return axios
        .delete(urlOrderRelatedDelete(props.order.id), {
          data: {
            orderId: relatedOrderId,
          },
        })
        .then(() => {
          const related = props.order.related.filter(
            o => o.id !== relatedOrderId
          )
          emit('update:order', { ...props.order, related })
        })
    }

    return {
      connections,
      navigateToItem,
      addRelatedOrder,
      deleteRelatedOrder,
      viewActualOrder,
      ...useRelatedOrders(props.order),
    }
  },
}
</script>

<style lang="scss" scoped>
.main-chip {
  position: relative;

  .chip-line-top {
    width: 1px;
    height: 14px;
    display: block;
    background: #cccccc;
    position: absolute;
    transform: translate(-50%, -50%);
    top: -7px;
    left: 50%;
  }

  .chip-line-bottom {
    width: 1px;
    height: 14px;
    display: block;
    background: #cccccc;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: -20px;
  }

  .chip-line-general {
  }
}
</style>
