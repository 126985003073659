import { mapKeys } from 'lodash'
import { computed } from 'vue'

export function useRelatedOrders(order) {
  const prepareOrders = (orders, childrenKey) => {
    return orders.map(o => {
      o = mapKeys(o, (value, key) => {
        if (key === childrenKey) {
          return 'children'
        } else {
          return key
        }
      })
      if (o.children.length) {
        o.children = prepareOrders(o.children, childrenKey)
      }

      return o
    })
  }

  const parentOrders = computed(() => {
    return prepareOrders(
      order.tree_parent_related_orders,
      'tree_parent_related_orders'
    )
  })
  const childrenOrders = computed(() => {
    return prepareOrders(
      order.tree_children_related_orders,
      'tree_children_related_orders'
    )
  })

  return { parentOrders, childrenOrders }
}
