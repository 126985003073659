<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="97" height="34" viewBox="0 0 97 34" fill="none">
    <path d="M43.9545 8.18475H38V6.04285H52.5221V8.18475H46.5248V25.6412H43.9545V8.18475Z" fill="white"/>
    <path d="M57.1057 7.60643C57.1271 8.48461 56.4845 9.17001 55.4778 9.17001C54.5782 9.17001 53.9357 8.46319 53.9357 7.60643C53.9357 6.70683 54.5997 6 55.5421 6C56.5059 6 57.1057 6.70683 57.1057 7.60643ZM54.257 25.6412V11.5689H56.8272V25.6412H54.257Z" fill="white"/>
    <path d="M61.0683 15.3816C61.0683 13.9251 61.0469 12.7256 60.9612 11.569H63.2102L63.3173 13.8394H63.4029C64.1955 12.5114 65.502 11.2477 67.8153 11.2477C69.743 11.2477 71.1995 12.4043 71.7992 14.075H71.8635C72.2918 13.2825 72.8487 12.6828 73.427 12.233C74.2624 11.5904 75.2048 11.2477 76.5328 11.2477C78.3963 11.2477 81.1593 12.4686 81.1593 17.3521V25.6413H78.6533V17.6734C78.6533 14.9746 77.668 13.3467 75.5904 13.3467C74.1339 13.3467 72.9987 14.4177 72.5703 15.6814C72.4632 16.0241 72.3561 16.4953 72.3561 16.9666V25.6627H69.8715V17.2236C69.8715 14.9746 68.8862 13.3467 66.9371 13.3467C65.3307 13.3467 64.174 14.6319 63.7671 15.917C63.6171 16.3026 63.5529 16.7309 63.5529 17.1593V25.6627H61.0469V15.3816H61.0683Z" fill="white"/>
    <path d="M86.7497 19.0656C86.8139 22.5355 89.0201 23.9492 91.569 23.9492C93.411 23.9492 94.5034 23.6279 95.4672 23.2209L95.8956 25.0629C94.996 25.4699 93.4538 25.9411 91.2048 25.9411C86.8782 25.9411 84.2865 23.0924 84.2865 18.8514C84.2865 14.6105 86.7925 11.2691 90.8836 11.2691C95.4886 11.2691 96.7095 15.3173 96.7095 17.909C96.7095 18.4231 96.6453 18.83 96.6239 19.1085H86.7497V19.0656ZM94.2463 17.245C94.2678 15.6172 93.5824 13.0897 90.6908 13.0897C88.0991 13.0897 86.9639 15.4672 86.7711 17.245H94.2463Z" fill="white"/>
    <circle cx="17" cy="17" r="17" fill="#010E30"/>
    <path d="M9.45251 26V11.4545H11.6773V26H9.45251ZM10.5838 9.0303C10.1501 9.0303 9.77617 8.88194 9.46193 8.58523C9.15398 8.28851 9 7.93182 9 7.51515C9 7.09848 9.15398 6.74179 9.46193 6.44508C9.77617 6.14836 10.1501 6 10.5838 6C11.0174 6 11.3882 6.14836 11.6962 6.44508C12.0104 6.74179 12.1675 7.09848 12.1675 7.51515C12.1675 7.93182 12.0104 8.28851 11.6962 8.58523C11.3882 8.88194 11.0174 9.0303 10.5838 9.0303Z" fill="white"/>
    <path d="M17.7613 17.25V26H15.5365V11.4545H17.6859V13.7273H17.8745C18.2139 12.9886 18.7292 12.3952 19.4205 11.947C20.1119 11.4924 21.0043 11.2652 22.0979 11.2652C23.0783 11.2652 23.9362 11.4672 24.6715 11.8712C25.4068 12.2689 25.9787 12.875 26.3872 13.6894C26.7957 14.4975 27 15.5202 27 16.7576V26H24.7752V16.9091C24.7752 15.7664 24.4798 14.8763 23.889 14.2386C23.2983 13.5947 22.4875 13.2727 21.4568 13.2727C20.7466 13.2727 20.1119 13.4274 19.5525 13.7367C18.9995 14.0461 18.5627 14.4975 18.2421 15.0909C17.9216 15.6843 17.7613 16.404 17.7613 17.25Z" fill="white"/>
  </svg>
</template>

<style scoped lang="scss">

</style>
