import { io } from 'socket.io-client'

const getUrl = () => {
  switch (import.meta.env.VITE_APP_ENV) {
    case 'local':
      return 'http://localhost:4000'
    case 'dev':
      return 'https://io.erm.bestleasing.com.ua'
    case 'prod':
      return 'https://socket.io.bestleasing.com.ua'
    default:
      throw new Error('.env property VITE_APP_ENV is not valid')
  }
}

const socket = io(getUrl(), {
  extraHeaders: {
    apikey: 'IrA1VqblYy',
    'x-api-secret': 'G5Euomjeln',
  },
  reconnectionAttempts: 1,
})

const notificationChannel = 'intime_notification'
const documentSignatureSingleChannel = 'intime_document_signature_single'
const documentAgreementSingleChannel = 'intime_document_agreement_single'
const taskNotification = 'intime_task_single'

export {
  socket,
  notificationChannel,
  documentSignatureSingleChannel,
  documentAgreementSingleChannel,
  taskNotification,
}
