<template>
  <div>
    <v-checkbox
      v-model="filters.isActive"
      false-value="true"
      hide-details
      label="Звільнені"
      style="margin: 0"
      true-value="false" />
    <v-data-table
      :disable-pagination="true"
      :headers="columns"
      :hide-default-footer="true"
      :hide-default-header="!userList.length"
      :items="userList"
      :items-per-page="-1"
      :loading="loading"
      @dblclick:row="openRow">
      <template #bottom></template>
      <template #item.photo="{ item }">
        <BaseImage
          :src="item.photo"
          alt="photo"
          class="my-2"
          rounded
          width="50"></BaseImage>
      </template>
      <template #item.ageThisYear="{ item }"
        >{{ getCurrentYearDeclension(item?.ageThisYear) }}
      </template>
      <template #item.birthDate="{ item }"
        >{{ formatDate(item.birthDate, 'response') }}
      </template>
      <template #item.department="{ item }">
        {{ item.department?.name || '---' }}
      </template>
      <template #item.name="{ item }">
        {{ getUserName(item) || '---' }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { axios } from '@/plugins/index.js'
import { reactive, ref, watch } from 'vue'
import {
  getCurrentYearDeclension,
  getUserName,
  navigateToItem,
} from '@/utils/helpers.js'
import { urlUserBirthdaysList } from '@/utils/urls'
import { formatDate } from '@/plugins/axios/helpers'

export default {
  name: 'UsersBirthdayList',
  methods: { getCurrentYearDeclension, formatDate, getUserName },
  mounted() {
    this.getUsers()
  },
  setup() {
    const store = useStore()
    const loading = ref(true)
    const userList = ref([])
    const columns = ref([
      {
        key: 'photo',
        title: 'Фото',
      },
      {
        key: 'birthDate',
        title: 'Дата народження',
      },
      {
        key: 'ageThisYear',
        title: 'Виповнюється',
      },
      {
        key: 'name',
        title: 'ПІБ',
        width: 250,
      },
      {
        key: 'department',
        title: 'Департамент',
      },
      { key: 'post', title: 'Посада' },
    ])
    const filters = reactive({
      isActive: true,
    })
    const openRow = (e, row) => {
      navigateToItem('single-user', row.item.id)
    }
    const getUsers = () => {
      loading.value = true
      return axios.get(urlUserBirthdaysList({ filters })).then(async res => {
        userList.value = res.data
        loading.value = false

        return res.data
      })
    }

    watch(() => filters.isActive, getUsers)

    return {
      store,
      openRow,
      columns,
      userList,
      getUsers,
      navigateToItem,
      loading,
      filters,
    }
  },
}
</script>
