import NotFoundLayout from '@/layout/NotFoundLayout.vue'

export default [
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundLayout,
    name: 'notFound',
    meta: {
      title: 'Сторінку не знайдено',
    },
  },
]
