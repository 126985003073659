<template>
  <div>
    <v-data-table
      :disable-pagination="true"
      :headers="headers"
      :hide-default-footer="true"
      :hide-default-header="!history.length"
      :items="history"
      :items-per-page="-1"
      dense>
      <!-- eslint-disable-next-line -->
      <template #item.userName="{ item }">
        {{
          getUserName(item.userName, { initials: true, isFullName: true }) ||
          '---'
        }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.date="{ item }">
        {{ formatDate(item?.dateTo, 'response') || '---' }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.field="{ item }">
        {{ item?.field || '---' }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.oldValue="{ item }">
        <HistoryItem :value="item ? item.oldValue : null" />
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.newValue="{ item }">
        <HistoryItem :value="item ? item.newValue : null" />
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script>
import { ref } from 'vue'
import { axios } from '@/plugins/index.js'
import { urlOrderHistory } from '@/utils/urls.js'
import { formatDate } from '@/plugins/axios/helpers'
import HistoryItem from '@/pages/orders/single/tabs/history/HistoryItem.vue'
import { getUserName } from '@/utils/helpers'

export default {
  name: 'HistoryList',
  components: { HistoryItem },
  props: {
    order: { type: Object },
  },
  data: () => ({
    headers: [
      {
        title: 'Користувач',
        align: 'start',
        key: 'userName',
        sortable: false,
      },
      { title: 'Дата', key: 'date', sortable: false, align: 'start' },
      { title: 'Поле', key: 'field', sortable: false, align: 'start' },
      {
        title: 'Старе значення',
        key: 'oldValue',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Номе значення',
        key: 'newValue',
        sortable: false,
        align: 'start',
      },
    ],
  }),
  methods: { getUserName, formatDate },
  setup(props) {
    const history = ref([])
    const loading = ref(false)

    const getHistory = () => {
      loading.value = true
      return axios.get(urlOrderHistory(props.order.id)).then(res => {
        history.value.push(...res.data)
        loading.value = false

        return res.data
      })
    }
    getHistory()

    return {
      loading,
      history,
    }
  },
}
</script>

<style lang="scss" scoped></style>
