<template>
  <div>
    <b-input
      v-model="dep.name"
      :error-messages="getErrorMessages(v$.name)"
      class="mt-2 mb-3"
      label="Назва"
      placeholder="Введіть текст"
      @blur="v$.name.$touch()" />
    <b-select
      v-model="dep.parentId"
      :items="$directory.get('departmentList', dep.parent)"
      :loading="$loading.isLoading('departmentList')"
      class="mb-3"
      clearable
      label="Відноситься до департаменту"
      @focus="$directory.fill('departmentList')" />
    <div v-if="dialogAction === 'update'">
      <div class="mt-5 mb-3 text-lg font-semibold">Співробітники</div>
      <SelectEntities
        v-model:entities="employees"
        :addEntityHandler="addUser"
        :removeEntityHandler="removeUser"
        :sort="
          (a, b) => {
            // Сравниваем значения поля isSup
            if (isSupervisor(a.id) && !isSupervisor(b.id)) {
              return -1 // a идет перед b
            } else if (!isSupervisor(a.id) && isSupervisor(b.id)) {
              return 1 // b идет перед a
            } else {
              return 0 // Порядок не меняется
            }
          }
        "
        class="mb-3"
        noDialog
        usersOnly>
        <template #item-append-icon="{ entity }">
          <v-tooltip
            :text="
              isSupervisor(entity.id) ? 'Супервайзер' : 'Призначити керівником'
            "
            location="bottom">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                variant="text"
                @click="setSupervisor(entity.id)">
                <v-icon
                  >{{
                    isSupervisor(entity.id)
                      ? 'mdi-star-circle'
                      : 'mdi-star-check-outline'
                  }}
                </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </template>
      </SelectEntities>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { axios, required, useVuelidate } from '@/plugins'
import { cachedObject } from 'best-modules/utils'
import {
  urlDepartmentAddUser,
  urlDepartmentCreate,
  urlDepartmentRemoveSupervisor,
  urlDepartmentRemoveUser,
  urlDepartmentSetSupervisor,
  urlDepartmentUpdate,
} from '@/utils/urls.js'
import SelectEntities from '@/components/entities/SelectEntities.vue'
import { getErrorMessages } from '@/utils/helpers'
import { cloneDeep } from 'lodash'

export default {
  name: 'Department',
  components: {
    SelectEntities,
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  async setup(props) {
    const dep = cachedObject({
      name: null,
      parentId: 0,
      ...(props.dialog.dialogData?.department || {}),
    })
    dep.$setIgnore('employees', 'supervisor')

    const dialogAction = props.dialog ? props.dialog.action : null

    const rules = {
      name: { required },
    }

    const v$ = useVuelidate(rules, dep)

    const employees = computed({
      get() {
        return [dep.supervisor, ...(dep?.employees || [])].filter(Boolean)
      },
      set(val) {
        dep.employees.splice(0)
        dep.employees.push(...(val || []).filter(Boolean))
      },
    })

    const addUser = user => {
      return axios
        .post(urlDepartmentAddUser(user.id), { departmentId: dep.id })
        .then(res => {
          dep.employees.push(res.data)

          return res.data
        })
    }

    const removeUser = user => {
      return axios.delete(urlDepartmentRemoveUser(user.id)).then(() => {
        if (user.id === dep.supervisor?.id) {
          dep.supervisor = null
        }
        dep.employees = dep.employees.filter(u => u.id !== user.id)
      })
    }

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        if (dep.$hasChanges) {
          const url = (() => {
            switch (props.dialog.action) {
              case 'create':
                return urlDepartmentCreate()
              case 'update':
                return urlDepartmentUpdate(dep.id)
            }
          })()

          const req = cloneDeep(dep.$object)
          req.parentId ??= 0

          return axios.post(url, req).then(res => {
            return res
          })
        } else {
          return dep
        }
      }
    }

    const setSupervisor = userId => {
      if (isSupervisor(userId)) {
        return axios.delete(urlDepartmentRemoveSupervisor(userId)).then(() => {
          dep.employees.push(cloneDeep(dep.supervisor))
          dep.supervisor = null
        })
      } else {
        return axios.get(urlDepartmentSetSupervisor(userId)).then(res => {
          if (dep.supervisor) {
            dep.employees.push(cloneDeep(dep.supervisor))
          }
          dep.supervisor = res.data
          dep.employees = dep.employees.filter(e => e.id !== dep.supervisor.id)
        })
      }
    }

    const isSupervisor = userId => {
      return dep.supervisor?.id === userId
    }

    return {
      dep,
      v$,
      submit,
      addUser,
      removeUser,
      setSupervisor,
      employees,
      isSupervisor,
      dialogAction,
    }
  },
}
</script>
