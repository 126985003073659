<template>
  <div class="text-center" style="margin-top: 150px">
    <h1>Доступ заборонено</h1>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AccessDenied',
}
</script>

<style scoped lang="scss"></style>
