<template>
  <div
    :class="{ 'wrapper--disabled': loading }"
    :style="{
      border: outline ? '1px solid #49A2F4 !important' : '1px dashed #e0e4e7',
    }"
    class="wrapper"
    @click="handleSelect"
    @dragover.prevent
    @drop.prevent="handleDrop">
    <div class="uploader-icon mb-2">
      <v-progress-circular
        v-if="loading"
        :width="3"
        indeterminate
        size="small" />
      <v-icon
        v-else
        :icon="
          dropperVariant === 'default'
            ? 'mdi-folder'
            : 'mdi-file-document-multiple-outline'
        "
        color="#49A2F4" />
    </div>
    <div
      v-if="dropperVariant === 'blue-label'"
      class="text-body-2"
      style="max-width: 16rem; opacity: 0.4">
      Перемістіть ваш файл сюди або оберіть зі свого комп'ютеру
    </div>
    <span v-else class="label d-block">Завантажити</span>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { filesHandler, selectFile } from './selectFile'
import { SelectFileOptions } from './selectFile/types'

export type DropperVariant = 'default' | 'blue-label'

export default {
  name: 'FileDropper',
  emits: ['select'],
  props: {
    outline: {
      type: Boolean,
    },
    dropperVariant: {
      type: String as PropType<DropperVariant>,
      default: 'default',
    },
    selectOptions: {
      type: Object as PropType<SelectFileOptions>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: { filesHandler },
  setup(props, { emit }) {
    const handleSelect = () => {
      if (!props.loading) {
        selectFile(props.selectOptions).then(result => {
          emit('select', result)
        })
      } else return
    }
    const handleDrop = e => {
      filesHandler({
        fileList: e.dataTransfer.files,
        resolve: e => emit('select', e),
      })
    }

    return { handleSelect, handleDrop }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 4px;
  padding: 16px 8px;
  text-align: center;
  transition: 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &--disabled {
    background: #fbfbfb;
    cursor: default;

    .label {
      display: none !important;
    }
  }

  .uploader-icon {
    border-radius: 50%;
    background: #c7e2ff;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(.wrapper--disabled):hover {
    background: #eefafc;
  }

  .label {
    color: #49a2f4;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
