<template>
  <div>
    <v-data-table
      :items="agreementHistory"
      :headers="headers"
      :items-per-page="-1">
      <template #[`item.user`]="{ item }">
        {{ getUserName(item.user, { initials: true }) }}
      </template>
      <template #[`item.status`]="{ item }">
        <div class="font-weight-bold">
          <!-- -->
          <span v-if="item.newStatusId === 1"> Чорновик </span>
          <!-- -->
          <span
            v-else-if="
              [1, 3].includes(item.oldStatusId) && item.newStatusId === 3
            ">
            <span>
              Завізовано
              <span class="text-success"> (Погодив документ) </span>
            </span>
          </span>
          <!-- -->
          <span v-else-if="item.oldStatusId === 3 && item.newStatusId === 2">
            <span>
              Доопрацювання документу
              <span class="text-grey-darken-1">{{ item.text }}</span>
            </span>
          </span>
          <!-- -->
          <span v-else-if="item.oldStatusId === 2 && item.newStatusId === 3">
            <span>
              Доопрацьовано
              <span class="text-grey-darken-1">
                {{ item.text }}
              </span>
            </span>
          </span>
          <!-- -->
          <span v-else-if="item.newStatusId === 4" class="text-success">
            Погоджено всіми
          </span>
        </div>
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { ref, Ref, PropType } from 'vue'
import { Dialog } from '@/dialog'
import { DataTableHeader } from '@/utils/types/vuetify'
import { getUserName } from '@/utils/helpers.js'

export default {
  name: 'DocumentAgreementHistory',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getUserName },
  setup(props) {
    const agreementHistory: Ref<[]> = ref(props.dialog.dialogData.logs || [])
    const headers: DataTableHeader[] = [
      {
        key: 'user',
        title: 'ПІБ',
      },
      {
        key: 'created_at',
        title: 'Дата',
      },
      {
        key: 'status',
        title: 'Статус',
      },
    ]

    return { agreementHistory, headers }
  },
}
</script>
