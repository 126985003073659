<template>
  <v-data-table
    :expanded="Array.from(expandedSet)"
    :headers="[{ key: 'expand', title: '', width: '48px' }, ...headers]"
    :items="items"
    :items-per-page="-1"
    :item-value="itemValue"
    :class="{ 'border-none': recursiveDeepens }">
    <template
      v-for="slot in Object.keys($slots).filter(s => s.startsWith('item.'))"
      #[slot]="{ item }">
      <slot
        :name="slot"
        :item="item"
        :props="
          slot === `item.${firstHeaderKey}`
            ? { style: `padding-left: ${recursiveDeepens * 30}px` }
            : {}
        "></slot>
    </template>
    <template v-if="recursiveDeepens" #headers></template>
    <template #item.expand="{ item }">
      <v-btn
        :style="{ visibility: item.items?.length ? 'visible' : 'hidden' }"
        class="expand-btn"
        :class="{ 'expand-btn--active': expandedSet.has(item[itemValue]) }"
        icon="$expand"
        density="comfortable"
        variant="text"
        @click="toggleExpand(item[itemValue])"></v-btn>
    </template>
    <template #[`item.${firstHeaderKey}`]="{ item }">
      <div :style="{ 'padding-left': `${recursiveDeepens * 30}px` }">
        {{ item[headers[0].key] }}
      </div>
    </template>
    <template #expanded-row="{ item }">
      <tr v-if="item.items?.length">
        <td colspan="4" class="p-0">
          <TreeTable
            :recursive-deepens="recursiveDeepens + 1"
            :items="item.items">
            <template
              v-for="slot in Object.keys($slots).filter(s =>
                s.startsWith('item.')
              )"
              #[slot]="{ item: itm, props }">
              <slot :name="slot" :item="itm" :props="props"></slot>
            </template>
          </TreeTable>
        </td>
      </tr>
    </template>
    <template #bottom></template>
  </v-data-table>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'TreeTable',
  props: {
    recursiveDeepens: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const expandedSet = inject('expandedSet')
    const expand = inject('expand')
    const collapse = inject('collapse')
    const itemValue = inject('itemValue')
    const headers = inject('headers')
    const firstHeaderKey = inject('firstHeaderKey')

    const toggleExpand = value => {
      if (expandedSet.value.has(value)) {
        collapse(value)
      } else {
        expand(value)
      }
    }

    return {
      firstHeaderKey,
      expandedSet,
      toggleExpand,
      itemValue,
      headers,
    }
  },
}
</script>

<style lang="scss" scoped>
.expand-btn {
  transition: transform 0.2s;
  transform-origin: center;
  &--active {
    transform: rotate(180deg);
  }
}
</style>
