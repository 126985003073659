<template>
  <div>
    <BaseTextarea
      v-model="dialogData.text"
      :placeholder="dialog.dialogData.placeholder"
      :rows="5"
      :error-messages="getErrorMessages(v$.text)"></BaseTextarea>
  </div>
</template>

<script>
import BaseTextarea from '@/components/inputs/BaseTextarea.vue'
import { getErrorMessages } from '@/utils/helpers.js'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'Textarea',
  components: { BaseTextarea },
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup() {
    const dialogData = reactive({ text: null })
    const rules = { text: { required } }
    const v$ = useVuelidate(rules, dialogData)

    const submit = () => {
      return Promise.resolve(dialogData.text)
    }

    return { dialogData, v$, submit }
  },
}
</script>
