import { forIn } from 'lodash'
import { getFile } from '@/utils/helpers.js'
import { Module } from 'vuex'

type State = {
  image: Record<string, string | null>
  audio: Record<string, string | null>
}

const files: Module<State, any> = {
  namespaced: true,
  state() {
    return {
      image: {},
      audio: {},
    }
  },
  mutations: {
    setFile(
      state,
      payload: {
        fileType: keyof State
        originPath: string
        blobPath: string
      }
    ) {
      const { fileType, originPath, blobPath } = payload

      state[fileType][originPath] = blobPath
    },
    clearImages(state) {
      forIn(state.image, (_blobPath, originPath) => {
        state.image[originPath] = null
      })
    },
  },
  actions: {
    async loadFile(
      { state, commit },
      payload: {
        fileType: keyof State
        originPath: string
        force: boolean
      }
    ) {
      const { fileType, originPath, force = false } = payload

      if (!state[fileType][originPath] || force) {
        const blobPath = await getFile({ path: originPath, type: 'path' })

        commit('setFile', { fileType, originPath, blobPath })
      }
    },
  },
}

export { files }
