import { axios, useStore } from '@/plugins/index.js'
import { urlDepartmentDelete } from '@/utils/urls.js'
import { confirm } from '@/plugins/confirm/index.ts'
import { openDialog } from '@/dialog'
import { setSnackbar } from 'best-modules/plugins'
import { cloneDeep } from 'lodash'
import { pushDirectoryItem, removeDirectoryItem } from '@/directory/index.ts'

export const findDepartment = (departmentId, arr) => {
  if (!departmentId) return null
  for (const idx in arr) {
    const item = arr[idx]
    if (item.id === departmentId) {
      return item
    } else if (item.all_children?.length) {
      const dep = findDepartment(departmentId, item.all_children)
      if (dep) return dep
    }
  }
}

export function useDepartment(companyStructure) {
  const store = useStore()

  const pushDepartment = (department, parentId) => {
    const dep = {
      employees: [],
      all_children: [],
      supervisor: null,
      ...department,
    }
    const parentDepartment = findDepartment(parentId, companyStructure.value)

    if (parentDepartment) {
      parentDepartment.all_children.push(dep)
    } else {
      companyStructure.value.push(dep)
    }
  }

  const removeDepartment = (departmentId, parentId) => {
    const parentDepartment = findDepartment(parentId, companyStructure.value)
    if (parentDepartment) {
      parentDepartment.all_children = parentDepartment.all_children.filter(
        d => {
          return d.id !== departmentId
        }
      )
    } else {
      companyStructure.value = companyStructure.value.filter(
        d => d.id !== departmentId
      )
    }
  }

  const moveDepartment = (department, from, to) => {
    removeDepartment(department.id, from)
    pushDepartment(department, to)
  }

  const createDepartment = () => {
    openDialog({
      name: 'Department',
      action: 'create',
      params: {
        cb: dep => {
          pushDepartment(dep, dep.parentId)
          pushDirectoryItem('departmentList', dep)
        },
      },
    })
  }

  const updateDepartment = department => {
    openDialog({
      name: 'Department',
      action: 'update',
      params: {
        cb: data => {
          if (data) {
            if (![department.parentId, void [0]].includes(data.parentId)) {
              moveDepartment(department, department.parentId, data.parentId)
            }
            const dep = findDepartment(department.id, companyStructure.value)
            Object.assign(dep, data)
          }
        },
      },
      dialogData: {
        department: cloneDeep(department),
      },
    })
  }

  const deleteDepartment = department => {
    confirm({
      text: 'Ви впевненні що хочете видалити департамент на завжди?',
    }).then(() => {
      {
        const handleChildren = dep => {
          moveDepartment(dep, dep.parentId, 0)
          if (dep.all_children?.length) {
            dep.all_children.forEach(handleChildren)
          }
        }

        return axios.delete(urlDepartmentDelete(department.id)).then(() => {
          if (department.all_children?.length) {
            department.all_children.forEach(handleChildren)
          }

          removeDepartment(department.id, department.parentId)
          removeDirectoryItem('departmentList', department.id)

          setSnackbar({ text: 'Департамент успішно видалено' })
        })
      }
    })
  }

  const getDepartmentActions = department => {
    return [
      {
        label: 'Видалити департамент',
        icon: 'pi pi-delete-left',
        action: () => deleteDepartment(department),
      },
      {
        label: 'Редагувати',
        icon: 'pi pi-pencil',
        action: () => updateDepartment(department),
      },
    ]
  }

  return {
    createDepartment,
    deleteDepartment,
    updateDepartment,
    getDepartmentActions,
  }
}
