<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn class="mb-2" @click="createDocument">
        <v-icon>mdi-plus</v-icon>
        Створити документ
      </v-btn>
    </div>
    <Filter
      v-model="filters"
      v-model:loading="loading"
      section="document"
      @clear="clearFilters"
      @filter="() => pgTrigger++" />
    <Skeleton v-if="loading" />
    <div v-else>
      <v-data-table
        :disable-pagination="true"
        :headers="columns"
        :hide-default-footer="true"
        :hide-default-header="!documents.length"
        :items="documents"
        :items-per-page="-1"
        :sort-desc="true"
        dense
        @dblclick:row="
          (e, row) => {
            navigateToDocument(row.item.id)
          }
        ">
        <template #item.created_at="{ item }">
          <span style="white-space: nowrap">
            {{ isCurrentDate(item.created_at) }}
          </span>
        </template>
        <template #item.type="{ item }">
          {{ item.type?.name }}
        </template>
        <template #item.name="{ item }">
          <v-tooltip :text="item?.name">
            <template #activator="{ props }">
              <div class="text-truncate" style="width: 250px" v-bind="props">
                <span>
                  {{ item.name }}
                </span>
              </div>
            </template>
          </v-tooltip>
        </template>
        <template #item.number="{ item }">
          <span style="white-space: nowrap">
            {{ item.number }}
          </span>
        </template>
        <template #item.status="{ item }">
          <span style="white-space: nowrap">
            {{ item.status?.name }}
          </span>
        </template>
        <template #item.author="{ item }">
          <span style="white-space: nowrap">
            {{ getUserName(item.author, { initials: true }) }}
          </span>
        </template>
        <template #[`item.contractors`]="{ item }">
          <v-menu>
            <template #activator="{ props }">
              <span v-if="item.contractors.length" class="link" v-bind="props">
                Показати
              </span>
              <span v-else>Відсутні</span>
            </template>
            <v-card>
              <v-card-text>
                <div
                  v-for="(contractor, idx) in item.contractors"
                  :key="contractor.id"
                  class="link"
                  :class="{ 'mb-2': item.contractors.length - 1 > idx }"
                  @click.stop="
                    navigateToItem(
                      'single-contractor',
                      contractor?.contractorId
                    )
                  ">
                  {{ contractor.contractor.name }}
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template #bottom></template>
      </v-data-table>
    </div>
    <Pagination :getItems="getDocuments" :trigger="pgTrigger" />
  </div>
</template>

<script lang="ts">
import { getUserName, isCurrentDate, navigateToItem } from '@/utils/helpers.js'
import Skeleton from '@/components/Skeleton.vue'
import Filter from '@/components/Filter.vue'
import Pagination from '@/components/Pagination.vue'
import { useDocumentList } from '@/pages/documents/documentList.js'
import { inject } from 'vue'
import { documentActionKey } from './injectionKeys'

export default {
  components: { Filter, Pagination, Skeleton },
  methods: { isCurrentDate, getUserName },
  setup() {
    const documentAction = inject(documentActionKey)

    const navigateToDocument = (id: number) => {
      navigateToItem(`document-${documentAction.value}-single`, id)
    }

    return { ...useDocumentList(), navigateToItem, navigateToDocument }
  },
}
</script>

<style lang="scss" scoped></style>
