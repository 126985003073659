<template>
  <div v-if="connectedDocument" class="mb-3">
    <div class="font-weight-bold">{{ connectedDocument.label }}</div>
    <span class="link" @click="$router.push(connectedDocument.route)">
      {{ connectedDocument.name }}
    </span>
  </div>
</template>

<script lang="ts">
import { documentActionKey } from '@/pages/documents/injectionKeys'
import { computed, inject } from 'vue'

export default {
  name: 'ConnectedDocument',
  props: {
    document: { type: Object },
  },
  setup(props) {
    const documentAction = inject(documentActionKey)

    const connectedDocument = computed<null | {
      name: string
      label: string
      route: { name: string; params: { id: number } }
    }>(() => {
      if (
        documentAction.value === 'agreement' &&
        props.document.related_document_signature
      ) {
        const { id, name } = props.document.related_document_signature
        return {
          name,
          label: 'Підписання',
          route: {
            name: 'document-signature-single',
            params: { id },
          },
        }
      }
      if (
        documentAction.value === 'signature' &&
        props.document.related_document_agreement
      ) {
        const { id, name } = props.document.related_document_agreement
        return {
          name,
          label: 'Візування',
          route: {
            name: 'document-agreement-single',
            params: { id },
          },
        }
      }
      return null
    })

    return { connectedDocument, documentAction }
  },
}
</script>

<style scoped lang="scss"></style>
