<template>
  <div>
    <cropper
      v-if="image"
      :auto-zoom="true"
      :src="image"
      :stencil-size="{
        width: 280,
        height: 280,
      }"
      class="cropper"
      image-restriction="stencil"
      @change="changeImg" />

    <SubmitButtons
      class="mt-3"
      submit-label="Зберегти"
      :loading="loading"
      :submit="handleAcceptChange"
      :cancel="handleRejectChange"></SubmitButtons>
  </div>
</template>

<script lang="ts">
import { ref, Ref, PropType } from 'vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import SubmitButtons from '@/components/SubmitButtons.vue'

export default {
  components: {
    SubmitButtons,
    Cropper,
  },
  emits: ['change'],
  props: {
    image: {
      type: String as PropType<string>,
    },
    loading: {
      type: Boolean as PropType<boolean>,
    },
  },
  setup(props, { emit }) {
    const canvasImage: Ref<Blob | null> = ref(null)

    const changeImg = ({ canvas }: { canvas: HTMLCanvasElement }): void => {
      canvas.toBlob((blob: Blob) => {
        canvasImage.value = blob
      }, 'image/png')
    }

    const handleRejectChange = (): void => {
      emit('change', null)
    }

    const handleAcceptChange = (): void => {
      emit('change', canvasImage.value)
    }

    return {
      changeImg,
      handleRejectChange,
      handleAcceptChange,
    }
  },
}
</script>

<style lang="scss" scoped>
.cropper {
  height: 300px;
  width: 100%;
  background: transparent;
}

.vue-preview.vue-preview--fill.vue-rectangle-stencil__preview {
  border-radius: 50% !important;
}
</style>
