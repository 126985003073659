<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn class="d-flex align-center" variant="text" @click="downloadPf">
        <v-icon
          class="mr-2"
          size="large"
          icon="mdi-printer"
          style="margin-bottom: -2px"></v-icon>
        <span>Роздрукувати форму звіту</span>
      </v-btn>
    </div>
    <v-data-table
      :disable-pagination="true"
      :headers="headers"
      :hide-default-footer="true"
      :hide-default-header="!order.familiarizations.length"
      :items="order.familiarizations || []"
      :items-per-page="-1"
      dense>
      <!-- eslint-disable-next-line -->
      <template #item.user="{ item }">
        {{ getUserName(item.user) || '---' }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.familiarizationDate="{ item }">
        <span v-if="item?.familiarizationDate">{{
          formatDate(item?.familiarizationDate, 'response')
        }}</span>
        <span v-else>---</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.isFamiliarization="{ item }">
        <span>{{ item.isFamiliarization ? 'Так' : 'Ні' }}</span>
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script>
import { getUserName } from '@/utils/helpers'
import { formatDate } from '@/plugins/axios/helpers'
import { openDialog } from '@/dialog'

export default {
  name: 'Familiarization',
  props: {
    order: {
      type: Object,
    },
  },
  methods: { formatDate, getUserName },
  setup() {
    const downloadPf = () => {
      openDialog({
        name: 'FamiliarizationReport',
        action: 'create',
      })
    }

    const headers = [
      {
        title: 'Користувач',
        align: 'start',
        key: 'user',
        width: '300px',
        sortable: false,
      },
      {
        title: 'Ознайомлений',
        key: 'isFamiliarization',
        sortable: false,
        width: '220px',
        align: 'start',
      },
      {
        title: 'Дата',
        key: 'familiarizationDate',
        sortable: false,
        align: 'start',
      },
    ]

    return {
      downloadPf,
      headers,
    }
  },
}
</script>
