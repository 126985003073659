<template>
  <div>
    <div class="d-flex justify-center">
      <BaseImage
        :src="photo"
        rounded
        alt="user-photo"
        class="photo"
        width="256" />
    </div>
    <SubmitButtons
      class="mt-3"
      submit-label="Змінити"
      cancel-label="Видалити"
      :submit="() => $emit('select', 'selectPhoto')"
      :cancel="() => $emit('select', 'deletePhoto')" />
  </div>
</template>

<script lang="ts">
import SubmitButtons from '@/components/SubmitButtons.vue'
import { PropType } from 'vue'

export default {
  name: 'Actions',
  components: { SubmitButtons },
  emits: ['select'],
  props: {
    photo: {
      type: [String, null] as PropType<string | null>,
    },
  },
}
</script>
