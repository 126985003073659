import { FileAccept } from '@/components/filesUploader/selectFile/types'

export const defaults: { confirm: string; accept: FileAccept[] } = {
  accept: [
    '.png',
    '.jpg',
    '.jpeg',
    '.svg',
    '.doc',
    '.docx',
    '.pdf',
    '.xls',
    '.xlsx',
    '.rar',
    '.zip',
  ],
  confirm:
    'Ви впевнені що хочете завантажити новий документ, поточний документ буде втрачено?', // eslint-disable-line max-len
}
