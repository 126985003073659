<template>
  <div>
    <auth
      v-if="!authToken && accessToken"
      :accessToken="accessToken"
      @auth-token="setAuthToken"></auth>
    <document v-if="authToken" :authToken="authToken"></document>
  </div>
</template>

<script lang="ts">
import Document from './Document.vue'
import Auth from './Auth.vue'
import { useRoute } from 'vue-router'
import { ref } from 'vue'

export default {
  name: 'ShareDocumentIndex',
  components: {
    Document,
    Auth,
  },
  setup() {
    const route = useRoute()
    const clientAccessId = +route.params.clientAccessId
    const authToken = ref<string | null>(
      (route.query.authToken || null) as string | null
    )
    const accessToken = ref<string | null>(
      (route.query.accessToken || null) as string | null
    )

    if (!localStorage.getItem('clientAccesses')) {
      localStorage.setItem('clientAccesses', '{}')
    }
    const clientAccesses: Record<number, string | null> = JSON.parse(
      localStorage.getItem('clientAccesses')
    )

    if (!clientAccesses[clientAccessId] && authToken.value) {
      /* set authToken from route query */
      setAuthToken(authToken.value)
    }
    if (clientAccesses[clientAccessId] && !authToken.value) {
      /* set authToken from localStorage */
      setAuthToken(clientAccesses[clientAccessId])
    }

    function setAuthToken(token: string) {
      authToken.value = token
      clientAccesses[clientAccessId] = token
      localStorage.setItem('clientAccesses', JSON.stringify(clientAccesses))
    }

    return { authToken, accessToken, setAuthToken }
  },
}
</script>

<style scoped lang="scss"></style>
