<template>
  <v-row class="mt-2">
    <v-col cols="12" md="12" sm="12">
      <b-input
        v-model="templateData.name"
        :error-messages="getErrorMessages(v$.name)"
        hide-details
        label="Назва"
        placeholder="Введіть текст"
        @blur="v$.name.$touch()" />
    </v-col>
    <v-col cols="12" md="12" sm="12">
      <b-select
        v-model="templateData.documentTypeId"
        :items="filteredTypes"
        :loading="$loading.isLoading('documentTypes')"
        @focus="$directory.fill('documentTypes')"
        class="mb-1"
        hide-details
        item-title="name"
        item-value="id"
        label="Тип документу"
        placeholder="Оберіть зі списку" />
    </v-col>
  </v-row>
</template>

<script>
import { computed, ref } from 'vue'
import { getErrorMessages } from '@/utils/helpers'
import { axios, required, useVuelidate } from '@/plugins'
import {
  urlCreateTemplateRoutingDocument,
  urlUpdateTemplateRoutingDocument,
} from '@/utils/urls'
import { setSnackbar } from 'best-modules/plugins'
import { getDirectory } from '@/directory/index.ts'

export default {
  name: 'TemplateRoutingDocument',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  async setup(props) {
    const templateData = ref({})
    const submitLoading = ref(false)

    templateData.value = {
      ...props.dialog.dialogData.documentData,
    }

    const filteredTypes = computed(() => {
      const unspecifiedItem = {
        name: 'Не визначено',
        id: null,
      }

      return [
        unspecifiedItem,
        ...getDirectory('documentTypes', templateData.value.documentType),
      ]
    })

    const rules = computed(() => {
      return {
        name: { required },
      }
    })
    const v$ = useVuelidate(rules, templateData)

    const submit = async () => {
      v$.value.$validate()
      if (!v$.value.$error) {
        submitLoading.value = true
        const url = props.dialog.dialogData.isUpdateTemplate
          ? urlUpdateTemplateRoutingDocument(templateData.value.id)
          : urlCreateTemplateRoutingDocument()
        return axios.post(url, templateData.value).then(res => {
          submitLoading.value = false
          setSnackbar({
            text: `Маршрут для документа ${
              props.dialog.dialogData.isUpdateTemplate ? 'оновлено' : 'створено'
            }`,
          })
          return res.data
        })
      }
    }
    return {
      templateData,
      submitLoading,
      filteredTypes,
      submit,
      v$,
    }
  },
}
</script>

<style lang="scss" scoped></style>
