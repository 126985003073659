<template>
  <div>
    <b-autocomplete
      :key="searchRenderKey"
      v-model="relatedOrder.orderId"
      :error-messages="getErrorMessages(v$.orderId)"
      :item-title="
        item => `${item.name} ${item.number ? `(${item.number})` : null}`
      "
      :items="searchOrderList"
      class="mb-4"
      item-value="id"
      no-filter
      placeholder="оберіть наказ"
      @blur="v$.orderId.$touch()"
      @update:model-value="handleModelUpdate"
      @update:search="handleSearch"></b-autocomplete>
  </div>
</template>

<script lang="ts">
import { reactive, ref, PropType, Ref } from 'vue'
import { axios, required, useVuelidate } from '@/plugins/index.js'
import { urlOrderRelatedAdd, urlSearchOrder } from '@/utils/urls.js'
import { debounce } from 'lodash'
import { getErrorMessages } from '@/utils/helpers.js'
import { Dialog } from '@/dialog'

export default {
  name: 'RelatedOrder',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getErrorMessages },
  async setup(props) {
    const searchOrderList = ref([])
    const searchRenderKey: Ref<number> = ref(1)

    const relatedOrder = reactive({
      orderId: null,
    })

    const selectingItem: Ref<boolean> = ref(false)

    const handleModelUpdate = (orderId: number): void => {
      selectingItem.value = true
      relatedOrder.orderId = orderId
    }

    const handleSearch = debounce((search: string) => {
      if (selectingItem.value) {
        selectingItem.value = false
        return
      }

      if (search) {
        return axios.get(urlSearchOrder(search)).then(res => {
          if (!res.data.message) {
            searchOrderList.value = res.data
          }
        })
      }
    }, 400)

    const v$ = useVuelidate({ orderId: { required } }, relatedOrder)

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$error) {
        return axios
          .post(
            urlOrderRelatedAdd(props.dialog.dialogData.orderId),
            relatedOrder
          )
          .then(res => {
            return res.data
          })
      }
    }

    return {
      relatedOrder,
      v$,
      submit,
      searchOrderList,
      handleSearch,
      handleModelUpdate,
      searchRenderKey,
    }
  },
}
</script>
