<template>
  <div>
    <v-pagination
      v-model="paginationData.current_page"
      :length="paginationData.last_page"
      total-visible="7"
      @update:model-value="paginate" />
  </div>
</template>

<script>
import { watch } from 'vue'
import { cachedObject } from 'best-modules/utils'

export default {
  props: {
    getItems: { type: Function, required: true },
    trigger: { type: Number, default: 1 },
  },
  setup(props) {
    const paginationData = cachedObject({
      current_page: 1,
      count: 1,
      per_page: 1,
      total: 1,
    })

    const paginate = async page => {
      const res = await props.getItems(page)
      if (res) {
        delete res.data
        paginationData.$set(res)
      } else {
        console.warn('paginate() - res is invalid')
      }
    }

    watch(
      () => props.trigger,
      () => paginate(1),
      { immediate: true }
    )

    return { paginationData, paginate }
  },
}
</script>

<style lang="scss" scoped>
/* Add your styles here if needed */
</style>
