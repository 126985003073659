<template>
  <div>
    <component
      :is="dialog.dialogData.type === 'phone' ? BInputPhone : BInput"
      v-model="contact.text"
      :label="contactType.name"
      :placeholder="`введіть ${contactType.name.toLowerCase()}`"
      :error-messages="getErrorMessages(v$.text)"
      @blur="v$.text.$touch()"></component>
    <b-select
      v-model="contact.typeId"
      label="Тип"
      placeholder="оберіть тип"
      :items="$directory.get(contactType.directory, contact.type)"
      :loading="$loading.isLoading(contactType.directory)"
      :error-messages="getErrorMessages(v$.typeId)"
      @blur="v$.typeId.$touch()"
      @focus="$directory.fill(contactType.directory)"></b-select>
  </div>
</template>

<script>
import { BInputPhone, BInput } from 'best-modules/components'
import { reactive } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { fillDirectory } from '@/directory/index.ts'
import axios from '@/plugins/axios'
import {
  urlContractorAddressUpdate,
  urlContractorAddressCreate,
  urlContractorPhoneCreate,
  urlContractorPhoneUpdate,
  urlContractorEmailCreate,
  urlContractorEmailUpdate,
} from '@/utils/urls.js'

export default {
  name: 'Contact',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const getContactType = () => {
      const types = {
        address: {
          name: 'Адреса',
          directory: 'addressTypes',
          url: {
            create: urlContractorAddressCreate,
            update: urlContractorAddressUpdate,
          },
        },
        phone: {
          name: 'Телефон',
          directory: 'phoneTypes',
          url: {
            create: urlContractorPhoneCreate,
            update: urlContractorPhoneUpdate,
          },
        },
        email: {
          name: 'Email',
          directory: 'emailTypes',
          url: {
            create: urlContractorEmailCreate,
            update: urlContractorEmailUpdate,
          },
        },
      }
      return types[props.dialog.dialogData.type]
    }
    const contactType = getContactType()

    const contact = reactive({
      text: null,
      typeId: null,
      contractorId: props.dialog.dialogData.contractorId,
    })
    if (props.dialog.action === 'update') {
      Object.assign(contact, props.dialog.dialogData.contact)
      contact.text =
        props.dialog.dialogData.contact[props.dialog.dialogData.type]
    }
    if (props.dialog.action === 'create') {
      fillDirectory(contactType.directory)
    }

    const rules = {
      text: {
        required,
        email: props.dialog.dialogData.type === 'email' ? email : {},
      },
      typeId: { required },
    }

    const v$ = useVuelidate(rules, contact)

    const submit = () => {
      const url =
        props.dialog.action === 'create'
          ? contactType.url.create()
          : contactType.url.update(contact.id)

      const request = {
        typeId: contact.typeId,
        contractorId: props.dialog.dialogData.contractorId,
      }
      request[props.dialog.dialogData.type] = contact.text

      return axios.post(url, request)
    }

    return { contact, submit, v$, contactType, BInputPhone, BInput }
  },
}
</script>
