<template>
  <div>
    <Filter
      v-model="filters"
      v-model:loading="loading"
      section="control"
      @filter="() => pgTrigger++">
      <template #append-search>
        <printed-form></printed-form>
      </template>
    </Filter>
    <v-data-table
      :headers="headers.slice(0, -1)"
      :hide-default-footer="true"
      :hide-default-header="!controlList.length"
      :items="controlList"
      :items-per-page="-1"
      :loading="loading"
      dense
      @dblclick:row="openRow">
      <template #item.executor="{ item }">
        <span>{{
          getUserName(item.executor, { initials: true }) || '---'
        }}</span>
      </template>
      <template #item.author="{ item }">
        <span>{{ getUserName(item.author, { initials: true }) || '---' }}</span>
      </template>
      <template #item.completedDate="{ item }">
        <div class="d-flex align-center gap-3">
          <span>{{ item.completedDate || '---' }}</span>
          <v-menu v-if="item.completedDate" open-on-hover>
            <template #activator="{ props }">
              <v-btn
                variant="plain"
                size="small"
                density="compact"
                icon="mdi-information-variant"
                v-bind="props"></v-btn>
            </template>

            <v-card>
              <v-card-text>
                {{ item.completionReport || item.rejectComment }}
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template #item.connect="{ item }">
        <span
          v-if="item.entityTypeId && item.entityId"
          class="link"
          @click.stop="
            $router.push({
              name:
                item.entityTypeId === 3
                  ? 'single-order'
                  : 'correspondence-single',
              params: { id: item.entityId },
            })
          "
          >{{ item.entityTypeId === 3 ? 'Наказ' : 'Кореспонденція' }}</span
        >
        <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
      </template>
      <template #[`item.contractor`]="{ item }">
        <v-tooltip v-if="item.contractor" :text="item.contractor?.name" bottom>
          <template #activator="{ props }">
            <span
              class="link"
              style="
                display: block;
                width: 230px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              v-bind="props"
              @click.stop="
                $router.push({
                  name: 'single-contractor',
                  params: { id: item?.contractor?.id },
                })
              "
              >{{ item.contractor?.name }}</span
            >
          </template>
        </v-tooltip>
        <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
      </template>
    </v-data-table>
    <Pagination :getItems="getControlUsers" :trigger="pgTrigger" />
  </div>
</template>

<script lang="ts">
import { reactive, ref } from 'vue'
import { axios } from '@/plugins'
import { urlGetControlUsers } from '@/utils/urls'
import { getUserName, navigateToItem } from '@/utils/helpers'
import Pagination from '@/components/Pagination.vue'
import Filter from '@/components/Filter.vue'
import { headers } from './headers'
import PrintedForm from '@/pages/tasks/PrintedForm.vue'

export default {
  name: 'ControlList',
  components: { PrintedForm, Filter, Pagination },
  methods: { getUserName },
  setup() {
    const loading = ref(false)
    const controlList = ref([])
    const filters = reactive({
      search: null,
      statusId: null,
      completedEndDate: null,
      completedStartDate: null,
      deadlineEndDate: null,
      deadlineStartDate: null,
    })
    const openRow = (e, row) => {
      navigateToItem('single-task', row.item.id)
    }

    const getControlUsers = () => {
      loading.value = true
      return axios.get(urlGetControlUsers({ filters })).then(res => {
        controlList.value = res.data.data
        loading.value = false

        return res.data
      })
    }
    const pgTrigger = ref(1)

    return {
      headers,
      pgTrigger,
      getControlUsers,
      openRow,
      loading,
      controlList,
      filters,
    }
  },
}
</script>

<style lang="scss" scoped></style>
