<template>
  <div>
    <div v-if="isArray && isDoc">
      <FileItem v-for="file in value" :key="file.path" :file="file" disabled />
    </div>
    <div v-else>
      <span>{{ isArray ? 'Відсутнє' : value || '---' }}</span>
    </div>
  </div>
</template>

<script>
import FileItem from '@/components/filesUploader/FileItem.vue'
import { computed } from 'vue'

export default {
  name: 'HistoryItem',
  components: { FileItem },
  props: ['value'],
  setup(props) {
    const isArray = computed(() => Array.isArray(props.value))
    const isDoc = computed(
      () =>
        isArray.value &&
        props.value.length &&
        'size' in props.value[0] &&
        'path' in props.value[0]
    )

    return { isArray, isDoc }
  },
}
</script>
