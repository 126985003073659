<template>
  <div class="d-flex justify-end">
    <v-menu>
      <template #activator="{ props }">
        <v-btn v-if="isMenu" v-bind="props">
          Дії
          <v-icon class="ml-2">{{ 'mdi-pencil' }}</v-icon>
        </v-btn>
        <v-btn v-else icon size="small" v-bind="props" variant="text">
          <v-icon>{{ 'mdi-dots-vertical' }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, idx) in actions"
          :key="idx"
          :disabled="item.disabled"
          style="cursor: pointer"
          @click="item.action">
          <div class="d-flex align-center">
            <v-icon v-if="item.icon" class="mr-3" style="font-size: 15px"
              >{{ item.icon }}
            </v-icon>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'TableActions',
  props: {
    actions: { type: Array },
    isMenu: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped></style>
