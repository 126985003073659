<template>
  <div class="mb-2">
    <div>
      <div
        style="cursor: pointer"
        class="d-flex align-center justify-space-between mb-2"
        @click="setExpanded(!isExpanded)">
        <span class="d-block label">{{ label }}</span>
        <v-btn icon size="x-small" variant="text">
          <v-icon
            :style="{
              transform: isExpanded ? 'rotate(360deg)' : 'rotate(180deg)',
              transition: '0.3s',
            }"
            height="14px"
            icon="mdi-arrow-up"
            width="15px" />
        </v-btn>
      </div>
      <div v-show="isExpanded" style="transition: 0.3s all">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    label: { type: String, default: '' },
    expanded: { type: Boolean, default: false },
  },
  setup(props) {
    const isExpanded = ref(props.expanded)
    const setExpanded = val => {
      isExpanded.value = val
    }

    return {
      isExpanded,
      setExpanded,
    }
  },
}
</script>

<style lang="scss" scoped>
.label {
  color: #010e30;
  font-size: 14px;
  font-weight: 600;
}
</style>
