<template>
  <div>
    <v-data-table
      :items="familiarizationUsers"
      :headers="headers"
      :items-per-page="-1">
      <template #[`item.user`]="{ item }">
        {{ getUserName(item.user) }}
      </template>
      <template #[`item.isFamiliarization`]="{ item }">
        {{ item.isFamiliarization ? 'Так' : 'Ні' }}
      </template>
      <template #[`item.familiarizationDate`]="{ item }">
        {{ item.familiarizationDate || '---' }}
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { PropType, ref, Ref } from 'vue'
import { Dialog } from '@/dialog'
import { DataTableHeader } from '@/utils/types/vuetify'
import { getUserName } from '@/utils/helpers.js'

export default {
  name: 'FamiliarizationUsers',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getUserName },
  setup(props) {
    const familiarizationUsers: Ref<[]> = ref(
      props.dialog.dialogData.familiarizations || []
    )
    const headers: DataTableHeader[] = [
      {
        key: 'user',
        title: 'ПІБ',
      },
      {
        key: 'isFamiliarization',
        title: 'Ознайомлений',
      },
      {
        key: 'familiarizationDate',
        title: 'Дата ознайомлення',
      },
    ]

    return { familiarizationUsers, headers }
  },
}
</script>
