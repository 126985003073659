<template>
  <div>
    <div class="mb-1">
      <div
        v-if="signature"
        class="text-success font-weight-bold d-flex align-center gap-1">
        <v-icon size="small">mdi-check</v-icon>
        Підписано
      </div>
      <div
        v-else
        class="text-warning font-weight-bold d-flex align-center gap-1">
        <v-icon size="small">mdi-clock-time-eight-outline</v-icon>
        Очікується підпис
      </div>
    </div>
    <div class="pl-5">
      <div class="font-weight-bold text-truncate">
        <span>{{ contractor?.name }}</span>
      </div>
      <div v-if="contractor?.code" class="font-weight-bold">
        {{ contractor?.code }}
      </div>
      <div v-else class="text-disabled">ЄДРПОУ/ІНН - не вказано</div>
      <div v-if="contractor?.signer && !signature" class="text-disabled">
        <span v-if="typeof contractor.signer === 'boolean'">
          Співробітник компанії
        </span>
        <span v-else>{{ contractor.signer }}</span>
      </div>

      <signature-info
        v-if="signature"
        :signature="signature"
        class="mt-2"></signature-info>
    </div>
  </div>
</template>

<script lang="ts">
import SignatureInfo from './SignatureInfo.vue'
import { Signature } from './types'
import { PropType } from 'vue'

export default {
  name: 'Signature',
  components: {
    SignatureInfo,
  },
  props: {
    signature: {
      type: Object as PropType<Signature>,
    },
    contractor: {
      type: Object as PropType<{
        name: string
        code: string
        signer?: string | boolean
      }>,
    },
    title: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss"></style>
