<template>
  <div>
    <correspondence-list
      v-if="$route.name === 'correspondence-list'"></correspondence-list>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import CorrespondenceList from './CorrespondenceList.vue'

export default {
  name: 'CorrespondenceIndex',
  components: { CorrespondenceList },
}
</script>
