<template>
  <div ref="entitiesListRef">
    <Item
      v-for="entity in sort ? filteredList.sort(sort) : filteredList"
      v-show="!checkedOnly || entityIds.includes(getEntityId(entity))"
      :key="getEntityId(entity)"
      :checked="checked"
      :entity="entity"
      :showDelete="showDelete"
      class="entities-list__item"
      @delete="removeEntityHandler($event)">
      <template v-if="$slots['item-append-icon']" #append-icon>
        <slot :entity="entity" name="item-append-icon"></slot>
      </template>
      <template #check>
        <v-checkbox
          :model-value="isCheckedEntity(entity)"
          :readonly="readonly"
          name="entity"
          single
          style="margin: 0 1rem 0 0"
          type="checkbox"
          @update:model-value="
            $event ? addEntityHandler(entity) : removeEntityHandler(entity)
          " />
      </template>
    </Item>
    <div
      v-if="!filteredList.length || (checkedOnly && !(entities || []).length)"
      class="disabled pl-3">
      Список порожній
    </div>
  </div>
</template>

<script>
import Item from './Item.vue'
import { computed } from 'vue'
import { getEntityId } from '@/utils/helpers.js'
import { getDirectory } from '@/directory/index.ts'

export default {
  components: { Item },
  emits: ['delete'],
  props: {
    entities: { type: Array, default: () => [] },
    showDelete: { type: Boolean },
    checked: { type: Boolean },
    checkedOnly: { type: Boolean },
    readonly: { type: Boolean },
    search: { type: String, default: '' },
    addEntityHandler: { type: Function },
    removeEntityHandler: { type: Function },
    usersOnly: { type: Boolean },
    sort: { type: Function },
  },
  setup(props) {
    const filteredList = computed(() => {
      const entityList = getDirectory(
        props.usersOnly ? 'userList' : 'entityList'
      )
      return entityList.filter(e => {
        return e.name.toLowerCase().includes(props.search.toLowerCase().trim())
      })
    })
    const entityIds = computed(() => {
      return props.entities.map(getEntityId)
    })

    const isCheckedEntity = entity => {
      return entityIds.value.includes(getEntityId(entity))
    }

    return {
      entityIds,
      filteredList,
      getEntityId,
      isCheckedEntity,
    }
  },
}
</script>

<style lang="scss">
.entities-list {
  &__item {
    padding: 0.5rem;
    border-bottom: 1px solid #e4e4e4;

    &:hover {
      background: var(--gray-300);
    }
  }
}
</style>
