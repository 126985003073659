<template>
  <div>
    <div class="title">Документи</div>
    <div v-if="contractor.documents && contractor.documents.length">
      <v-btn
        v-for="d in contractor.documents"
        :key="d.id"
        class="link"
        variant="text"
        @click="navigateToItem('single-document', d.id)">
        {{ d.name }}
      </v-btn>
    </div>
    <div v-else class="disabled mb-3 ml-3 mt-1">Відсутні</div>

    <div class="title">Завдання</div>
    <div v-if="contractor.tasks && contractor.tasks.length">
      <v-btn
        v-for="t in contractor.tasks"
        :key="t.id"
        class="link"
        variant="text"
        @click="navigateToItem('single-task', t.id)"
        >{{ t.topic }}
      </v-btn>
    </div>
    <div v-else class="disabled mb-3 ml-3 mt-1">Відсутні</div>
  </div>
</template>

<script>
import { navigateToItem } from '@/utils/helpers.js'

export default {
  inheritAttrs: false,
  props: {
    contractor: { type: Object },
  },
  setup() {
    return { navigateToItem }
  },
}
</script>
