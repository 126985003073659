<template>
  <div>
    <SelectAction
      v-if="currentState === 'selectAction'"
      :photo="dialog.dialogData.user.photo"
      @select="selectActionHandler" />
    <SelectPhoto
      v-if="currentState === 'selectPhoto'"
      :user="dialog.dialogData"
      @select="selectPhotoHandler" />
  </div>
</template>

<script lang="ts">
import SelectAction from './SelectAction.vue'
import SelectPhoto from './SelectPhoto.vue'
import { ref, Ref, PropType } from 'vue'
import { Dialog } from '@/dialog'

export default {
  name: 'UpdateUserPhoto',
  components: {
    SelectPhoto,
    SelectAction,
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  setup(props) {
    const currentState: Ref<'selectAction' | 'selectPhoto'> =
      ref('selectAction')
    if (!props.dialog.dialogData.user.photo) {
      currentState.value = 'selectPhoto'
    }

    const toggleState = (): void => {
      switch (currentState.value) {
        case 'selectAction':
          currentState.value = 'selectPhoto'
          break
        case 'selectPhoto':
          currentState.value = 'selectAction'
          break
      }
    }

    const deletePhoto = (): void => {
      props.dialog.submit(null)
    }

    const selectActionHandler = (
      selectedAction: 'selectPhoto' | 'deletePhoto'
    ) => {
      switch (selectedAction) {
        case 'selectPhoto':
          return toggleState()
        case 'deletePhoto':
          return deletePhoto()
      }
    }

    const selectPhotoHandler = (photo: string | null): void => {
      if (photo) {
        props.dialog.submit(photo)
      } else {
        toggleState()
      }
    }

    return {
      currentState,
      toggleState,
      deletePhoto,
      selectPhotoHandler,
      selectActionHandler,
    }
  },
}
</script>
