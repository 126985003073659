<template>
  <div
    class="d-flex align-center justify-center"
    style="height: calc(100vh - 126px)">
    <h1>InTime</h1>
  </div>
</template>

<script lang="ts">
export default {
  name: 'HomePage',
}
</script>

<style lang="scss" scoped></style>
