<template>
  <b-autocomplete
    :hide-details="hideDetails"
    :inputStyle="{ 'flex-grow': 1, width: '100%' }"
    :items="
      searchItems.filter(
        entity => !entities.map(getEntityId).includes(getEntityId(entity))
      )
    "
    :placeholder="`Починайте вводити ім’я ${!usersOnly ? 'або групу' : ''}`"
    class="block mb-2"
    item-title="name"
    item-value="id"
    style="width: 25rem; max-width: 100%"
    @focus="getSearchItems($event)">
    <template #item="{ item }">
      <Item :entity="item.raw" @click="selectItem(item.raw)" />
    </template>
  </b-autocomplete>
</template>

<script>
import Item from './Item.vue'
import { ref } from 'vue'
import { getEntityId } from '@/utils/helpers.js'
import { getDirectory } from '@/directory/index.ts'

export default {
  components: { Item },
  props: {
    entities: { type: Array },
    addEntityHandler: { type: Function },
    usersOnly: { type: Boolean },
    hideDetails: { type: Boolean },
  },
  setup(props) {
    const searchItems = ref([])

    const getSearchItems = event => {
      searchItems.value = []
      const entityList = getDirectory(
        props.usersOnly ? 'userList' : 'entityList'
      )

      const searchTerm = event && typeof event === 'string' ? event : ''

      if (!searchTerm.trim().length) {
        searchItems.value = [...entityList]
      } else {
        searchItems.value = Array.from(
          entityList.filter(e => {
            return e.name.toLowerCase().includes(searchTerm.toLowerCase())
          })
        )
      }
    }

    const selectItem = entity => {
      if (!props.entities.map(getEntityId).includes(getEntityId(entity))) {
        props.addEntityHandler(entity)
      }
    }

    return {
      getSearchItems,
      searchItems,
      selectItem,
      getEntityId,
    }
  },
}
</script>

<style lang="scss" scoped></style>
