<template>
  <div>
    <div class="title d-flex align-center justify-space-between">
      <span class="title__text">{{ title }}</span>
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  font-weight: 700;
  color: #010e30;
  padding: 15px 0.75rem;
  padding-top: 0;
}
</style>
