<template>
  <div>
    <component
      :is="label ? VCard : div"
      :class="{ 'p-invalid': v$ && v$.$error }"
      variant="outlined">
      <div class="d-flex justify-space-between align-center padding">
        <span v-if="label" class="label">{{ label }}</span>
        <div>
          <span class="text mb-2">Користувач</span>
          <Search
            :addEntityHandler="addEntityHandler"
            :entities="entities"
            :usersOnly="usersOnly" />
        </div>
        <div v-if="!noDialog">
          <span class="text" style="padding-left: 14px"
            >Бажаєте додати одразу декілька користувачів?</span
          >
          <v-btn variant="text" @click="openAddDialog">
            Додати декілька
            <v-icon class="ml-1" color="#0F6AC1" icon="mdi-plus"></v-icon>
          </v-btn>
        </div>
      </div>
      <v-data-table
        :disable-pagination="true"
        :headers="headers"
        :hide-default-footer="true"
        :hide-default-header="!entities.length"
        :items="entities"
        :items-per-page="-1"
        dense>
        <!-- eslint-disable-next-line -->
        <template #item.fullName="{ item }">
          <div class="d-flex align-center gap-3">
            <slot :entity="item" name="item-append-icon"></slot>
            <span>
              {{ item?.entity?.name || getUserName(item) || '---' }}
            </span>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.description="{ item }">
          {{ item?.entity?.description || item?.post || '---' }}
        </template>
        <template #item.actions="{ item }">
          <div
            class="d-flex justify-end"
            style="margin-right: -10px"
            @click="removeEntityHandler(item.entity || item)">
            <v-btn :loading="loading" icon size="small" variant="text">
              <v-icon height="18px" icon="mdi-delete" width="22px" />
            </v-btn>
          </div>
        </template>
        <template #bottom></template>
      </v-data-table>
    </component>
    <ValidationError :v$="v$" />
  </div>
</template>

<script>
import div from '@/components/div.vue'
import { VCard } from 'vuetify/components'
import Search from './Search.vue'
import ValidationError from '@/components/ValidationError.vue'
import { getUserName, reactiveProp } from '@/utils/helpers.js'
import { ref } from 'vue'
import { openDialog } from '@/dialog'
import { fillDirectory } from '@/directory/index.ts'

export default {
  components: { Search, ValidationError },
  props: {
    entities: { type: Array, default: () => [] },
    usersOnly: { type: Boolean },
    submit: { type: [Function, Boolean], default: false },
    addEntityHandler: { type: Function },
    removeEntityHandler: { type: Function },
    loading: { type: Boolean },
    noDialog: { type: Boolean },
    label: { type: String },
    sort: { type: Function },
    v$: { type: Object },
    filters: {
      type: Array,
      default: () => ['user', 'departments'],
    },
  },
  data: () => ({
    headers: [
      {
        title: 'ПІБ',
        align: 'start',
        key: 'fullName',
        sortable: false,
      },
      { title: 'Посада', key: 'description', sortable: false, align: 'start' },
      { title: 'Дії', key: 'actions', sortable: false, align: 'end' },
    ],
  }),
  methods: { getUserName },
  setup(props, { emit }) {
    const showPagination = ref(false)

    const entitiesData = reactiveProp(props, emit, 'entities', [])

    const openAddDialog = () => {
      openDialog({
        name: 'SelectEntities',
        action: 'create',
        dialogData: {
          ...props,
        },
        params: {
          hideActionButtons: true,
        },
      })
    }

    if (props.usersOnly) {
      fillDirectory('userList')
    } else {
      fillDirectory({
        name: 'entityList',
        query: { filter: props.filters },
        force: true,
      })
    }

    return { entitiesData, openAddDialog, VCard, div, showPagination }
  },
}
</script>

<style lang="scss" scoped>
.text {
  color: #656567;
  font-size: 12px;
  display: block;
}

.v-data-table-footer {
  display: none !important;
}
</style>
