<template>
  <div>
    <v-row>
      <v-col class="d-flex justify-center" cols="12" md="12" sm="12">
        <UserPhoto v-model="user.photo" :user="user" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <b-input
          v-model="user.secondName"
          :error-messages="getErrorMessages(v$.secondName)"
          class="w-100"
          hide-details
          label="Прізвище"
          placeholder="Введіть текст"
          @blur="v$.secondName.$touch()"></b-input>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <b-input
          v-model="user.name"
          :error-messages="getErrorMessages(v$.name)"
          class="w-100"
          hide-details
          label="Ім'я"
          placeholder="Введіть текст"
          @blur="v$.name.$touch()"></b-input>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <b-input
          v-model="user.patronymic"
          :error-messages="getErrorMessages(v$.patronymic)"
          class="w-100"
          hide-details
          label="По батькові"
          placeholder="Введіть текст"
          @blur="v$.patronymic.$touch()"></b-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <b-autocomplete
          v-model="user.departmentId"
          :error-messages="getErrorMessages(v$.departmentId)"
          :items="$directory.get('departmentList')"
          class="w-100"
          hide-details
          item-title="name"
          item-value="id"
          label="Департамент"
          placeholder="Оберіть зі списку"
          @blur="v$.departmentId.$touch()"></b-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <b-select
          v-model="user.roleId"
          :error-messages="getErrorMessages(v$.roleId)"
          :items="$directory.get('roles')"
          class="w-100"
          hide-details
          item-title="name"
          item-value="id"
          label="Роль"
          placeholder="Оберіть зі списку"
          @blur="v$.roleId.$touch()"></b-select>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <b-input
          v-model="user.post"
          :error-messages="getErrorMessages(v$.post)"
          class="w-100"
          hide-details
          label="Посада"
          placeholder="Введіть текст"
          @blur="v$.post.$touch()"></b-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <b-input
          v-model="user.inn"
          v-mask:[innMask]
          :error-messages="getErrorMessages(v$.inn)"
          class="w-100"
          hide-details
          label="ІПН"
          placeholder="Введіть номер"
          type="mask"
          @blur="v$.inn.$touch()"></b-input>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <b-select
          v-model="user.genderId"
          :error-messages="getErrorMessages(v$.genderId)"
          :items="$directory.get('genders')"
          class="w-100"
          hide-details
          item-title="name"
          item-value="id"
          label="Стать"
          placeholder="Оберіть зі списку"
          @blur="v$.genderId.$touch()"></b-select>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <BaseDatePicker
          v-model="user.birthDate"
          :error-messages="getErrorMessages(v$.birthDate)"
          class="w-100"
          hide-details
          label="Дата народження"
          @blur="v$.birthDate.$touch()" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <b-input-phone
          v-model="user.phone"
          :error-messages="getErrorMessages(v$.phone)"
          hide-details
          label="Телефон"
          @blur="v$.phone.$touch()"></b-input-phone>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <b-input
          v-model="user.email"
          v-mask:[emailMask]
          :error-messages="getErrorMessages(v$.email)"
          class="w-100"
          hide-details
          label="Пошта"
          placeholder="Введіть текст"
          @blur="v$.email.$touch()"></b-input>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <b-input
          v-model="user.profileDWH"
          :error-messages="getErrorMessages(v$.profileDWH)"
          class="w-100"
          hide-details
          label="profileDWH"
          placeholder="Введіть текст"
          @blur="v$.profileDWH.$touch()"></b-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-checkbox
          v-model="user.isNotificationEmail"
          class="w-100"
          hide-details
          label="Сповіщення на пошту" />
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-checkbox
          v-model="user.isNotificationTelegram"
          class="w-100"
          hide-details
          label="Сповіщення в телеграмі" />
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12" md="4" sm="12">
        <b-input
          v-model="user.password"
          :error-messages="getErrorMessages(v$.password)"
          class="w-100 align-self-start"
          hide-details
          label="Пароль"
          placeholder="********"
          type="password"
          @blur="v$.password.$touch()"></b-input>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { emailMask, innMask } from '@/utils/masks.js'
import { reactive } from 'vue'
import { axios, required, useVuelidate } from '@/plugins'
import { urlUserCreate } from '@/utils/urls.js'
import { getUserRules } from '@/utils/validations.js'
import { getErrorMessages } from '@/utils/helpers'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { BInputPhone } from 'best-modules/components'
import UserPhoto from '@/pages/company-structure/users/single/UserPhoto.vue'
import { fillDirectory } from '@/directory/index.ts'

export default {
  name: 'User',
  components: {
    UserPhoto,
    BInputPhone,
    BaseDatePicker,
  },
  methods: { getErrorMessages },
  async setup() {
    await fillDirectory(['departmentList', 'roles', 'genders'])

    const user = reactive({
      name: null,
      secondName: null,
      patronymic: null,
      departmentId: null,
      roleId: null,
      genderId: null,
      birthDate: null,
      inn: null,
      post: null,
      phone: null,
      email: null,
      profileDWH: null,
      isNotificationTelegram: false,
      isNotificationEmail: false,
      password: null,
      photo: null,
    })

    const v$ = useVuelidate(
      {
        ...getUserRules(),
        departmentId: { required },
        password: { required },
      },
      user
    )

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return axios.post(urlUserCreate(), user).then(res => {
          return res.data
        })
      }
    }

    return { user, v$, submit, innMask, emailMask }
  },
}
</script>
