<template>
  <div>
    <div>{{ signature.signingDate }}</div>
    <div class="mb-1">
      {{
        signature.certificate.extKeyUsages
          ? 'Електронна печатка'
          : 'Електронний підпис'
      }}
    </div>
    <signature-info-value
      title="Власник ключа"
      :value="signature.certificate.subjFullName"></signature-info-value>
    <span v-if="!collapsed" class="link" @click="toggle">Деталі</span>
    <v-scroll-x-transition hide-on-leave>
      <div v-show="collapsed">
        <signature-info-value
          title="АЦСК"
          :value="signature.certificate.issuerCN"></signature-info-value>
        <signature-info-value
          title="Організація (установа)"
          :value="
            signature.certificate.issuerCN?.includes('DIIA')
              ? null
              : signature.certificate.subjOrg || 'ФІЗИЧНА ОСОБА ПІДПРИЄМЕЦЬ'
          "></signature-info-value>
        <signature-info-value
          title="Посада"
          :value="signature.certificate.subjTitle"></signature-info-value>
        <signature-info-value
          title="Серійний номер"
          :value="signature.certificate.serial"></signature-info-value>
        <span class="link" @click="toggle">Сховати</span>
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script lang="ts">
import { PropType, ref } from 'vue'
import SignatureInfoValue from './SignatureInfoValue.vue'
import { Signature } from './types'
export default {
  name: 'SignatureInfo',
  components: { SignatureInfoValue },
  props: {
    signature: {
      type: Object as PropType<Signature>,
      required: true,
    },
  },
  setup() {
    const collapsed = ref<boolean>(false)

    const toggle = () => {
      collapsed.value = !collapsed.value
    }

    return { collapsed, toggle }
  },
}
</script>

<style scoped lang="scss"></style>
