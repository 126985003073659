const isDateWithTime = dateString => {
  return dateString.split(' ').length === 2
}

function formatDate(dateString, type) {
  if (type === 'response') {
    // input 'yyyy-mm-dd hh:mm:ss'
    // output 'dd.mm.yyyy, hh:mm'
    const format = isDateWithTime(dateString) ? '$3.$2.$1, $4:$5' : '$3.$2.$1'
    const regex = isDateWithTime(dateString)
      ? /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
      : /(\d{4})-(\d{2})-(\d{2})/
    const formattedDate = dateString.replace(regex, format)

    return formattedDate
  }
  if (type === 'request') {
    // input 'dd.mm.yyyy, hh:mm'
    // output 'yyyy-mm-dd hh:mm:ss'
    const format = isDateWithTime(dateString) ? '$3-$2-$1 $4:00' : '$3-$2-$1'
    const regex = isDateWithTime(dateString)
      ? /(\d{2}).(\d{2}).(\d{4}), (\d{2}:\d{2})/
      : /(\d{2}).(\d{2}).(\d{4})/
    return dateString.replace(regex, format)
  }
}

function isDate(value, type) {
  const formats = {
    response: /^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2})?$/, // 'yyyy-mm-dd hh:mm:ss' | yyyy-mm-dd
    request: /^\d{2}\.\d{2}\.\d{4}(, \d{2}:\d{2})?$/, // 'dd.mm.yyyy, hh:mm' | dd.mm.yyyy
  }

  return formats[type].test(value)
}

function setData(httpObj, type) {
  if (!httpObj.data) return
  try {
    httpObj.data = JSON.parse(
      JSON.stringify(
        typeof httpObj.data === 'string'
          ? JSON.parse(httpObj.data)
          : httpObj.data,
        (key, value) => {
          if (key === '') return value
          return isDate(value, type) ? formatDate(value, type) : value
        }
      )
    )
  } catch (e) {
    return void [0]
  }
}

function setCSRF(req) {
  let origin

  try {
    origin = new URL(req.url).origin
  } catch (_) {
    return false
  }

  if (!origin) return false

  req.headers.Authorization = getCSRF()
}

function getCSRF() {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')
}

export { setData, setCSRF, getCSRF, formatDate, isDate }
