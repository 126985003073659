import { cachedObject } from 'best-modules/utils'
import { required, requiredIf } from '@vuelidate/validators'
import { computed, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios/index.js'
import { urlContractorSingle } from '@/utils/urls.js'

const correspondence = cachedObject({
  id: null,
  related_correspondences: [],
  subject: null,
  description: null,
  isOrigin: false,
  typeId: null,
  documentTypeId: null,
  documentPriorityTypeId: null,
  documentDeliveryTypeId: null,
  deliveryCost: null,
  ttn: null,
  registrationNumber: null,
  registrationDate: null,
  departmentId: null,
  contractorId: null,
  addressId: null,
  documents: null,
})
correspondence.$setIgnore('statusId', 'status', 'related_correspondences')

const rules = {
  subject: { required },
  description: {},
  isOrigin: { required },
  typeId: { required },
  documentTypeId: { required },
  documentPriorityTypeId: {
    requiredIf: requiredIf(
      computed(() => {
        return (
          correspondence.documentTypeId === 1 && correspondence.typeId === 2
        )
      })
    ),
  },
  documentDeliveryTypeId: {
    requiredIf: requiredIf(
      computed(() => [1, 4].includes(correspondence.documentTypeId))
    ),
  },
  registrationNumber: { required },
  registrationDate: { required },
  departmentId: { required },
  documents: {},
}

const addressList = ref([])
const getAddressList = () => {
  if (correspondence.contractorId) {
    return handleAsync('getContractorSingle', () => {
      return axios
        .get(urlContractorSingle(correspondence.contractorId))
        .then(res => {
          addressList.value = res.data.addresses || []
        })
    })
  } else {
    addressList.value = []
    correspondence.addressId = null
  }
}

const v$ = useVuelidate(rules, correspondence)

export { correspondence, v$, addressList, getAddressList }
