<template>
  <div v-if="localComments.find(c => c.user.id === user?.id)">
    <CommentItem
      :comment="
        Array.from(localComments)
          .reverse()
          .find(c => c.user.id === user.id)
      "
      hideDelete
      hidePhoto>
      <template #showAllComments>
        <v-btn
          size="small"
          style="padding: 0 !important"
          variant="text"
          @click="openCommentsDialog"
          >Показати всі...
        </v-btn>
      </template>
    </CommentItem>
  </div>
  <div v-else>
    <div v-if="!disabled" class="wrapper" @click="openCommentsDialog">
      <v-icon color="#49A2F4" icon="mdi-plus" />
      <span class="label d-block">Додати</span>
    </div>
    <span v-if="disabled" class="label">Дія неможлива</span>
  </div>
</template>

<script>
import CommentItem from '@/dialog/components/comments/CommentItem.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { openDialog } from '@/dialog/index.ts'

export default {
  components: { CommentItem },
  props: {
    comments: { type: [Array, Object], default: () => [] },
    entityId: { type: Number, required: true },
    entityTypeId: {
      type: Number,
      required: true,
      validator: val => [1, 2, 3, 4].includes(val),
    },
    disabled: { type: Boolean },
  },
  setup(props) {
    const localComments = ref(props.comments || [])

    const store = useStore()
    const user = computed(() => store.state.user.userData)

    const openCommentsDialog = () => {
      openDialog({
        name: 'Comments',
        dialogData: {
          comments: Array.from(localComments.value).reverse(),
          entityId: props.entityId,
          entityTypeId: props.entityTypeId,
          updateComments: comments => (localComments.value = comments),
        },
        params: {
          hideActionButtons: true,
        },
      })
    }
    return { openCommentsDialog, localComments, user }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 4px;
  border: 1px dashed #e0e4e7;
  padding: 16px 8px;
  text-align: center;
  transition: 0.3s;

  &:hover {
    background: #eefafc;
  }

  .label {
    color: #49a2f4;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
