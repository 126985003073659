<template>
  <div>
    <base-date-picker
      v-model="deliveryData.deliveryDate"
      label="Дата доставки"
      :error-messages="getErrorMessages(v$.deliveryDate)"
      @blur="v$.deliveryDate.$touch()"></base-date-picker>
  </div>
</template>

<script>
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { urlCorrespondenceDelivered } from '@/utils/urls.js'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { axios } from '@/plugins'
import { getErrorMessages } from 'best-modules/utils'

export default {
  name: 'CorrespondenceDeliver',
  components: { BaseDatePicker },
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const deliveryData = reactive({
      deliveryDate: null,
    })
    const rules = {
      deliveryDate: { required },
    }
    const v$ = useVuelidate(rules, deliveryData)

    const submit = () => {
      return axios.post(
        urlCorrespondenceDelivered(props.dialog.dialogData.correspondenceId),
        deliveryData
      )
    }

    return { deliveryData, v$, submit }
  },
}
</script>
