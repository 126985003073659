<template>
  <div>
    <Filter
      v-model="filters"
      v-model:loading="loading"
      section="task"
      @filter="() => pgTrigger++">
      <template #append-search>
        <div class="d-flex justify-end mb-3 gap-3">
          <printed-form></printed-form>
          <v-btn @click="createTask">
            <v-icon>mdi-plus</v-icon>
            Створити завдання
          </v-btn>
        </div>
      </template>
    </Filter>

    <v-data-table
      :disable-pagination="true"
      :headers="headers"
      :hide-default-footer="true"
      :hide-default-header="!taskList.length"
      :items="taskList"
      :items-per-page="-1"
      :loading="loading"
      dense
      @dblclick:row="openRow">
      <template #item.executor="{ item }">
        <span>{{
          getUserName(item.executor, { initials: true }) || '---'
        }}</span>
      </template>
      <template #item.author="{ item }">
        <span>{{ getUserName(item.author, { initials: true }) || '---' }}</span>
      </template>
      <template #item.completedDate="{ item }">
        <div class="d-flex align-center gap-3">
          <span>{{ item.completedDate || '---' }}</span>
          <v-menu v-if="item.completedDate" open-on-hover>
            <template #activator="{ props }">
              <v-btn
                variant="plain"
                size="small"
                density="compact"
                icon="mdi-information-variant"
                v-bind="props"></v-btn>
            </template>

            <v-card>
              <v-card-text>
                {{ item.completionReport || item.rejectComment }}
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template #item.connect="{ item }">
        <span
          v-if="item.entityTypeId && item.entityId"
          class="link"
          @click.stop="
            $router.push({
              name:
                item.entityTypeId === 3
                  ? 'single-order'
                  : 'correspondence-single',
              params: { id: item.entityId },
            })
          "
          >{{ item.entityTypeId === 3 ? 'Наказ' : 'Кореспонденція' }}</span
        >
        <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
      </template>
      <template #[`item.contractor`]="{ item }">
        <v-tooltip v-if="item.contractor" :text="item.contractor?.name" bottom>
          <template #activator="{ props }">
            <span
              class="link"
              style="
                display: block;
                width: 230px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              v-bind="props"
              @click.stop="
                $router.push({
                  name: 'single-contractor',
                  params: { id: item?.contractor?.id },
                })
              "
              >{{ item.contractor?.name }}</span
            >
          </template>
        </v-tooltip>
        <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
      </template>
      <template #item.actions="{ item }">
        <TableActions
          :actions="[
            {
              label: 'Взяти в роботу',
              action: () => {
                actions.taskTakeToWork(item, item.id).then(setTask)
              },
              icon: 'mdi-play',
              disabled: actions.disabled(item).takeToWork.value,
            },
            {
              label: 'Завершити задачу',
              action: () => actions.taskComplete(item, item.id, setTask),
              icon: 'mdi-bell-check-outline',
              disabled: actions.disabled(item).complete.value,
            },
            {
              label: 'Копіювати задачу',
              action: () => actions.copyTask(item),
              icon: 'mdi-content-copy',
              disabled: actions.disabled(item).copy.value,
            },
            {
              label: 'Делегувати',
              action: () => actions.taskDelegate(item, item.id, setTask),
              icon: 'mdi-link',
              disabled: actions.disabled(item).delegate.value,
            },
            {
              label: 'Відхилити задачу',
              action: () => actions.taskReject(item, item.id, setTask),
              icon: 'mdi-clock-out',
              disabled: actions.disabled(item).reject.value,
            },
            {
              label: 'Видалити задачу',
              action: () => actions.taskDelete(item, item.id, deleteTask),
              icon: 'mdi-delete',
              disabled: actions.disabled(item).del.value,
            },
          ]" />
      </template>
      <template #bottom></template>
    </v-data-table>
    <Pagination :getItems="getTaskList" :trigger="pgTrigger" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import { axios, useRoute } from '@/plugins'
import { computed, reactive, ref } from 'vue'
import { urlIncomingTasks, urlOutgoingTasks } from '@/utils/urls.js'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { useTaskActions } from '@/pages/tasks/taskActions.js'
import TableActions from '@/components/TableActions.vue'
import Filter from '@/components/Filter.vue'
import { headers } from './headers.ts'
import PrintedForm from './PrintedForm.vue'

export default {
  components: {
    Filter,
    TableActions,
    Pagination,
    PrintedForm,
  },
  methods: { getUserName },
  setup() {
    const route = useRoute()
    const loading = ref(true)
    const taskList = ref([])

    const url = computed(() => {
      switch (route.name) {
        case 'incoming-tasks':
          return urlIncomingTasks
        case 'outgoing-tasks':
          return urlOutgoingTasks
        default:
          return urlIncomingTasks
      }
    })

    const filters = reactive({
      search: null,
      statusId: null,
      startDate: null,
      endDate: null,
      typeId: null,
      'executors[]': null,
    })

    const openRow = (e, row) => {
      navigateToItem('single-task', row.item.id)
    }

    const getTaskList = () => {
      loading.value = true
      return axios.get(url.value({ filters })).then(res => {
        taskList.value = res.data.data
        loading.value = false

        return res.data
      })
    }
    const pgTrigger = ref(1)

    const actions = useTaskActions()
    const setTask = task => {
      taskList.value = taskList.value.map(t => (t.id === task.id ? task : t))
    }
    const pushTask = task => taskList.value.push(task)
    const deleteTask = taskId => {
      taskList.value = taskList.value.filter(t => t.id !== taskId)
    }

    const createTask = () => {
      navigateToItem('create-task', { typeId: 1 })
    }

    return {
      loading,
      taskList,
      headers,
      navigateToItem,
      getTaskList,
      pgTrigger,
      openRow,
      actions,
      setTask,
      pushTask,
      deleteTask,
      createTask,
      filters,
    }
  },
}
</script>

<style lang="scss"></style>
