<template>
  <TreeTable :item-value="itemValue" :items="items">
    <template
      v-for="slot in Object.keys($slots).filter(s => s.startsWith('item.'))"
      #[slot]="{ item, props }">
      <slot :name="slot" :item="item" :props="props"></slot>
    </template>
  </TreeTable>
</template>

<script>
import TreeTable from './TreeTable.vue'
import { ref, watch, provide } from 'vue'

export default {
  name: 'Index',
  components: {
    TreeTable,
  },
  props: {
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    itemValue: {
      type: String,
      required: true,
    },
    expanded: {
      type: Array,
    },
  },
  emits: ['update:expanded'],
  setup(props, { emit }) {
    const expandedSet = ref(new Set(props.expanded || []))
    const expand = item => {
      expandedSet.value.add(item)
      emit('update:expanded', Array.from(expandedSet.value))
    }

    const collapse = item => {
      expandedSet.value.delete(item)
      emit('update:expanded', Array.from(expandedSet.value))
    }
    const firstHeaderKey = props.headers[0].key

    provide('firstHeaderKey', firstHeaderKey)
    provide('expandedSet', expandedSet)
    provide('expand', expand)
    provide('collapse', collapse)
    provide('headers', props.headers)
    provide('itemValue', props.itemValue)

    watch(
      () => props.expanded,
      () => {
        expandedSet.value.clear()
        for (const item of props.expanded || []) {
          expandedSet.value.add(item)
        }
      }
    )

    return { expandedSet, expand, collapse, firstHeaderKey }
  },
}
</script>
