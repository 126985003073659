function readFile(file: File): Promise<{file: File, data: Uint8Array}> {
	return new Promise(function(resolve, reject) {
		var reader = new FileReader();
		reader.onloadend  = function(evt) {
			if (evt.target.readyState != FileReader.DONE)
				return;

			resolve({
				"file": file,
				"data": new Uint8Array(evt.target.result as ArrayBuffer)
			});
		};
		reader.readAsArrayBuffer(file);
	});
}

export { readFile }