import { Collection  } from 'best-modules/utils'

const caList = new Collection('address', [
	{
		"issuerCNs": 				["КНЕДП ДПС",
									"КНЕДП ІДД ДПС",
									"КНЕДП - ІДД ДПС",
									"Акредитований центр сертифікації ключів ІДД ДФС",
									"Акредитований центр сертифікації ключів ІДД Міндоходів",
									"Акредитований центр сертифікації ключів ІДД ДПС"],
		"address": 					"acskidd.gov.ua",
		"ocspAccessPointAddress":	"acskidd.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"acskidd.gov.ua",
		"tspAddress":				"acskidd.gov.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"codeEDRPOU":				"43174711"
	},
	{
		"issuerCNs": 				["\"Дія\". Кваліфікований надавач електронних довірчих послуг",
									"АЦСК органів юстиції України",
									"\"DIIA\". Qualified Trust Services Provider",
									"CA of the Justice of Ukraine",
									"АЦСК Держінформ'юсту"],
		"address": 					"ca.informjust.ua",
		"ocspAccessPointAddress":	"ca.informjust.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.informjust.ua",
		"tspAddress":				"ca.informjust.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"qscdSNInCert":				true,
		"codeEDRPOU":				"43395033"
	},
	{
		"issuerCNs": 				["КНЕДП ЦСК Укрзалізниці",
									"QTSP of the Ukrzaliznytsia CA",
									"Акредитований центр сертифікації ключів Укрзалізниці",
									"ЦСК Укрзалізниці"],
		"address": 					"csk.uz.gov.ua",
		"ocspAccessPointAddress":	"csk.uz.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"csk.uz.gov.ua",
		"tspAddress":				"csk.uz.gov.ua",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"40081258"
	},
	{
		"issuerCNs": 				["КНЕДП \"MASTERKEY\" ТОВ \"АРТ-МАСТЕР\"",
									"АЦСК \"MASTERKEY\" ТОВ \"АРТ-МАСТЕР\"",
									"ЦСК \"MASTERKEY\" ТОВ \"АРТ-МАСТЕР\"",
									"ТОВ \"Арт-мастер\""],
		"address": 					"masterkey.ua",
		"ocspAccessPointAddress":	"masterkey.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"masterkey.ua",
		"tspAddress":				"masterkey.ua/services/tsp/",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"30404750"
	},
	{
		"issuerCNs": 				["КНЕДП ДП \"УСС\"",
									"АЦСК ДП «УСС»",
									"АЦСК ДП \"УСС\""],
		"address": 					"csk.uss.gov.ua",
		"ocspAccessPointAddress":	"csk.uss.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"csk.uss.gov.ua",
		"tspAddress":				"csk.uss.gov.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"codeEDRPOU":				"32348248"
	},
	{
		"issuerCNs": 				["КНЕДП АТ \"УКРСИББАНК\"",
									"АЦСК Публічного акціонерного товариства \"УкрСиббанк\""],
		"address": 					"csk.ukrsibbank.com",
		"ocspAccessPointAddress":	"csk.ukrsibbank.com/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"csk.ukrsibbank.com",
		"tspAddress":				"csk.ukrsibbank.com",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"09807750"
	},
	{
		"issuerCNs": 				["КНЕДП АЦСК АТ КБ \"ПРИВАТБАНК\"",
									"АЦСК АТ КБ «ПРИВАТБАНК»",
									"АЦСК ПАТ КБ «ПРИВАТБАНК»",
									"АЦСК «ПРИВАТБАНК»"],
		"address": 					"acsk.privatbank.ua",
		"ocspAccessPointAddress":	"acsk.privatbank.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"acsk.privatbank.ua",
		"tspAddress":				"acsk.privatbank.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"qscdSNInCert":				true,
		"codeEDRPOU":				"14360570"
	},
	{
		"issuerCNs": 				["КНЕДП АЦСК АТ \"Альфа-Банк\"",
									"Акредитований центр сертифікації ключів АТ \"Альфа-Банк\"",
									"КНЕДП АТ \"СЕНС БАНК\""],
		"address": 					"ca.alfabank.kiev.ua",
		"ocspAccessPointAddress":	"ca.alfabank.kiev.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.alfabank.kiev.ua",
		"tspAddress":				"ca.alfabank.kiev.ua",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"23494714"
	},
	{
		"issuerCNs": 				["КНЕДП АТ \"СЕНС БАНК\"",
									"КНЕДП АЦСК АТ \"Альфа-Банк\"",
									"Акредитований центр сертифікації ключів АТ \"Альфа-Банк\""],
		"address": 					"ca.sensebank.com.ua",
		"ocspAccessPointAddress":	"ca.sensebank.com.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.sensebank.com.ua",
		"tspAddress":				"ca.sensebank.com.ua",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"23494714",
		"cmpCompatibility":			1
	},
	{
		"issuerCNs": 				["КНЕДП Акціонерного товариства “КРЕДІ АГРІКОЛЬ БАНК”",
									"QTSP of Joint-stock company \"CREDIT-AGRICOLE BANK\""],
		"address": 					"ca.credit-agricole.ua",
		"ocspAccessPointAddress":	"ca.credit-agricole.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.credit-agricole.ua",
		"tspAddress":				"ca.credit-agricole.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"codeEDRPOU":				"14361575"
	},
	{
		"issuerCNs": 				["КНЕДП АТ \"ПУМБ\""],
		"address": 					"pki.pumb.ua",
		"ocspAccessPointAddress":	"pki.pumb.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"pki.pumb.ua",
		"tspAddress":				"pki.pumb.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"codeEDRPOU":				"14282829"
	},
	{
		"issuerCNs": 				["КНЕДП ТОВ \"ДЕПОЗИТ САЙН\"",
									"QTSP of the DEPOSIT SIGN LLC"],
		"address": 					"ca.depositsign.com",
		"ocspAccessPointAddress":	"ca.depositsign.com/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.depositsign.com",
		"tspAddress":				"ca.depositsign.com/services/tsp/dstu/",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"codeEDRPOU":				"43005049"
	},
	{
		"issuerCNs": 				["КНЕДП ТОВ \"Вчасно Сервіс\"",
									"QTSP \"VCHASNO SERVICE\" LLC"],
		"address": 					"ca.vchasno.ua",
		"ocspAccessPointAddress":	"ca.vchasno.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.vchasno.ua",
		"tspAddress":				"ca.vchasno.ua/services/tsp/dstu/",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"qscdSNInCert":				true,
		"codeEDRPOU":				"41231992"
	},
	{ 
		"issuerCNs": 				["КНЕДП “ЦСК Збройних Сил України”",
									"QTSP “CSK of Armed Forces of Ukraine”",
									"Акредитований Центр сертифікації ключів Збройних Сил",
									"Акредитований центр сертифікації ключів Збройних Сил",
									"Armed Forces of Ukraine Certificate authority"],
		"address": 					"ca.mil.gov.ua",
		"ocspAccessPointAddress":	"ca.mil.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.mil.gov.ua",
		"tspAddress":				"ca.mil.gov.ua",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"22991050"
	},
	{ 
		"issuerCNs": 				["КНЕДП \"Військова частина 2428\" ДПСУ",
									"Кваліфікований надавач ЕДП \"Військова частина 2428\"", 
									"АЦСК Державної прикордонної служби України"],
		"address": 					"acsk.dpsu.gov.ua",
		"ocspAccessPointAddress":	"acsk.dpsu.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"acsk.dpsu.gov.ua",
		"tspAddress":				"acsk.dpsu.gov.ua",
		"tspAddressPort":			"80",
		"qscdSNInCert":				true,
		"codeEDRPOU":				"14321469"
	},
	{
		"issuerCNs": 				["КНЕДП \"АЦСК ринку електричної енергії\"",
									"АЦСК ринку електричної енергії"],
		"address": 					"acsk.oree.com.ua",
		"ocspAccessPointAddress":	"acsk.oree.com.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"acsk.oree.com.ua",
		"tspAddress":				"acsk.oree.com.ua",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"43064445"
	},
	{
		"issuerCNs": 				["КНЕДП - АЦСК МВС України",
									"АЦСК МВС України"],
		"address": 					"ca.mvs.gov.ua",
		"ocspAccessPointAddress":	"ca.mvs.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.mvs.gov.ua",
		"tspAddress":				"ca.mvs.gov.ua",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"00032684"
	},
	{
		"issuerCNs": 				["КНЕДП Служби зовнішньої розвідки України",
									"QTSP of Foreign Intelligence Service of Ukraine"],
		"address": 					"ca.szru.gov.ua",
		"ocspAccessPointAddress":	"ca.szru.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.szru.gov.ua",
		"tspAddress":				"ca.szru.gov.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"codeEDRPOU":				"33240845"
	},
	{
		"issuerCNs": 				["АЦСК Національного банку України",
									"National Bank of Ukraine Certificate authority ECDSA"],
		"address": 					"canbu.bank.gov.ua",
		"ocspAccessPointAddress":	"canbu.bank.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"canbu.bank.gov.ua",
		"tspAddress":				"canbu.bank.gov.ua",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"00032106"
	},
	{
		"issuerCNs": 				["КНЕДП АТ \"ПРАВЕКС БАНК\"",
									"QTSP-RSA of \"PRAVEX BANK\" JSC",
									"QTSP-ECDSA of \"PRAVEX BANK\" JSC"],
		"address": 					"ca.pravex.com.ua",
		"ocspAccessPointAddress":	"ca.pravex.com.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.pravex.com.ua",
		"tspAddress":				"ca.pravex.com.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"codeEDRPOU":				"14360920"
	},
	{
		"issuerCNs": 				["КНЕДП АТ \"ТАСКОМБАНК\""],
		"address": 					"ca.tascombank.ua",
		"ocspAccessPointAddress":	"ca.tascombank.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.tascombank.ua",
		"tspAddress":				"ca.tascombank.ua",
		"tspAddressPort":			"80",
		"codeEDRPOU":				"09806443"
	},
	{ 
		"issuerCNs": 				["КНЕДП ТОВ \"Центр сертифікації ключів \"Україна\"",
									"АЦСК ТОВ \"Центр сертифікації ключів \"Україна\"",
									"ТОВ \"Центр сертифікації ключів \"Україна\""],
		"address": 					"uakey.com.ua",
		"ocspAccessPointAddress":	"uakey.com.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"uakey.com.ua",
		"tspAddress":				"uakey.com.ua",
		"tspAddressPort":			"80",
		"directAccess":				true,
		"cmpCompatibility":			3,
		"codeEDRPOU":				"36865753"
	},
	{
		"issuerCNs":				["КНЕДП Казначейства",
									"Кваліфікований надавач ЕДП \"Казначейство\"",
									"АЦСК Державної казначейської служби України",
									"ЦСК Державної казначейської служби України"],
		"address": 					"ca.treasury.gov.ua",
		"ocspAccessPointAddress":	"ca.treasury.gov.ua",
		"ocspAccessPointPort":		"43222",
		"cmpAddress":				"",
		"tspAddress":				"ca.treasury.gov.ua",
		"tspAddressPort":			"43221",
		"certsInKey":				true,
		"cmpCompatibility":			0,
		"codeEDRPOU":				"37567646"
	},
	{
		"issuerCNs":				["КНЕДП АТ \"Ощадбанк\"",
									"КНЕДП ЦСК АТ \"Ощадбанк\"",
									"КНЕДП ЦСК АТ «Ощадбанк»",
									"Акредитований центр сертифікації ключів АТ \"Ощадбанк\""],
		"address": 					"ca.oschadbank.ua",
		"ocspAccessPointAddress":	"ca.oschadbank.ua/public/ocsp",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"",
		"tspAddress":				"ca.oschadbank.ua/public/tsp",
		"tspAddressPort":			"80",
		"certsInKey":				true,
		"cmpCompatibility":			0,
		"codeEDRPOU":				"00032129"
	},
	{
		"issuerCNs":				["КНЕДП органів прокуратури України",
									"QTSP of the Prosecution Bodies of Ukraine",
									"АЦСК Генеральної прокуратури України"],
		"address": 					"ca.gp.gov.ua",
		"ocspAccessPointAddress":	"ca.gp.gov.ua/ocsp",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.gp.gov.ua/cmp",
		"tspAddress":				"ca.gp.gov.ua/tsp",
		"tspAddressPort":			"80",
		"certsInKey":				true,
		"cmpCompatibility":			1,
		"codeEDRPOU":				"00034051"
	},
	{
		"issuerCNs":				["КНЕДП СБ України"],
		"address": 					"knedp.ssu.gov.ua",
		"ocspAccessPointAddress":	"va1-knedp.ssu.gov.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"va1-knedp.ssu.gov.ua/services/cmp/",
		"tspAddress":				"va1-knedp.ssu.gov.ua/services/tsp/",
		"tspAddressPort":			"80",
		"cmpCompatibility":			3,
		"codeEDRPOU":				"00034074"
	},
	{
		"issuerCNs":				["КНЕДП ЦЕЗАРІС TOB \"Інтер-Метл\"",
									"\"АЦСК TOB 'Інтер-Метл'\""],
		"address": 					"cesaris.itsway.kiev.ua",
		"ocspAccessPointAddress":	"cesaris.itsway.kiev.ua/services/ocsp/",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"",
		"tspAddress":				"cesaris.itsway.kiev.ua/services/tsp/",
		"tspAddressPort":			"80",
		"certsInKey":				true,
		"directAccess":				true,
		"cmpCompatibility":			0,
		"codeEDRPOU":				"25279440"
	},
	{
		"issuerCNs":				["КНЕДП \"eSign\" ТОВ \"Ілайф\""],
		"address": 					"ca.e-life.com.ua",
		"ocspAccessPointAddress":	"ocsp.e-life.com.ua",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"cmp.e-life.com.ua/api/PKI/CMP",
		"tspAddress":				"tsp.e-life.com.ua",
		"tspAddressPort":			"80",
		"certsInKey":				false,
		"directAccess":				true,
		"cmpCompatibility":			3,
		"codeEDRPOU":				"36049014"
	},
	{
		"issuerCNs":				["КНЕДП АТ \"БАНК АЛЬЯНС\"",
									"QTSP-RSA of JSC \"Bank Alliance\"",
									"QTSP-ECDSA of JSC \"Bank Alliance\""],
		"address": 					"ca.bankalliance.ua",
		"ocspAccessPointAddress":	"ca.bankalliance.ua/ocsp",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"ca.bankalliance.ua/cmp",
		"tspAddress":				"ca.bankalliance.ua/tsp",
		"tspAddressPort":			"80",
		"certsInKey":				true,
		"cmpCompatibility":			3,
		"codeEDRPOU":				"14360506"
	},
	{
		"issuerCNs":				["КНЕДП АБ \"УКРГАЗБАНК\"",
									"QTSP-RSA of JSB \"UKRGASBANK\"",
									"QTSP-ECDSA of JSB \"UKRGASBANK\""],
		"address": 					"qca.ukrgasbank.com",
		"ocspAccessPointAddress":	"qca.ukrgasbank.com/ocsp",
		"ocspAccessPointPort":		"80",
		"cmpAddress":				"qca.ukrgasbank.com/cmp",
		"tspAddress":				"qca.ukrgasbank.com/tsp",
		"tspAddressPort":			"80",
		"certsInKey":				true,
		"cmpCompatibility":			1,
		"codeEDRPOU":				"23697280"
	}
])

export { caList }
