<template>
  <b-section-loader :loading="$loading.isLoading('initialize')">
    <div>
      <div>
        <div class="mb-3 d-flex align-center justify-space-between">
          <h3>Оберіть ключ</h3>
          <v-btn
            v-show="showKey"
            size="small"
            variant="outlined"
            color="error"
            @click="
              () => {
                showKey = false
              }
            ">
            Видалити ключ
          </v-btn>
        </div>
        <v-btn
          v-if="!showKey"
          variant="outlined"
          color="primary"
          @click="showKey = true">
          Додати ключ <v-icon class="ml-2">mdi-plus</v-icon>
        </v-btn>
        <key-reader v-if="showKey" :keyType="keyType"></key-reader>
      </div>

      <v-divider class="mt-3 mb-3"></v-divider>

      <div>
        <div class="mb-3 d-flex align-center justify-space-between">
          <h3>Додайте за потреби печатку</h3>
          <v-btn
            v-show="showStamp"
            size="small"
            variant="outlined"
            color="error"
            @click="
              () => {
                showStamp = false
              }
            ">
            Видалити печатку
          </v-btn>
        </div>
        <v-btn
          v-if="!showStamp"
          variant="outlined"
          color="primary"
          @click="showStamp = true">
          Додати печатку <v-icon class="ml-2">mdi-plus</v-icon>
        </v-btn>
        <key-reader v-if="showStamp" :keyType="keyType" stamp></key-reader>
      </div>
      <div class="d-flex align-center gap-4 mt-4">
        <v-btn variant="outlined" color="black" @click="$emit('cancel')">
          Назад
        </v-btn>
        <v-btn
          color="secondary"
          class="text-white"
          :loading="$loading.isLoading('sendSign')"
          @click="sendSign">
          Підписати і надіслати документи
        </v-btn>
      </div>
    </div>
  </b-section-loader>
</template>

<script lang="ts">
import { inject, ref, PropType } from 'vue'
import KeyReader from './KeyReader.vue'
import { initialize, keys, signFile } from '@/utils/signature'
import { KeyType } from '../types'
import { BSectionLoader } from 'best-modules/components'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios/index.js'
import {
  dialogKey,
  AuthorizationKey,
  isClientKey,
  fileToSignKey,
} from '../injectionKeys'
import { urlClientAccessCreateSign, urlSignCreate } from '@/utils/urls'
import { uploadFiles } from '@/components/filesUploader/uploadFiles'

export default {
  name: 'SignLocal',
  emits: ['cancel'],
  components: { KeyReader, BSectionLoader },
  props: {
    keyType: {
      type: String as PropType<KeyType>,
    },
  },
  setup(props) {
    const dialog = inject(dialogKey)
    const Authorization = inject(AuthorizationKey)
    const isClient = inject(isClientKey)
    const fileToSign = inject(fileToSignKey)

    handleAsync('initialize', () => {
      return initialize(props.keyType)
    })

    const showStamp = ref<boolean>(false)
    const showKey = ref<boolean>(true)

    const sendSign = async () => {
      if (!keys.length) {
        return
      }

      const signatures = await handleAsync('sendSign', () => {
        const url = isClient.value ? urlClientAccessCreateSign : urlSignCreate
        return Promise.all(
          keys.filter(Boolean).map(async k => {
            const { signBinary, signTimeStamp } = await signFile(
              k.privateKeyCtx,
              fileToSign.value
            )
            const sign = new File(
              [signBinary],
              `${dialog.dialogData.document.document[0].name}.p7s`,
              { type: 'application/pkcs7-signature' }
            )
            const { paths } = await uploadFiles({
              files: sign,
              nameFolder: dialog.dialogData.document.number,
              mainFolder: 'Документы',
              prefixFolder: 'Подписанные',
              Authorization: Authorization.value,
              isClient: isClient.value,
            })
            return axios
              .post(
                url(),
                {
                  documentId: dialog.dialogData.document.id,
                  documentSigned: paths[0],
                  signingDate: signTimeStamp,
                  fullCertificate: k.ownerInfo,
                },
                {
                  headers: isClient.value
                    ? {
                      Authorization: Authorization.value,
                    }
                    : {},
                }
              )
              .then(res => res.data)
          })
        )
      })

      return dialog.submit(signatures)
    }

    return { showKey, showStamp, sendSign }
  },
}
</script>

<style scoped lang="scss"></style>
