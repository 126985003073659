<template>
  <div>
    <b-input v-model="searchStr" label="Пошук..."></b-input>
    <v-tabs
      v-model="activeTab"
      hide-slider
      style="border-bottom: 1px solid #e0e4e7">
      <v-tab v-for="(tab, index) in tabs" :key="index" :value="tab.value">
        {{ tab.text }}
      </v-tab>
    </v-tabs>

    <div>
      <div v-if="entityLoading" class="position-relative">
        <span
          class="position-absolute"
          style="
            z-index: 1000;
            left: 50%;
            transform: translate(-70px, -40px);
            top: 50%;
          ">
          Зачекайте будь ласка
        </span>
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </div>
      <div v-else>
        <EntitiesList
          v-if="activeTab === 0"
          v-model:entities="localEntities"
          :addEntityHandler="addEntity"
          :removeEntityHandler="removeEntity"
          :search="searchStr"
          :usersOnly="dialog.dialogData.usersOnly"
          checked
          class="list" />

        <EntitiesList
          v-if="activeTab === 1"
          :addEntityHandler="addEntity"
          :entities="localEntities"
          :removeEntityHandler="removeEntity"
          :usersOnly="dialog.dialogData.usersOnly"
          checked
          checkedOnly
          class="list"
          readonly></EntitiesList>
      </div>
    </div>
  </div>
</template>

<script>
import EntitiesList from '@/components/entities/List.vue'
import { ref } from 'vue'
import { getEntityId } from '@/utils/helpers.js'

export default {
  components: { EntitiesList },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const activeTab = ref(0)
    const localEntities = ref([...props.dialog.dialogData.entities])
    const searchStr = ref('')
    const entityLoading = ref(false)

    const tabs = [
      { text: 'Всі користувачі', value: 0 },
      { text: 'Обрані', value: 1 },
    ]

    const submit = () => {
      props.dialog.close()
    }

    const addEntity = entity => {
      entityLoading.value = true
      return props.dialog.dialogData.addEntityHandler(entity).then(e => {
        if (Array.isArray(e)) {
          localEntities.value = e
          entityLoading.value = false
        } else {
          localEntities.value.push(e)
          entityLoading.value = false
        }
      })
    }
    const removeEntity = entity => {
      entityLoading.value = true
      return props.dialog.dialogData.removeEntityHandler(entity).then(() => {
        localEntities.value = localEntities.value.filter(e => {
          entityLoading.value = false
          return getEntityId(e) !== getEntityId(entity)
        })
      })
    }

    return {
      activeTab,
      localEntities,
      searchStr,
      tabs,
      submit,
      addEntity,
      removeEntity,
      entityLoading,
    }
  },
}
</script>

<style lang="scss" scoped>
.list {
  overflow: auto;
  max-height: max(20rem, 40vh);
  margin-bottom: 1rem;
}

button.v-btn.v-slide-group-item--active.v-tab--selected.v-theme--light.text-primary.v-btn--density-default.v-btn--size-default.v-btn--variant-text.v-tab {
  background: #000 !important;
  color: #fff !important;
  border-radius: 40px;
  padding: 4px 12px !important;
  font-weight: 600;
  height: 30px;
  font-size: 14px !important;
  letter-spacing: -0.26px;
}

button.v-btn.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.v-tab {
  background: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #323232 !important;
  text-transform: none;
  height: 30px;
  margin: 0 5px;
  transition: 0.5s;
  letter-spacing: -0.26px;
}
</style>
