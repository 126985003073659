<template>
  <div class="mb-2">
    <div class="d-flex align-center gap-3 justify-space-between">
      <div class="d-flex align-center gap-3">
        <b-input
          v-if="showFilter('search')"
          v-model="filters.search"
          class="mb-3"
          hide-details
          label="Пошук"
          placeholder="Введіть текст"
          style="max-width: 30rem; width: 30rem" />
      </div>
      <slot name="append-search"></slot>
    </div>
    <SimpleExpansionPanel class="mb-3" label="Фільтр" @click="onToggle">
      <template #content>
        <v-row>
          <v-col
            v-if="
              showFilter('task_statusId') ||
              showFilter('control_statusId') ||
              showFilter('observer_statusId')
            "
            cols="12"
            md="3"
            sm="12">
            <b-select
              v-model="filters.statusId"
              :items="$directory.get('taskStatuses')"
              :loading="$loading.isLoading('taskStatuses')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Статус"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('taskStatuses')" />
          </v-col>
          <v-col v-if="showFilter('task_typeId')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters.typeId"
              :items="$directory.get('taskTypes')"
              :loading="$loading.isLoading('taskTypes')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Тип"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('taskTypes')" />
          </v-col>
          <v-col v-if="showFilter('task_executors[]')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters['executors[]']"
              :items="$directory.get('userList')"
              :loading="$loading.isLoading('userList')"
              multiple
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Виконавець"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('userList')" />
          </v-col>
          <v-col
            v-if="showFilter('task_startDate') && showFilter('task_endDate')"
            cols="12"
            md="3"
            sm="12">
            <BaseDatePicker
              v-model:end-date="filters.endDate"
              v-model:start-date="filters.startDate"
              hide-details
              label="Дата виконання"
              range />
          </v-col>
          <v-col v-if="showFilter('document_typeId')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters.typeId"
              :items="$directory.get('documentTypes')"
              :loading="$loading.isLoading('documentTypes')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Тип документу"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('documentTypes')" />
          </v-col>
          <v-col v-if="showFilter('departmentId')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters.departmentId"
              :items="$directory.get('departmentList')"
              :loading="$loading.isLoading('departmentList')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Департамент"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('departmentList')" />
          </v-col>
          <!-- orderTypeId -->
          <v-col v-if="showFilter('order_typeId')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters.typeId"
              :items="$directory.get('orderTypes')"
              :loading="$loading.isLoading('orderTypes')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Тип"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('orderTypes')" />
          </v-col>
          <v-col v-if="showFilter('directionId')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters.directionId"
              :items="$directory.get('orderDirections')"
              :loading="$loading.isLoading('orderDirections')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Напрямок"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('orderDirections')" />
          </v-col>
          <v-col
            v-if="showFilter('document_statusId')"
            cols="12"
            md="3"
            sm="12">
            <b-select
              v-model="filters.statusId"
              :items="getCurrentDocumentStatuses"
              :loading="$loading.isLoading('documentStatuses')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Статус"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('documentStatuses')" />
          </v-col>
          <v-col v-if="showFilter('issuedUserId')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters.issuedUserId"
              :items="$directory.get('userList')"
              :loading="$loading.isLoading('userList')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Відповідальний"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('userList')" />
          </v-col>
          <v-col v-if="showFilter('authorId')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters.authorId"
              :items="$directory.get('userList')"
              :loading="$loading.isLoading('userList')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Автор"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('userList')" />
          </v-col>
          <v-col v-if="showFilter('roleId')" cols="12" md="3" sm="12">
            <b-select
              v-model="filters.roleId"
              :items="$directory.get('roles')"
              :loading="$loading.isLoading('roles')"
              clearable
              hide-details
              item-title="name"
              item-value="id"
              label="Роль"
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('roles')" />
          </v-col>
          <v-col v-if="showFilter('toDate')" cols="12" md="3" sm="12">
            <BaseDatePicker
              v-model="filters.toDate"
              hide-details
              label="Дата від" />
          </v-col>
          <v-col v-if="showFilter('fromDate')" cols="12" md="3" sm="12">
            <BaseDatePicker
              v-model="filters.fromDate"
              hide-details
              label="Дата до" />
          </v-col>
          <v-col
            v-if="
              showFilter('control_completedEndDate') &&
              showFilter('control_completedStartDate')
            "
            cols="12"
            md="3"
            sm="12">
            <BaseDatePicker
              v-model:end-date="filters.completedEndDate"
              v-model:start-date="filters.completedStartDate"
              hide-details
              label="Дата виконання"
              range />
          </v-col>
          <v-col
            v-if="
              showFilter('control_deadlineEndDate') &&
              showFilter('control_deadlineStartDate')
            "
            cols="12"
            md="3"
            sm="12">
            <BaseDatePicker
              v-model:end-date="filters.deadlineEndDate"
              v-model:start-date="filters.deadlineStartDate"
              hide-details
              label="Термін виконання"
              range />
          </v-col>
          <v-col
            v-if="
              showFilter('observer_completedEndDate') &&
              showFilter('observer_completedStartDate')
            "
            cols="12"
            md="3"
            sm="12">
            <BaseDatePicker
              v-model:end-date="filters.completedEndDate"
              v-model:start-date="filters.completedStartDate"
              hide-details
              label="Дата виконання"
              range />
          </v-col>
          <v-col
            v-if="
              showFilter('observer_deadlineEndDate') &&
              showFilter('observer_deadlineStartDate')
            "
            cols="12"
            md="3"
            sm="12">
            <BaseDatePicker
              v-model:end-date="filters.deadlineEndDate"
              v-model:start-date="filters.deadlineStartDate"
              hide-details
              label="Термін виконання"
              range />
          </v-col>
          <v-col v-if="showFilter('isActive')" cols="12" md="3" sm="12">
            <div class="d-flex align-center">
              <v-checkbox
                v-model="filters.isActive"
                false-value="false"
                hide-details
                label="Активний"
                style="margin: 0"
                true-value="true" />
              <v-btn
                v-if="typeof filters.isActive === 'boolean'"
                icon
                size="small"
                variant="text"
                @click="filters.isActive = null">
                <v-icon>{{ 'mdi-delete' }}</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col class="d-flex align-end mb-1" cols="12" md="3" sm="12">
            <v-btn
              v-if="hasNonNullValue(modelValue)"
              style="background: #000e30 !important"
              @click="clearFilters">
              Очистити
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </SimpleExpansionPanel>
  </div>
</template>

<script>
import { computed, watch } from 'vue'
import SimpleExpansionPanel from '@/components/expansion-panel/SimpleExpansionPanel.vue'
import { forIn } from 'lodash'
import { useRoute } from 'vue-router'
import router from '@/router'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { getDirectory, fillDirectory } from '@/directory/index.ts'
import { keepArray } from 'best-modules/utils'

export default {
  components: { BaseDatePicker, SimpleExpansionPanel },
  emits: ['filter', 'update:modelValue', 'update:loading'],
  props: {
    modelValue: { type: Object, required: true },
    section: { type: String },
    loading: { type: Boolean },
  },
  setup(props, { emit }) {
    const route = useRoute()

    let filterTimeout
    const filters = computed({
      get: () => props.modelValue || {},
      set: val => emit('update:modelValue', val),
    })
    const showFilter = f => {
      let filterName = f
      if (props.section) {
        filterName = f.replace(`${props.section}_`, '')
      }
      return Object.keys(filters.value).includes(filterName)
    }

    const clearFilters = () => {
      for (const filter in filters.value) {
        if (filter !== 'search' && filters.value[filter]) {
          filters.value[filter] = null
        }
      }
    }

    const getCurrentDocumentStatuses = computed(() => {
      const items = getDirectory('documentStatuses')
      return route.name === 'document-signature-list' &&
        route.query.statusId === 6
        ? items.filter(s => [1, 6, 7, 8].includes(s.id))
        : items.filter(s => ![6, 7, 8].includes(s.id))
    })

    const hasNonNullValue = obj => {
      return Object.values(obj).some(value => value !== null)
    }

    const listsMap = {
      control_statusId: 'taskStatuses',
      task_statusId: 'taskStatuses',
      observer_statusId: 'taskStatuses',
      task_typeId: 'taskTypes',
      'task_executors[]': 'userList',
      document_typeId: 'documentTypes',
      document_statusId: 'documentStatuses',
      issuedUserId: 'userList',
      authorId: 'userList',
      order_typeId: 'orderTypes',
      roleId: 'roles',
      directionId: 'orderDirections',
      departmentId: 'departmentList',
    }
    const stringQueryTypes = [
      'search',
      'isActive',
      'toDate',
      'fromDate',
      'endDate',
      'startDate',
      'completedEndDate',
      'completedStartDate',
      'deadlineEndDate',
      'deadlineStartDate',
    ]
    // const lists = Object.entries(listsMap)
    //   .filter(f => Object.keys(filters.value).includes(f[0]))
    //   .map(f => f[1])

    const onToggle = event => {
      if (event.value) {
        clearFilters()
      }
      // else {
      //   fillDirectory(lists)
      // }
    }

    const updateFiltersQuery = () => {
      const query = route.query

      for (const key in query) {
        if (
          (Object.prototype.hasOwnProperty.call(query, key) &&
            showFilter(key)) ||
          showFilter(`${props.section}_${key}`)
        ) {
          if (key.includes('[]')) {
            if (!filters.value[key]) {
              filters.value[key] = []
            }
            filters.value[key].push(...keepArray(query[key]).map(Number))
          } else if (!stringQueryTypes.includes(key)) {
            filters.value[key] = +query[key]
          } else filters.value[key] = query[key]

          if (key in listsMap) {
            fillDirectory(listsMap[key])
          }
          if (`${props.section}_${key}` in listsMap) {
            fillDirectory(listsMap[`${props.section}_${key}`])
          }
        }
      }
    }

    updateFiltersQuery()

    watch(
      () => filters,
      val => {
        clearTimeout(filterTimeout)
        const query = {}
        forIn(filters.value, (value, key) => {
          if (value) {
            if (Array.isArray(value)) {
              query[key] = value.map(v => {
                return Number.isNaN(+v) || String(v)?.startsWith('0') ? v : +v
              })
            } else {
              query[key] =
                Number.isNaN(+value) || String(value)?.startsWith('0')
                  ? value
                  : +value
            }
            if (
              JSON.stringify(filters.value[key]) !== JSON.stringify(query[key])
            ) {
              filters.value[key] = query[key]
            }
          }
        })
        router.push({ name: route.name, query })
        filterTimeout = setTimeout(() => emit('filter', val), 500)
      },
      { deep: true }
    )

    return {
      onToggle,
      filters,
      showFilter,
      clearFilters,
      hasNonNullValue,
      getCurrentDocumentStatuses,
    }
  },
}
</script>

<style lang="scss" scoped></style>
