import { required, email } from '@/plugins'

const getUserRules = () => {
  return {
    name: { required },
    secondName: { required },
    patronymic: { required },
    genderId: { required },
    birthDate: { required },
    inn: { required },
    post: { required },
    photo: { required },
    phone: { required },
    roleId: { required },
    email: { required, email },
    profileDWH: { required },
  }
}

export { getUserRules }
