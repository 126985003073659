import { computed } from 'vue'
import { axios } from '@/plugins/index.js'
import { urlFamiliarizationAgree } from '@/utils/urls.js'
import { useStore } from 'vuex'
import { setSnackbar } from 'best-modules/plugins/index'

export function useFamiliarization(order) {
  const store = useStore()
  const user = computed(() => store.state.user.userData)

  const familiarizationAgree = () => {
    return axios.get(urlFamiliarizationAgree(order.id)).then(() => {
      order.familiarizations ??= []
      const f = order.familiarizations.find(f => f.userId === user.value.id)
      const newF = {
        userId: user.value.id,
        isFamiliarization: true,
      }
      setSnackbar({
        color: 'success',
        text: 'Ви успішно ознайомились з документом',
      })
      if (f) {
        Object.assign(f, newF)
      } else {
        order.familiarizations.push(newF)
      }
    })
  }

  const currentFamiliarization = computed(() => {
    return order.familiarizations.find(f => f.userId === user.value.id) || null
  })

  const isFamiliarization = computed(() => {
    return currentFamiliarization.value?.isFamiliarization || false
  })

  return {
    isFamiliarization,
    familiarizationAgree,
    currentFamiliarization,
  }
}
