<template>
  <div>
    <v-skeleton-loader
      v-for="(row, idx) in rows"
      :key="row"
      :loading="loading"
      :class="{ 'mb-3': idx < rows - 1 }"
      style="background: transparent"
      type="ossein"
      :width="width"
      :height="height">
      <template #default>
        <slot name="default"></slot>
      </template>
    </v-skeleton-loader>
  </div>
</template>

<script>
export default {
  props: {
    rows: { type: Number, default: 5 },
    width: { type: String },
    height: { type: String, default: '2.85rem' },
    loading: { type: Boolean, default: true },
  },
}
</script>

<style lang="scss" scoped></style>
