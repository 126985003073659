import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes'
import store from '@/store/index.ts'
import { writeRouteHistory } from 'best-modules/utils'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach(writeRouteHistory)

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    document.title = to.meta?.title || 'Система візування'
  }, 0)

  const hasToken = !!store.state.auth.token
  const hasAuthUser = !!store.state.auth.authUser.id
  const isAuthRoute = ['login', 'auth-confirm', 'forgot-password'].includes(
    to.name
  )

  switch (true) {
    case to.name === 'share-document':
      next()
      break
    case !isAuthRoute && !hasToken:
      next({ name: 'login' })
      break
    case isAuthRoute && hasToken && from.name === 'home':
      next(false)
      break
    case isAuthRoute && hasToken:
      next({ name: 'home' })
      break
    case to.name === 'auth-confirm' && !hasAuthUser:
      next({ name: 'login' })
      break
    default:
      next()
  }
})

export default router
