<template>
  <v-row class="flex-wrap-reverse">
    <v-col cols="12" md="7">
      <!-- row -->
      <v-row>
        <v-col cols="12" md="4">
          <b-select
            v-model="correspondence.typeId"
            :error-messages="getErrorMessages(v$.typeId)"
            :items="$directory.get('correspondenceTypes', correspondence.type)"
            :loading="$loading.isLoading('correspondenceTypes')"
            hide-details
            label="Тип кореспонденції"
            placeholder="оберіть тип"
            @blur="v$.typeId.$touch()"
            @focus="$directory.fill('correspondenceTypes')"></b-select>
        </v-col>
        <v-col cols="12" md="4">
          <b-select
            v-model="correspondence.documentTypeId"
            :error-messages="getErrorMessages(v$.documentTypeId)"
            :items="
              $directory.get(
                'correspondenceDocumentTypes',
                correspondence.document_type
              )
            "
            :loading="$loading.isLoading('correspondenceDocumentTypes')"
            hide-details
            label="Тип документу"
            placeholder="оберіть тип"
            @blur="v$.documentTypeId.$touch()"
            @focus="$directory.fill('correspondenceDocumentTypes')"
            @update:model-value="
              () => {
                $directory.fill('correspondenceDeliveryTypes')
                correspondence.documentPriorityTypeId = null
                correspondence.documentDeliveryTypeId = 2
              }
            "></b-select>
        </v-col>
      </v-row>
      <!-- row -->
      <v-row>
        <v-col cols="12" md="4">
          <b-input
            v-model="correspondence.registrationNumber"
            :error-messages="getErrorMessages(v$.registrationNumber)"
            :mask="'#'.repeat(7)"
            hide-details
            label="Номер"
            @blur="v$.registrationNumber.$touch()">
          </b-input>
        </v-col>
        <v-col cols="12" md="4">
          <base-date-picker
            v-model="correspondence.registrationDate"
            :error-messages="getErrorMessages(v$.registrationDate)"
            hide-details
            label="Дата реєстрації"
            timePicker
            @blur="v$.registrationDate.$touch()"></base-date-picker>
        </v-col>
      </v-row>
      <!-- row -->
      <v-row>
        <v-col
          v-if="[1, 4].includes(correspondence.documentTypeId)"
          cols="12"
          md="4">
          <b-select
            v-model="correspondence.documentDeliveryTypeId"
            :error-messages="getErrorMessages(v$.documentDeliveryTypeId)"
            :items="
              $directory.get(
                'correspondenceDeliveryTypes',
                correspondence.document_delivery_type
              )
            "
            :label="
              correspondence.typeId === 1
                ? 'Тип доставлення'
                : 'Тип відправлення'
            "
            :loading="$loading.isLoading('correspondenceDeliveryTypes')"
            hide-details
            placeholder="оберіть тип"
            @blur="v$.documentDeliveryTypeId.$touch()"
            @focus="$directory.fill('correspondenceDeliveryTypes')"></b-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-scroll-x-transition v-show="correspondence.documentTypeId === 1">
            <b-select
              v-model="correspondence.documentPriorityTypeId"
              :error-messages="getErrorMessages(v$.documentPriorityTypeId)"
              :items="
                $directory.get(
                  'correspondencePriorityTypes',
                  correspondence.document_priority_type
                )
              "
              :loading="$loading.isLoading('correspondencePriorityTypes')"
              hide-details
              label="Пріорітет"
              placeholder="оберіть тип"
              @blur="v$.documentPriorityTypeId.$touch()"
              @focus="
                $directory.fill('correspondencePriorityTypes')
              "></b-select>
          </v-scroll-x-transition>
        </v-col>
        <v-col cols="12" md="12">
          <b-autocomplete
            v-model="correspondence.contractor"
            v-model:search="search"
            :hide-no-data="search.length < 3"
            :item-title="c => `${c.shortName || c.name} (${c.code})`"
            :items="
              search.length < 3
                ? [correspondence.contractor].filter(c => {
                    return correspondence.contractorId ? c : false
                  })
                : $directory.get('contractorList', correspondence.contractor)
            "
            :loading="$loading.isLoading('contractorList')"
            hide-details
            label="Контрагент"
            placeholder="Введіть назву контрагента або ЄДРПОУ"
            return-object
            @update:model-value="
              () => {
                correspondence.contractorId = $event?.id || null
                $event && getAddressList()
              }
            "
            @update:search="
              $event?.length === 3 && $directory.fill('contractorList')
            ">
            <template #append-item>
              <div
                class="d-flex align-center append-item-create"
                @click="createContractor">
                <v-btn density="comfortable" icon="mdi-plus" variant="plain">
                </v-btn>
                <span>Створити нового</span>
              </div>
            </template>
          </b-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <v-scroll-y-transition>
            <b-select
              v-show="correspondence.contractorId"
              v-model="correspondence.addressId"
              :item-title="a => `${a.address} (${a.type.name})`"
              :items="addressList"
              :loading="$loading.isLoading('getContractorSingle')"
              hide-details
              label="Адреса"
              placeholder="оберіть адресу"
              @focus="getAddressList">
              <template #append>
                <v-tooltip text="додати адресу">
                  <template #activator="{ props }">
                    <v-btn
                      density="comfortable"
                      icon="mdi-plus"
                      v-bind="props"
                      variant="plain"
                      @click="createAddress">
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
            </b-select>
          </v-scroll-y-transition>
        </v-col>
        <v-col cols="12">
          <b-input
            v-model="correspondence.subject"
            :error-messages="getErrorMessages(v$.subject)"
            hide-details
            label="Тема"
            @blur="v$.subject.$touch()">
          </b-input>
        </v-col>
        <v-col cols="12">
          <b-textarea
            v-model="correspondence.description"
            hide-details
            label="Опис"
            placeholder="Введіть опис">
          </b-textarea>
        </v-col>
        <v-col cols="12" md="12">
          <b-select
            v-model="correspondence.departmentId"
            :error-messages="getErrorMessages(v$.departmentId)"
            :items="$directory.get('departmentList', correspondence.department)"
            :loading="$loading.isLoading('departmentList')"
            hide-details
            label="Департамент"
            placeholder="оберіть департамент"
            @blur="v$.departmentId.$touch()"
            @focus="$directory.fill('departmentList')"></b-select>
        </v-col>
      </v-row>

      <!-- row -->
      <v-row class="align-center mb-4" style="justify-content: space-between">
        <v-col cols="12" md="8" sm="12">
          <b-autocomplete
            v-model="selectedUser"
            :items="$directory.get('userList')"
            :loading="$loading.isLoading('userList')"
            hide-details
            item-title="name"
            item-value="id"
            label="Виконавець та Одержувач"
            placeholder="Оберіть зі списку"
            return-object
            @focus="$directory.fill('userList')" />
        </v-col>
        <v-col class="mt-6" cols="12" md="2" sm="12">
          <v-btn
            :disabled="
              correspondence.type.id !== 1 && correspondence.users.length >= 1
            "
            color="secondary"
            @click="handleAccessAddUser">
            <v-icon class="mr-1">{{ 'mdi-plus-box-multiple-outline' }}</v-icon>
            Додати
          </v-btn>
        </v-col>
        <v-col v-if="correspondence.users.length" cols="12" md="12" sm="12">
          <CustomItem
            v-for="user in correspondence.users"
            :key="user.id"
            :item="user"
            hidePost>
            <template #actions>
              <v-btn
                :disabled="disabled"
                icon
                size="small"
                variant="text"
                @click="handleRemoveUser(user.id)">
                <v-icon icon="mdi-delete" size="large" />
              </v-btn>
            </template>
          </CustomItem>
        </v-col>
      </v-row>

      <!-- row -->
      <v-row>
        <v-col cols="12">
          <div>
            <div class="mb-3 d-flex align-center gap-3">
              Пов'язана кореспонденція
              <v-btn
                density="compact"
                icon="mdi-plus"
                variant="plain"
                @click="addRelatedCorrespondence"></v-btn>
            </div>
            <div
              v-if="correspondence.related_correspondences?.length"
              class="pl-8">
              <div
                v-for="c in correspondence.related_correspondences"
                :key="c.id"
                class="mb-2">
                <span class="link">
                  {{ c.subject }}
                </span>
                <v-btn
                  density="compact"
                  icon="mdi-close"
                  variant="plain"
                  @click="removeRelatedCorrespondence(c.id)">
                  <v-icon size="small">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div v-else class="text-disabled text-center">
              Пов'язана кореспонденція відсутня
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="5">
      <attachment class="mb-3" label="Вкладення">
        <template #label>
          <div class="d-flex justify-space-between">
            <span class="font-weight-bold">Вкладення</span>
            <v-checkbox
              v-model="correspondence.isOrigin"
              hide-details
              label="Оригінал"></v-checkbox>
          </div>
        </template>
        <template #content>
          <files-uploader
            v-model="correspondence.documents"
            label="Документи"
            main-folder="Корреспонденция"
            :name-folder="correspondence.created_at.substring(6, 10)"
            :prefix-folder="
              correspondence.created_at.substring(3, 5)
            "></files-uploader>
        </template>
      </attachment>
      <Comments
        :comments="correspondence.comments"
        :entityId="correspondence.id"
        :entityTypeId="4" />
      <div class="info mb-3">
        <div class="text-right">
          <span>Дата створення: {{ correspondence.created_at }}</span>
          <span>Дата оновлення: {{ correspondence.updated_at }}</span>
          <span>
            Відповідальний: {{ getUserName(correspondence.responsible) }}
          </span>
        </div>
      </div>
      <div class="d-flex justify-end">
        <v-btn v-show="correspondence.statusId === 1" @click="delivered">
          <v-icon class="mr-3">mdi-package-variant-closed-check</v-icon>
          {{ correspondence?.status?.name }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import CustomItem from '@/dialog/components/templateGroup/CustomItem.vue'
import Attachment from '@/components/Attachment.vue'
import { BTextarea } from 'best-modules/components'
import { getErrorMessages } from 'best-modules/utils'
import {
  addressList,
  correspondence,
  getAddressList,
  v$,
} from '../correspondence'
import { openDialog } from '@/dialog'
import axios from '@/plugins/axios'
import {
  urlCorrespondenceCreateUser,
  urlCorrespondenceDeleteUser,
  urlCorrespondenceUnrelated,
} from '@/utils/urls'
import { getUserName } from '@/utils/helpers.js'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { ref } from 'vue'
import { pushDirectoryItem } from '@/directory'
import { setSnackbar } from 'best-modules/plugins/index'
import { useRoute } from '@/plugins'
import Comments from '@/components/Comments.vue'

export default {
  name: 'CorrespondenceGeneralInfo',
  components: { Comments, CustomItem, BaseDatePicker, Attachment, BTextarea },
  methods: { getErrorMessages, getUserName },
  setup() {
    const route = useRoute()
    const search = ref('')
    const selectedUser = ref(null)

    const delivered = () => {
      openDialog({
        name: 'CorrespondenceDeliver',
        dialogData: {
          correspondenceId: correspondence.id,
        },
        params: {
          cb: res => {
            correspondence.$set(res)
          },
        },
      })
    }

    const handleAccessAddUser = () => {
      if (!selectedUser.value) {
        setSnackbar({
          text: 'Спочатку оберіть виконавця та одержувача',
          color: 'error',
        })
      } else {
        handleCreateTaskUser(selectedUser.value)
      }
    }

    const handleCreateTaskUser = user => {
      const obj = {
        correspondenceId: +route.params.id,
        userId: user?.id,
      }
      if (obj.userId && obj.correspondenceId) {
        return axios.post(urlCorrespondenceCreateUser(), obj).then(res => {
          correspondence.users.push(res.data)
          selectedUser.value = null
          return res
        })
      }
    }

    const handleRemoveUser = userId => {
      return axios.delete(urlCorrespondenceDeleteUser(userId)).then(() => {
        correspondence.users = correspondence.users.filter(c => c.id !== userId)
      })
    }

    const createContractor = () => {
      openDialog({
        name: 'Contractor',
        params: {
          cb: contractor => {
            pushDirectoryItem('contractorList', contractor)
          },
        },
      })
    }
    const createAddress = () => {
      openDialog({
        name: 'ContractorContact',
        action: 'create',
        dialogData: {
          type: 'address',
          contractorId: correspondence.contractorId,
          title: 'Адреса',
        },
        params: {
          cb: address => {
            addressList.value.push(address)
          },
        },
      })
    }

    const addRelatedCorrespondence = () => {
      openDialog({
        name: 'RelatedCorrespondence',
        dialogData: {
          correspondenceId: correspondence.id,
        },
        params: {
          cb: res => {
            correspondence.related_correspondences = res.related_correspondences
          },
        },
      })
    }
    const removeRelatedCorrespondence = relatedId => {
      return axios
        .post(urlCorrespondenceUnrelated(correspondence.id), {
          relatedId,
        })
        .then(() => {
          correspondence.related_correspondences =
            correspondence.related_correspondences.filter(
              c => c.id !== relatedId
            )
        })
    }

    return {
      search,
      delivered,
      selectedUser,
      handleAccessAddUser,
      addRelatedCorrespondence,
      handleRemoveUser,
      removeRelatedCorrespondence,
      correspondence,
      v$,
      addressList,
      getAddressList,
      createContractor,
      createAddress,
    }
  },
}
</script>

<style lang="scss" scoped>
.info {
  background: #f5f6f8;
  padding: 8px;
  display: flex;
  justify-content: flex-end;

  span {
    display: block;
    font-size: 12px;
    color: #b5b7c0;
    margin-bottom: 4px;
  }
}

.append-item-create {
  min-height: 48px;
  padding: 4px;

  &:hover {
    background: #e7e7e7 !important;
  }
}
</style>
