import { generateId } from '@/utils/helpers.js'
import { confirm } from '@/plugins/confirm'
import {
  SelectFileOptions,
  InputFileAttributes,
  FilesHandlerOptions,
  FileResult,
} from './types'
import { defaults } from './defaults'

export function selectFile(options: SelectFileOptions) {
  const { accept, multiple, confirmMsg = false } = options
  const input = createInputElement({ accept, multiple })

  return new Promise((resolve, reject) => {
    try {
      input.addEventListener('change', event => {
        // @ts-ignore
        filesHandler({ fileList: event.target.files, resolve, reject })
      })

      if (confirmMsg) {
        /* show confirmation before select */
        confirm({
          text: typeof confirmMsg === 'string' ? confirmMsg : defaults.confirm,
        })
          .then(() => input.click())
          .catch(reject)
      } else {
        input.click()
      }
    } catch (e) {
      return reject()
    }
  })
}

export function filesHandler({
  fileList,
  resolve,
  reject,
}: FilesHandlerOptions) {
  const files: Blob[] = Array.from(fileList)

  const result: FileResult = {
    length: files.length,
    files: fileList,
    blob: files,
    path: files.map(f => URL.createObjectURL(f)),
    formData: new FormData(),
  }

  if (!result.length) {
    reject && reject(result)
    return result
  }

  // fill formData
  for (const f of files) {
    result.formData.append('file[]', f)
  }

  resolve && resolve(result)
  return result
}

function createInputElement({ accept, multiple }: InputFileAttributes) {
  const inputElement = document.createElement('input')

  inputElement.classList.add('hidden')
  inputElement.setAttribute('type', 'file')
  inputElement.setAttribute('id', `input-file_${generateId()}`)
  inputElement.setAttribute('accept', (accept || defaults.accept).join(', '))
  multiple && inputElement.setAttribute('multiple', 'true')

  return inputElement
}
