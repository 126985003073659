<template>
  <div>
    <div
      class="d-flex align-center mb-4 justify-space-between"
      style="width: 100%">
      <v-tooltip :text="document && document[0]?.name" bottom>
        <template #activator="{ props }">
          <span class="document-title mr-4" v-bind="props">{{
            (document && document[0]?.name) || 'Назва не вказана'
          }}</span>
        </template>
      </v-tooltip>
      <div
        class="d-flex align-center"
        style="min-width: 20rem; max-width: 20rem">
        <slot v-if="!loading" name="top-header"></slot>
      </div>
    </div>
    <div :class="{ 'fullscreen-iframe': fullWidth }" class="document-page">
      <div class="document-page__doc">
        <div
          v-if="document && document.path && !document.path.includes('.pdf')"
          class="document-page__header-section">
          <slot
            v-if="!loading"
            :fullWidth="fullWidth"
            name="header"
            @updateFullWidth="updateFullWidth"></slot>
        </div>
        <DocumentPreview
          :document="document"
          :fullWidth="fullWidth"
          :zoomPercentage="zoomPercentage"
          :token="token" />
      </div>
      <v-card class="document-page__info info-block-general">
        <Skeleton v-if="loading" />
        <slot v-else name="info"></slot>
      </v-card>
    </div>
  </div>
</template>

<script>
import DocumentPreview from './DocumentPreview.vue'
import Skeleton from '@/components/Skeleton.vue'

export default {
  name: 'DocumentPageTemplate',
  components: { DocumentPreview, Skeleton },
  emits: ['updateFullWidth'],
  props: {
    document: {
      type: [Array, Object],
    },
    zoomPercentage: {
      type: Number,
      default: 100,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
    token: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const updateFullWidth = value => {
      emit('updateFullWidth', value)
    }
    return {
      updateFullWidth,
    }
  },
}
</script>

<style lang="scss" scoped></style>
