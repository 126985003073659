<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn @click="createCorrespondence">
        <v-icon>mdi-plus</v-icon>
        Створити кореспонденцію
      </v-btn>
    </div>
    <b-server-table
      :key="renderKey"
      :headers="headers"
      :request-func="getCorrespondence"
      show-filters
      show-search
      @click:row="
        $router.push({
          name: 'correspondence-single',
          params: { id: $event.id },
        })
      ">
      <template #filters>
        <b-server-filter-item
          v-slot="{ props }"
          directory="correspondenceTypes"
          query-key="typeId">
          <b-select
            label="Тип"
            placeholder="оберіть тип"
            v-bind="props"></b-select>
        </b-server-filter-item>
        <b-server-filter-item
          v-slot="{ props }"
          directory="correspondenceStatuses"
          query-key="statusId">
          <b-select
            label="Статус"
            placeholder="оберіть статус"
            v-bind="props"></b-select>
        </b-server-filter-item>
        <b-server-filter-item
          v-slot="{ props }"
          directory="correspondenceDocumentTypes"
          query-key="documentTypeId">
          <b-select
            label="Тип документу"
            placeholder="оберіть тип"
            v-bind="props"></b-select>
        </b-server-filter-item>
        <b-server-filter-item
          v-slot="{ props }"
          directory="correspondenceDeliveryTypes"
          query-key="documentDeliveryTypeId">
          <b-select
            label="Тип доставки"
            placeholder="оберіть тип"
            v-bind="props"></b-select>
        </b-server-filter-item>
        <b-server-filter-item
          v-slot="{ props }"
          directory="departmentList"
          query-key="departmentId">
          <b-select
            label="Департамент"
            placeholder="оберіть департамент"
            v-bind="props"></b-select>
        </b-server-filter-item>
        <b-server-filter-item v-slot="{ props }" query-key="responsibleId">
          <b-server-autocomplete
            label="Відповідальний"
            placeholder="оберіть користувача"
            url="/json/user/getAll"
            v-bind="props">
          </b-server-autocomplete>
        </b-server-filter-item>
      </template>
      <template #[`item.responsible`]="{ item }">
        {{ getUserName(item.responsible, { initials: true }) }}
      </template>
      <template #[`item.document_type`]="{ item }">
        <span v-if="item.document_type">{{ item.document_type?.name }}</span>
        <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
      </template>
      <template #[`item.contractor`]="{ item }">
        <v-tooltip
          v-if="item.contractor"
          :text="item.contractor?.shortName"
          bottom>
          <template #activator="{ props }">
            <span
              class="link"
              style="
                display: block;
                width: 230px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              v-bind="props"
              @click.stop="
                $router.push({
                  name: 'single-contractor',
                  params: { id: item?.contractor?.id },
                })
              "
              >{{ item.contractor?.shortName }}</span
            >
          </template>
        </v-tooltip>
        <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
      </template>
      <template #[`item.subject`]="{ item }">
        <v-tooltip v-if="item.subject" :text="item.subject" bottom>
          <template #activator="{ props }">
            <span
              style="
                display: block;
                width: 350px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              v-bind="props"
              >{{ item.subject }}</span
            >
          </template>
        </v-tooltip>
        <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
      </template>
      <template #[`item.documents`]="{ item }">
        <v-icon v-if="item.documents">{{ 'mdi-check' }}</v-icon>
        <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
      </template>
      <template #[`item.actions`]="{ item }">
        <b-action-menu
          :actions="[
            {
              title: 'Відкрити',
              icon: 'mdi-eye',
              action: () =>
                $router.push({
                  name: 'correspondence-single',
                  params: { id: item.id },
                }),
            },
            {
              title: 'Видалити',
              icon: 'mdi-delete',
              action: () => deleteCorrespondence(item.id),
            },
          ]"></b-action-menu>
      </template>
    </b-server-table>
  </div>
</template>

<script>
import {
  BActionMenu,
  BServerAutocomplete,
  BServerFilterItem,
  BServerTable,
} from 'best-modules/components'
import axios from '@/plugins/axios'
import { urlCorrespondenceDelete, urlCorrespondenceList } from '@/utils/urls.js'
import { computed, ref } from 'vue'
import { openDialog } from '@/dialog/index.ts'
import { useRouter } from 'vue-router'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { ADMIN, CLERKSHIP, CORRESPONDENT } from '@/utils/roles'
import { useStore } from 'vuex'

export default {
  name: 'CorrespondenceList',
  components: {
    BServerTable,
    BActionMenu,
    BServerFilterItem,
    BServerAutocomplete,
  },
  methods: { navigateToItem, getUserName },
  setup() {
    const router = useRouter()
    const store = useStore()
    const user = computed(() => {
      return store.state.user.UserData
    })
    const headers = [
      {
        key: 'registrationDate',
        title: 'Дата реєстрації',
        minWidth: '160px',
        align: 'start',
      },
      {
        key: 'registrationNumber',
        title: 'Номер',
        align: 'start',
      },
      {
        key: 'type.name',
        title: 'Тип',
      },
      {
        key: 'document_type',
        title: 'Тип документа',
        minWidth: '180px',
      },
      {
        key: 'contractor',
        title: 'Контрагент',
      },
      {
        key: 'subject',
        title: 'Тема',
      },
      {
        key: 'documents',
        title: 'Документи',
      },
      {
        key: 'responsible',
        title: 'Відповідальний',
      },
      {
        key: 'actions',
        title: 'Дії',
      },
    ]
    const renderKey = ref(1)
    const getCorrespondence = query => {
      return axios.get(urlCorrespondenceList(query)).then(res => res.data)
    }

    const createCorrespondence = () => {
      openDialog({
        name: 'Correspondence',
        params: {
          cb: correspondence => {
            router.push({
              name: 'correspondence-single',
              params: { id: correspondence.id },
            })
          },
          submitLabel: 'Створити',
        },
      })
    }

    const deleteCorrespondence = correspondenceId => {
      return axios
        .delete(urlCorrespondenceDelete(correspondenceId))
        .then(() => (renderKey.value += 1))
    }

    return {
      renderKey,
      getCorrespondence,
      createCorrespondence,
      deleteCorrespondence,
      headers,
      ADMIN,
      CLERKSHIP,
      CORRESPONDENT,
      user,
    }
  },
}
</script>
