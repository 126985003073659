import { socket, notificationChannel } from '@/socket.js'
import { axios } from '@/plugins/index.js'
import { urlGetNotifications, urlReadNotification } from '@/utils/urls.js'
import { playSound } from '@/utils/helpers.js'
import { Module } from 'vuex'

const NOTIFICATION_SOUND_URL = '/notification.mp3'

type Notification = {
  id: number
  userId: number
  message: string
  isRead: boolean
  isReadSend: boolean
  section: 'document' | 'order' | 'task'
  rowId: number
  created_at: string
  updated_at: string
}

const state = {
  notificationList: [],
  notificationsReadQueue: [],
}

const notifications: Module<typeof state, any> = {
  state() {
    return state
  },
  mutations: {
    setNotifications(state, notificationList: Notification[]) {
      state.notificationList = notificationList
    },
    addNotification(state, notification: Notification) {
      state.notificationList.unshift(notification)
    },
    addNotificationToQueue(state, notificationId: number) {
      state.notificationsReadQueue.push(notificationId)
    },
    removeNotificationFromQueue(state, notificationId: number) {
      state.notificationsReadQueue = state.notificationsReadQueue.filter(
        n => n !== notificationId
      )
    },
    confirmNotificationQueue(state) {
      state.notificationsReadQueue.forEach(notificationId => {
        const notification = state.notificationList.find(
          n => n.id === notificationId
        )
        notification.isRead = true
      })
    },
  },
  actions: {
    getNotifications({ commit }) {
      return axios.get(urlGetNotifications()).then(res => {
        commit('setNotifications', res.data)
      })
    },
    readNotification({ state, commit }, notificationId: number) {
      if (state.notificationsReadQueue.includes(notificationId)) {
        return
      }
      commit('addNotificationToQueue', notificationId)
      return axios.get(urlReadNotification(notificationId)).catch(() => {
        commit('removeNotificationFromQueue', notificationId)
      })
    },
    async listenNotificationEvents({ commit, rootState, dispatch }) {
      // load notification sound
      await dispatch('files/loadFile', {
        fileType: 'audio',
        originPath: NOTIFICATION_SOUND_URL,
      })

      socket.on(
        // @ts-ignore
        notificationChannel,
        (res: Notification) => {
          if (rootState.user.userData?.id === res.userId) {
            commit('addNotification', res)

            playSound(rootState.files.audio[NOTIFICATION_SOUND_URL])
          }
        }
      )
    },
  },
}

export { notifications }
