import { DataTableHeader } from 'best-modules/components'

const headers: DataTableHeader[] = [
  {
    key: 'type.name',
    title: 'Тип',
    sortable: false,
  },
  {
    key: 'status.name',
    title: 'Статус',
    minWidth: '150px',
    sortable: false,
  },
  {
    key: 'topic',
    title: 'Тема',
    sortable: false,
  },
  {
    key: 'executor',
    title: 'Виконавець',
    minWidth: '150px',
    sortable: false,
  },
  {
    key: 'author',
    title: 'Автор',
    minWidth: '150px',
    sortable: false,
  },
  {
    key: 'deadline',
    title: 'Термін виконання',
    minWidth: '150px',
    sortable: false,
  },
  {
    key: 'completedDate',
    title: 'Дата завершення',
    sortable: false,
  },
  {
    key: 'contractor',
    title: 'Контрагент',
    sortable: false,
  },
  {
    key: 'connect',
    title: "Зв'язок",
    sortable: false,
  },
  {
    key: 'actions',
    title: 'Дії',
    align: 'end',
    sortable: false,
  },
]

export { headers }
