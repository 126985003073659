import axios from '@/plugins/axios/index.js'
import { urlUserData } from '@/utils/urls.js'
import { Module } from 'vuex'

const state = {
  userData: null,
}

const user: Module<typeof state, any> = {
  namespaced: true,
  state() {
    return state
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData
    },
  },
  actions: {
    getUserData({ commit }) {
      return axios.get(urlUserData()).then(res => {
        res.status === 200 && commit('setUserData', res.data)
        return res.status
      })
    },
  },
}

export { user }
