<template>
  <v-dialog
    v-model="isActive"
    persistent
    absolute
    :contentProps="{ style: 'top: 0' }"
    width="700">
    <v-card>
      <v-card-text>
        <div class="d-flex gap-5">
          <div class="align-self-center text-h5">
            <v-icon end start size="x-large" :icon="confirmData.icon"></v-icon>
          </div>
          <div class="w-100">
            <div class="text-h5 mb-3">
              {{ confirmData.title }}
            </div>
            <span>
              {{ confirmData.text }}
            </span>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- has listener in @/plugins/confirm/index.js -->
              <v-btn id="confirmation-reject">Відмінити</v-btn>
              <v-btn id="confirmation-accept">Підтвердити</v-btn>
            </v-card-actions>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { isActive, confirmData } from '@/plugins/confirm/index.ts'

export default {
  name: 'Confirm',
  setup() {
    return { isActive, confirmData }
  },
}
</script>

<style lang="scss" scoped>
.confirm-icon {
}
</style>
