<template>
  <Skeleton v-if="$loading.isLoading('initDocumentTemplateGroups')" />
  <div v-else>
    <div v-if="!groupList.length" class="text-center">
      <span>Для створення маршрута документа додайте хоча б одну групу</span>
      <div class="d-flex justify-center mt-3">
        <v-btn size="small" @click="addGroup()">
          Додати групу
          <template #append>
            <v-icon icon="mdi-plus" />
          </template>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-end mb-2">
        <v-btn color="secondary" @click="addGroup()">
          Додати групу
          <template #append>
            <v-icon icon="mdi-plus" size="large" />
          </template>
        </v-btn>
      </div>
      <v-stepper
        v-model="currentGroup.queueNumber"
        :items="sortedGroupList"
        hide-actions
        item-value="queueNumber">
        <template
          v-for="group in sortedGroupList"
          :key="group.queueNumber"
          #[`header-item.${group.queueNumber}`]>
          <div class="d-flex align-center gap-2">
            <div class="d-flex flex-column gap-2" style="max-width: 160px">
              <v-chip
                v-for="g in group.groups"
                :key="g.id"
                :variant="currentGroup.group.id === g.id ? 'tonal' : 'text'"
                class="rounded-pill"
                density="comfortable"
                @click="openGroup(group.queueNumber, g)">
                {{ g.name }}
              </v-chip>
            </div>
            <v-tooltip text="Додати паралельну групу">
              <template #activator="{ props }">
                <v-btn
                  class="mr-2"
                  density="compact"
                  icon="mdi-plus"
                  v-bind="props"
                  variant="plain"
                  @click.stop="addGroup(group.queueNumber)"></v-btn>
              </template>
            </v-tooltip>
          </div>
        </template>
      </v-stepper>
      <TemplateGroupItem
        :group="currentGroup.group"
        @update:group="updateGroup(currentGroup.group, $event)"
        @delete-group="deleteGroup">
        <template #queue-number>
          <Counter
            :length="groupList.length"
            :value="currentGroup.queueNumber"
            @decrement="changeQueueNumber('minus')"
            @increment="changeQueueNumber('plus')" />
        </template>
      </TemplateGroupItem>
      <div class="d-flex justify-end">
        <v-btn
          :loading="$loading.isLoading('submit')"
          class="mt-5"
          color="secondary"
          @click="submit">
          Зберегти
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import Counter from './Counter.vue'
import { computed, reactive, ref } from 'vue'
import {
  urlCreateRouteDocument,
  urlDeleteTemplateRouteDocument,
  urlGetTemplateRoutingDocumentSingle,
  urlUpdateRouteDocument,
} from '@/utils/urls'
import { axios } from '@/plugins'
import { getErrorMessages } from '@/utils/helpers'
import { cachedObject } from 'best-modules/utils'
import TemplateGroupItem from '@/dialog/components/templateGroup/templateGroupItem.vue'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { fillDirectory } from '@/directory/index.ts'

export default {
  name: 'TemplateGroup',
  components: { Counter, TemplateGroupItem, Skeleton },
  props: {
    dialog: { type: Object },
  },
  methods: { getErrorMessages },
  async setup(props) {
    const routeData = ref({})
    const groupList = ref([])
    const currentGroup = reactive({
      queueNumber: null,
      group: null,
    })

    const updateGroup = (group, updatedData) => {
      Object.assign(group, updatedData)
    }

    const openGroup = (queueNumber, group) => {
      if (!groupList.value.length) {
        return
      }
      if (!queueNumber || !group) {
        currentGroup.queueNumber = groupList.value[0].queueNumber
        currentGroup.group = groupList.value[0]
      } else {
        currentGroup.queueNumber = queueNumber
        currentGroup.group = group
      }
    }

    const sortedGroupList = computed(() => {
      const result = []
      const existQueue = []
      groupList.value.forEach(g => {
        if (!existQueue.includes(g.queueNumber)) {
          result.push({
            queueNumber: g.queueNumber,
            groups: [g],
            title: '',
          })
          existQueue.push(g.queueNumber)
        } else {
          const q = result.find(r => r.queueNumber === g.queueNumber)
          q.groups.push(g)
        }
      })
      return result.sort((a, b) => a.queueNumber - b.queueNumber)
    })

    const changeQueueNumber = action => {
      const currentGroups = sortedGroupList.value.find(g => {
        return g.queueNumber === currentGroup.queueNumber
      })
      if (action === 'plus') {
        const nextGroups = sortedGroupList.value.find(g => {
          return g.queueNumber === currentGroup.queueNumber + 1
        })
        if (nextGroups) {
          currentGroups.groups.forEach(g => {
            updateGroup(g, {
              queueNumber: currentGroup.queueNumber + 1,
            })
          })
          nextGroups.groups.forEach(g => {
            updateGroup(g, { queueNumber: nextGroups.queueNumber - 1 })
          })
        }
      } else if (action === 'minus') {
        const prevGroups = sortedGroupList.value.find(g => {
          return g.queueNumber === currentGroup.queueNumber - 1
        })
        if (prevGroups) {
          currentGroups.groups.forEach(g => {
            updateGroup(g, {
              queueNumber: currentGroup.queueNumber - 1,
            })
          })
          prevGroups.groups.forEach(g => {
            updateGroup(g, { queueNumber: prevGroups.queueNumber + 1 })
          })
        }
      }

      openGroup(currentGroup.group.queueNumber, currentGroup.group)
    }

    const submit = () => {
      return handleAsync('submit', () => {
        return Promise.all(
          groupList.value
            .filter(g => g.$hasChanges)
            .map(g => {
              return axios.post(urlUpdateRouteDocument(g.id), g).then(res => {
                g.$set(res.data)
              })
            })
        ).then(() => {
          setSnackbar({ text: 'Дані збережено' })
        })
      })
    }

    const pushGroup = group => {
      const g = cachedObject(group)
      g.$setIgnore('users')
      groupList.value.push(g)
      return g
    }

    const getTemplateRouteSingle = () => {
      return axios
        .get(
          urlGetTemplateRoutingDocumentSingle(
            props.dialog.dialogData.templateId
          )
        )
        .then(res => {
          res.data.routes.forEach(pushGroup)

          openGroup()

          return res
        })
    }

    const addGroup = async queueNumber => {
      return axios
        .post(urlCreateRouteDocument(), {
          name: `Група ${groupList.value.length + 1}`,
          templateId: props.dialog.dialogData.templateId,
          queueNumber,
        })
        .then(res => {
          openGroup(res.data.queueNumber, pushGroup(res.data))
        })
    }
    const deleteGroup = () => {
      return axios
        .delete(urlDeleteTemplateRouteDocument(currentGroup.group.id))
        .then(res => {
          const newCurrentGroup = {
            queueNumber: null,
            group: null,
          }
          let deletedGroupIdx
          const hasParallelGroups =
            sortedGroupList.value.find(g => {
              return g.queueNumber === currentGroup.queueNumber
            })?.groups.length > 1

          groupList.value.forEach((g, idx) => {
            if (g.id === currentGroup.group.id) {
              deletedGroupIdx = idx
            }
            if (
              !hasParallelGroups &&
              g.queueNumber > currentGroup.queueNumber
            ) {
              updateGroup(g, { queueNumber: (g.queueNumber -= 1) })
            }

            // set prev group
            if (
              !newCurrentGroup.group &&
              (g.queueNumber === currentGroup.queueNumber - 1 ||
                (g.queueNumber === currentGroup.queueNumber &&
                  g.id !== currentGroup.group.id))
            ) {
              newCurrentGroup.queueNumber = g.queueNumber
              newCurrentGroup.group = g
            }
          })

          groupList.value.splice(deletedGroupIdx, 1)

          openGroup(newCurrentGroup.queueNumber, newCurrentGroup.group)

          return res
        })
    }

    const init = () => {
      return handleAsync('initDocumentTemplateGroups', () => {
        return Promise.all([
          getTemplateRouteSingle(),
          fillDirectory([
            'userList',
            'templateRouteActions',
            'templateRouteAgreements',
          ]),
        ])
      })
    }

    init()

    return {
      routeData,
      submit,
      groupList,
      addGroup,
      deleteGroup,
      currentGroup,
      changeQueueNumber,
      sortedGroupList,
      updateGroup,
      openGroup,
    }
  },
}
</script>
