<template>
  <div class="document-page__iframe-section">
    <div v-if="documentObj" class="document-page__iframe-wrapper">
      <div
        v-if="$loading.isLoading(`preview_${documentObj.path}`)"
        class="d-flex align-center justify-center"
        style="padding-top: 200px">
        <v-progress-circular indeterminate size="50"></v-progress-circular>
      </div>
      <div v-else-if="!hasDocument" class="document-page__iframe-hidden">
        <span>Документ відсутній</span>
      </div>
      <Iframe
        v-else-if="canPreviewDoc"
        ref="documentIframe"
        :src="documentPath"
        :ext="docExt"
        :file-name="fileName"
        :style="iframeStyle" />
      <div v-else-if="!canPreviewDoc" class="document-page__iframe-hidden">
        <span class="d-block mb-3 text-center"
          >Документи типу
          <span class="document-page__iframe-ext">.{{ docExt }}</span>
          неможливо відобразити.</span
        >
        <span class="d-block text-center"
          >Типи документів доступні для відображення -
          <span class="document-page__iframe-ext"
            >.pdf, .jpg, .jpeg, .png, .gif, .docx, .xlsx</span
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Iframe from './Iframe.vue'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import {
  convertFileFormatToLowerCase,
  documentPathHandler,
} from '@/utils/helpers.js'
import { handleAsync } from 'best-modules/plugins'

export default {
  name: 'DocumentPreview',
  components: { Iframe },
  props: {
    document: { type: Object, default: null },
    zoomPercentage: {
      type: Number,
      default: 100,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore()

    const documentIframe = ref(null)
    const documentPath = ref(null)
    const hasDocument = computed(() => {
      return !!props.document?.length
    })
    const documentObj = computed(() => {
      if (hasDocument.value) {
        return props.document.find(d => d.isStamp) || props.document[0]
      } else {
        return null
      }
    })
    const docExt = computed(() => {
      if (hasDocument.value) {
        const fileExtension = documentObj.value.path.split('.').at(-1)
        return fileExtension ? fileExtension.toLowerCase() : null
      } else {
        return null
      }
    })
    const fileName = computed(() => {
      if (hasDocument.value) {
        return documentObj.value.name
      } else {
        return null
      }
    })
    const canPreviewDoc = computed(() => {
      return [
        'pdf',
        'jpg',
        'jpeg',
        'png',
        'gif',
        'docx',
        'doc',
        'xlsx',
        'xls',
      ].includes(docExt.value)
    })

    const iframeStyle = computed(() => {
      return {
        transform: `scale(${scaleFactor.value})`,
        transition: '0.3s',
        width: props.fullWidth ? '100vw' : '100%',
        height: '100%',
      }
    })

    const scaleFactor = computed(() => {
      return props.zoomPercentage / 100
    })

    watch(
      () => props.zoomPercentage,
      newZoomPercentage => {
        const scale = newZoomPercentage / 100
        documentIframe.value.style.transform = `scale(${scale})`
      }
    )

    watch(
      computed(() => {
        if (!hasDocument.value) {
          return null
        } else {
          return convertFileFormatToLowerCase(documentObj.value.path)
        }
      }),
      () => {
        if (hasDocument.value) {
          handleAsync(`preview_${documentObj.value.path}`, async () => {
            documentPath.value = await documentPathHandler(
              documentObj.value.path,
              {
                token: props.token,
              }
            )
          })
        }
      },
      {
        immediate: true,
      }
    )

    return {
      store,
      hasDocument,
      docExt,
      canPreviewDoc,
      scaleFactor,
      documentIframe,
      iframeStyle,
      documentPath,
      fileName,
      documentObj,
    }
  },
}
</script>

<style lang="scss" scoped></style>
