import { reactive, ref, Ref, UnwrapRef, nextTick } from 'vue'
import { Empty } from '@/utils/types/helpers'

type ConfirmData = {
  title?: string
  text: string
  icon?: string
}

const isActive: Ref<boolean> = ref(false)

const defaultConfirmData = {
  title: 'Увага',
  icon: 'mdi-alert-outline',
}
const confirmData: UnwrapRef<Empty<ConfirmData>> = reactive({
  title: null,
  text: null,
  icon: null,
})

const clearConfirmData = () => {
  isActive.value = false

  // wait close dialog animation
  setTimeout(() => {
    Object.assign(confirmData, { title: null, text: null, icon: null })
  }, 200)
}

const confirm = (params: ConfirmData): Promise<void> => {
  return new Promise((resolve, reject) => {
    Object.assign(confirmData, defaultConfirmData)
    Object.assign(confirmData, params)
    isActive.value = true

    nextTick(() => {
      const acceptButton = document.getElementById('confirmation-accept')
      const rejectButton = document.getElementById('confirmation-reject')

      acceptButton.addEventListener(
        'click',
        () => {
          clearConfirmData()
          resolve()
        },
        { once: true }
      )
      rejectButton.addEventListener(
        'click',
        () => {
          clearConfirmData()
          reject()
        },
        { once: true }
      )
    })
  })
}

export { confirm, isActive, confirmData, clearConfirmData }
