<template>
  <div
    class="d-flex align-center justify-center"
    :class="{ 'rounded-circle': rounded }"
    :style="{
      width: containerSize.width,
      height: containerSize.height,
      background: '#f0f0f0',
      overflow: 'hidden',
    }">
    <v-progress-circular
      v-if="$loading.isLoading(`getFile_${src}`)"
      indeterminate
      :size="loaderSize.size"
      :width="loaderSize.width"></v-progress-circular>
    <slot v-else-if="$slots.alt && (!src || !localSrc)" name="alt"></slot>
    <v-icon v-else-if="!src || !localSrc" color="grey" size="x-large"
      >mdi-image</v-icon
    >
    <v-img
      v-else
      :src="localSrc"
      :height="containerSize.height"
      :width="containerSize.width"
      inline
      cover
      :aspect-ratio="aspectRatio"
      v-bind="$attrs"></v-img>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { ref, watch, computed, Ref, ComputedRef, PropType } from 'vue'

export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String as PropType<string>,
    },
    rounded: {
      type: Boolean as PropType<boolean>,
    },
    height: {
      type: [String, Number] as PropType<string | number>,
    },
    width: {
      type: [String, Number] as PropType<string | number>,
    },
    aspectRatio: {
      type: String as PropType<'auto' | '1' | `${number}/${number}`>,
      default: '1',
    },
  },
  setup(props) {
    const store = useStore()
    const localSrc: Ref<string | null> = ref(null)

    watch(
      computed(() => props.src),
      () => {
        if (props.src) {
          store
            .dispatch('files/loadFile', {
              fileType: 'image',
              originPath: props.src,
            })
            .then(() => {
              localSrc.value = store.state.files.image[props.src]
            })
        } else {
          localSrc.value = null
        }
      },
      { immediate: true }
    )

    const loaderSize: ComputedRef<{ width: number; size: number }> = computed(
      () => {
        const MIN_SIZE: number = 20
        const MAX_SIZE: number = 70
        const SCALE_COEFF: number = 0.5

        const imageSize: number = calcImageSize()
        const loaderSize: number = Math.ceil(imageSize * SCALE_COEFF)

        return {
          width: calcLoaderWidth(imageSize),
          size: Math.max(MIN_SIZE, Math.min(MAX_SIZE, loaderSize)),
        }

        function calcImageSize(): number {
          if (props.width && props.height) {
            return Math.min(Number(props.width), Number(props.height))
          }
          if (props.width || props.height) {
            return Number(props.width) || Number(props.height)
          }
          return 20
        }
        function calcLoaderWidth(imgSize: number): number {
          switch (true) {
            case imgSize < 50:
              return 2
            case imgSize < 90:
              return 3
            case imgSize < 130:
              return 4
            case imgSize < 200:
              return 5
          }
        }
      }
    )

    type CssSize = 'auto' | `${number}px`
    const containerSize: ComputedRef<{ width: CssSize; height: CssSize }> =
      computed(() => {
        const getSameProp = (propName: 'width' | 'height') => {
          switch (propName) {
            case 'width':
              return 'height'
            case 'height':
              return 'width'
          }
        }

        const getSize = (propName: 'width' | 'height'): CssSize => {
          if (props[propName]) {
            return `${props[propName]}px`
          }
          if (props.aspectRatio === '1') {
            return `${props[getSameProp(propName)]}px`
          }
          return 'auto'
        }

        return { width: getSize('width'), height: getSize('height') }
      })

    return { localSrc, loaderSize, containerSize }
  },
}
</script>
