import moment from 'moment/moment'

type LocaleTime = `${number}:${number}`
type ISOTime = `${number}:${number}:${number}`
type LocaleDateWithoutTime = `${number}.${number}.${number}`
type ISODateWithoutTime = `${number}-${number}-${number}`

type LocaleDate =
  | LocaleDateWithoutTime
  | `${LocaleDateWithoutTime}, ${LocaleTime}`
type ISODate = ISODateWithoutTime | `${ISODateWithoutTime} ${ISOTime}`

type Format = 'locale-numeric' | 'iso'

interface ToStringParams {
  time?: boolean
  format?: Format
}

class CustomDate {
  date: moment.Moment

  constructor(date: LocaleDate | ISODate | Date) {
    if (typeof date === 'string' && date.includes('.')) {
      const [dateStr, time = '00:00'] = date.split(', ')
      const [day, month, year] = dateStr.split('.')
      this.date = moment(
        `${year}-${month}-${day} ${time}`.trim(),
        'YYYY-MM-DD HH:mm'
      )
    } else {
      this.date = date ? moment(date) : null
    }
  }

  toString(params: ToStringParams = {}): LocaleDate | ISODate {
    if (!this.date || !this.date.isValid()) return null
    const { time = false, format = 'locale-numeric' } = params

    if (format === 'locale-numeric') {
      // @ts-ignore
      return this.date.format(`DD.MM.YYYY${time ? ', HH:mm' : ''}`)
    }
    if (format === 'iso') {
      // @ts-ignore
      return this.date.format(`YYYY-MM-DD${time ? ' HH:mm:ss' : ''}`)
    }

    throw new Error('CustomDate().toString() - invalid format')
  }

  add(part: moment.DurationInputArg2, count: number): CustomDate {
    if (this.date) {
      this.date.add(count, part)
    }
    return this
  }

  remove(part: moment.DurationInputArg2, count: number): CustomDate {
    if (this.date) {
      this.date.subtract(count, part)
    }
    return this
  }
}

export { CustomDate, Format, LocaleDate, ISODate }
