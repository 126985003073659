<template>
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_481_1800)">
      <path d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z" fill="#4C535C" />
    </g>
    <defs>
      <clipPath id="clip0_481_1800">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>

</style>
