<template>
  <div>
    <v-data-table
      :items="agreementUsers"
      :headers="headers"
      :items-per-page="-1"
      color="transparent">
      <template #[`item.user`]="{ item }">
        {{ getUserName(item.user, { initials: true }) }}
      </template>
      <template #[`item.isAgreed`]="{ item }">
        <div class="font-weight-bold">
          <span v-if="item.isAgreed" class="text-success"> Погодив </span>
          <span v-else class="text-grey-darken-1"> Очікується погодження </span>
        </div>
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { PropType, ref, Ref } from 'vue'
import { Dialog } from '@/dialog'
import { DataTableHeader } from '@/utils/types/vuetify'
import { getUserName } from '@/utils/helpers.js'

export default {
  name: 'DocumentAgreementUsers',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getUserName },
  setup(props) {
    const agreementUsers: Ref<[]> = ref(
      props.dialog.dialogData.participants || []
    )
    const headers: DataTableHeader[] = [
      {
        key: 'user',
        title: 'ПІБ',
      },
      {
        key: 'agreementDate',
        title: 'Дата',
      },
      {
        key: 'isAgreed',
        title: 'Статус погодження',
      },
    ]

    return { agreementUsers, headers }
  },
}
</script>
