import AuthLayout from '@/layout/AuthLayout.vue'
import LoginPage from '@/pages/auth/LoginPage.vue'
import ForgotPasswordPage from '@/pages/auth/ForgotPasswordPage.vue'
import G2Auth from '@/pages/auth/G2Auth.vue'

export default [
  {
    path: '/',
    name: 'init',
    component: AuthLayout,
    meta: {
      title: 'eDocuments',
    },
    redirect: { name: 'login' },
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginPage,
        meta: {
          title: 'Авторизація',
        },
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordPage,
        meta: {
          title: 'Відновлення паролю',
        },
      },
      {
        path: 'auth-confirm',
        name: 'auth-confirm',
        component: G2Auth,
        meta: {
          title: 'Авторизація',
        },
      },
    ],
  },
]
