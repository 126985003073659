<template>
  <div class="overflow-x-auto">
    <div
      style="min-width: max-content"
      v-if="[...parentOrders, ...childrenOrders].length">
      <tree :tree="parentOrders" reverse></tree>
      <div class="text-center main-chip">
        <span v-if="parentOrders.length" class="chip-line-top"></span>
        <v-chip append-icon="mdi-star" color="orange">
          <span class="text-truncate" style="max-width: 200px">
            {{ order.name }}
          </span>
        </v-chip>
        <span v-if="childrenOrders.length" class="chip-line-bottom"></span>
      </div>
      <tree :tree="childrenOrders"></tree>
    </div>
    <div v-else class="text-disabled text-center">
      Пов'язані накази відсутні
    </div>
  </div>
</template>

<script lang="ts">
import Tree from '@/components/tree/index.vue'
import { useRelatedOrders } from './relatedOrders'

export default {
  name: 'RelatedOrders',
  components: { Tree },
  props: {
    order: {
      type: Object,
    },
  },
  setup(props) {
    return { ...useRelatedOrders(props.order) }
  },
}
</script>

<style scoped lang="scss"></style>
