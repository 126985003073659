import Index from '@/pages/client-access/Index.vue'
import AccessDenied from '@/pages/client-access/AccessDenied.vue'

export default [
  {
    path: '/share-document/:clientAccessId',
    name: 'share-document',
    component: Index,
    meta: {
      title: 'Intime',
    },
  },
  {
    path: '/share-document/access-denied',
    name: 'share-document-access-denied',
    component: AccessDenied,
    meta: {
      title: 'Intime',
    },
  },
]
