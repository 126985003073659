<template>
  <div>
    <div
      v-if="store.state.user.userData?.roleId === 1"
      class="d-flex justify-end">
      <v-btn @click="createUser">Створити співробітника</v-btn>
    </div>
    <Filter
      v-model="filters"
      v-model:loading="loading"
      @filter="() => pgTrigger++" />
    <v-data-table
      :disable-pagination="true"
      :headers="columns"
      :hide-default-footer="true"
      :hide-default-header="!userList.length"
      :items="userList"
      :items-per-page="-1"
      :loading="loading"
      @dblclick:row="openRow">
      <template #bottom></template>
      <template #item.isActive="{ item }">
        {{ item.isActive ? 'Активний' : 'Звільнений' }}
      </template>
      <template #item.department="{ item }">
        {{ item.department?.name || '---' }}
      </template>
      <template #item.name="{ item }">
        {{ getUserName(item) || '---' }}
      </template>
    </v-data-table>
    <Pagination :getItems="getUsers" :trigger="pgTrigger" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import Pagination from '@/components/Pagination.vue'
import Filter from '@/components/Filter.vue'
import { axios } from '@/plugins/index.js'
import { reactive, ref } from 'vue'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { urlUserList } from '@/utils/urls.js'
import { openDialog } from '@/dialog/index.ts'
import { useRouter } from 'vue-router'

export default {
  name: 'UserList',
  components: { Pagination, Filter },
  methods: { getUserName },
  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = ref(true)
    const userList = ref([])
    const columns = ref([
      {
        key: 'isActive',
        title: 'Статус',
      },
      {
        key: 'name',
        title: 'ПІБ',
        width: 250,
      },
      {
        key: 'department',
        title: 'Департамент',
      },
      { key: 'post', title: 'Посада' },
    ])
    const filters = reactive({
      search: null,
      departmentId: null,
      roleId: null,
      isActive: null,
    })

    const openRow = (e, row) => {
      navigateToItem('single-user', row.item.id)
    }

    const getUsers = page => {
      loading.value = true
      return axios.get(urlUserList({ page, filters })).then(res => {
        userList.value = res.data.data
        loading.value = false
        return res.data
      })
    }
    const pgTrigger = ref(1)

    const createUser = () => {
      openDialog({
        name: 'User',
        action: 'create',
        params: {
          cb: newUser => {
            router.push({ name: 'single-user', params: { id: newUser.id } })
          },
        },
      })
    }

    return {
      store,
      openRow,
      columns,
      userList,
      getUsers,
      navigateToItem,
      loading,
      filters,
      pgTrigger,
      createUser,
    }
  },
}
</script>
