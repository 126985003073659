<template>
  <div>
    <BaseDatePicker
      v-model:start-date="absence.startDate"
      v-model:end-date="absence.endDate"
      :minDate="new Date()"
      :error-messages="
        getErrorMessages(v$.startDate) || getErrorMessages(v$.endDate)
      "
      class="mt-2 mb-3"
      hide-details
      range
      label="Період відпустки"
      @blur="v$.startDate.$touch()" />
    <b-select
      v-model="absence.typeId"
      :error-messages="getErrorMessages(v$.typeId)"
      :items="$directory.get('absenceTypes')"
      :loading="$loading.isLoading('absenceTypes')"
      class="mb-3"
      hide-details
      item-title="name"
      item-value="id"
      label="Тип відпустки"
      @blur="v$.typeId.$touch()"></b-select>
    <b-select
      v-model="absence.deputyId"
      :error-messages="getErrorMessages(v$.deputyId)"
      :items="$directory.get('userList')"
      :loading="$loading.isLoading('userList')"
      class="mb-3"
      hide-details
      item-title="name"
      item-value="id"
      label="Замісник"
      @blur="v$.deputyId.$touch()"></b-select>
  </div>
</template>

<script lang="ts">
import { reactive, PropType } from 'vue'
import { axios, required, useVuelidate } from '@/plugins/index.js'
import { urlUserAbsenceCreate } from '@/utils/urls.js'
import { getErrorMessages } from '@/utils/helpers.js'
import { Dialog } from '@/dialog'
import { fillDirectory } from '@/directory'

export default {
  name: 'Absence',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
    userId: {
      type: Number,
    },
  },
  methods: { getErrorMessages },
  async setup(props) {
    await fillDirectory(['userList', 'absenceTypes'])

    const absence = reactive({
      userId: props.dialog.dialogData.userId,
      startDate: null,
      endDate: null,
      typeId: null,
      deputyId: null,
    })
    const rules = {
      startDate: { required },
      endDate: { required },
      typeId: { required },
      deputyId: { required },
    }

    const v$ = useVuelidate(rules, absence)

    const submit = (): Promise<void> | void => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return axios.post(urlUserAbsenceCreate(), absence).then(res => {
          return res.data
        })
      }
    }

    return { absence, v$, submit }
  },
}
</script>
