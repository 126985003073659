<template>
  <div class="fields-container grid">
    <div v-for="(f, idx) in fields" :key="idx" class="data-field">
      <span class="title">{{ f.label }}</span>
      <span v-if="f.value && f.to" class="value">
        <v-btn
          :label="f.value"
          class="link"
          variant="text"
          @click="$router.push(f.to)" />
      </span>
      <span v-else-if="f.value" class="value" @click="copyText(f.value)">
        {{ f.value }}</span
      >
      <span v-else class="value disabled">Не вказано</span>
    </div>
  </div>
</template>

<script>
import { navigateToItem } from '@/utils/helpers.js'
import { setSnackbar } from 'best-modules/plugins'

export default {
  props: {
    fields: { type: Array, required: true },
    loading: { type: Boolean },
  },
  setup() {
    const copyText = text => {
      const textarea = document.createElement('textarea')
      textarea.value = text
      textarea.select()
      document.execCommand('copy')
      setSnackbar({ text: 'Текст скопійовано' })
    }
    return { copyText, navigateToItem }
  },
}
</script>

<style lang="scss" scoped>
.grid {
  max-width: 60em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 0.5rem;
}

.data-field {
  & > .title {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
    cursor: default;
  }

  & > .value {
    display: inline-block;
    font-size: 0.9rem;
    color: var(--gray-700);
    margin: 5px 0 0 0;
    user-select: all;
  }
}
</style>
