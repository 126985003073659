<template>
  <div>
    <v-row>
      <v-col class="mb-5" cols="12" md="12" sm="12">
        <div class="grey-block mb-5">
          <span class="label">
            Перед копіюванням задачі, будь ласка, оберіть виконавця
          </span>
        </div>
        <b-select
          v-model="data.executorId"
          :error-messages="getErrorMessages(v$.executorId)"
          :items="$directory.get('userList')"
          :loading="$loading.isLoading('userList')"
          hide-details
          item-title="name"
          item-value="id"
          label="Виконавець"
          placeholder="Оберіть зі списку"
          @blur="v$.executorId.$touch()" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory } from '@/directory'
import { axios, required, useVuelidate } from '@/plugins'
import { urlTaskCopy } from '@/utils/urls'
import { setSnackbar } from 'best-modules/plugins/index'

export default {
  name: 'CopyTask',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  async setup(props) {
    await fillDirectory(['userList'])
    const data = reactive({
      executorId: null,
    })

    const v$ = useVuelidate(
      {
        executorId: { required },
      },
      data
    )

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return axios
          .post(urlTaskCopy(props.dialog.dialogData.taskId), {
            executorId: data?.executorId,
          })
          .then(res => {
            setSnackbar({ text: 'Завдання скопійовано', color: 'success' })
            setSnackbar({
              text: 'Вас перенаправлено до скопіюваного завдання',
              color: 'warning',
            })
            return res.data
          })
      }
    }

    return {
      v$,
      data,
      submit,
    }
  },
}
</script>

<style lang="scss" scoped></style>
