<template>
  <div>
    <v-hover>
      <template #default="{ props, isHovering }">
        <div class="user-photo-wrapper" v-bind="props">
          <BaseImage
            :src="user.photo"
            alt="photo"
            class="user-photo"
            rounded
            width="126"></BaseImage>
          <v-fade-transition>
            <v-btn
              v-show="isHovering && !readonly"
              :disabled="$loading.isLoading(`getFile_${modelValue}`)"
              class="user-photo-edit"
              icon
              size="x-small"
              @click="openSelectPhotoDialog">
              <v-icon>{{ 'mdi-pencil' }}</v-icon>
            </v-btn>
          </v-fade-transition>
        </div>
      </template>
    </v-hover>
  </div>
</template>

<script>
import { openDialog } from '@/dialog/index.ts'

export default {
  name: 'UserPhoto',
  emits: ['update:model-value'],
  props: {
    modelValue: { type: [Object, String, Number] },
    user: { type: Object },
    v$: { type: Object },
    readonly: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const openSelectPhotoDialog = () => {
      openDialog({
        name: 'UserPhoto',
        dialogData: { user: props.user },
        params: {
          hideActionButtons: true,
          cb: photo => {
            emit('update:model-value', photo)
          },
        },
      })
    }

    return {
      openSelectPhotoDialog,
    }
  },
}
</script>

<style lang="scss">
.user-photo {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    position: relative;
  }

  &-edit {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}
</style>
