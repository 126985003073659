<template>
  <div v-if="value" class="mb-1 text-wrap">
    <span class="d-inline-block font-weight-bold mr-1">{{ title }}:</span>
    <span>{{ value }}</span>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SignatureInfoValue',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss"></style>
