<template>
  <div>
    <b-skeleton-loader :loading="$loading.isLoading('getCorrespondence')">
      <router-view
        v-if="route.name === 'correspondence-create-task'"
        :task-data="taskData"></router-view>
      <b-form
        v-else
        :actions-disabled="!correspondence.$hasChanges"
        :reject="() => correspondence.$reset()"
        :submit="{ title: 'Зберегти', handler: updateCorrespondence }">
        <div class="d-flex justify-space-between align-center flex-wrap">
          <v-tabs
            class="mb-5"
            hide-slider
            style="border-bottom: 1px solid #e0e4e7">
            <v-tab
              replace
              :to="{
                name: 'correspondence-general-info',
                params: { id: $route.params.id },
              }">
              Загальна інформація
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'correspondence-additional-info',
                params: { id: $route.params.id },
              }">
              {{
                correspondence.typeId === 2
                  ? 'Параметри відправлення'
                  : 'Додаткова інформація'
              }}
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'correspondence-tasks',
                params: { id: $route.params.id },
              }">
              Завдання
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'correspondence-history',
                params: { id: $route.params.id },
              }">
              Історія
            </v-tab>
          </v-tabs>

          <div class="d-flex align-center">
            <div>
              <b>Статус</b>: {{ correspondence.status?.name || '---' }}
              <span v-if="correspondence.deliveryDate" class="text-disabled">
                ({{ correspondence.deliveryDate }})
              </span>
            </div>
            <div>
              <v-btn
                class="ml-3"
                style="min-width: 13rem"
                variant="outlined"
                @click="createCorrespondenceTask">
                Створити доручення
                <v-icon class="ml-2">{{ 'mdi-plus' }}</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <router-view></router-view>
      </b-form>
    </b-skeleton-loader>
  </div>
</template>

<script>
import {
  urlCorrespondenceSingle,
  urlCorrespondenceUpdate,
} from '@/utils/urls.js'
import axios from '@/plugins/axios/index.js'
import { BForm, BSkeletonLoader } from 'best-modules/components'
import { computed } from 'vue'
import { addressList, correspondence, v$ } from './correspondence.ts'
import { handleAsync } from 'best-modules/plugins'
import { useRoute } from 'vue-router'
import { getErrorMessages } from 'best-modules/utils'
import router from '@/router'
import { navigateToItem } from '@/utils/helpers'
import { useBreadcrumb } from '@/plugins'

export default {
  name: 'CorrespondenceSingle',
  components: { BForm, BSkeletonLoader },
  methods: { navigateToItem, getErrorMessages },
  setup() {
    const route = useRoute()
    const breadcrumb = useBreadcrumb()

    const correspondenceId = computed(() => route.params.id)

    const createCorrespondenceTask = () => {
      router.push({
        name: 'correspondence-create-task',
        params: {
          typeId: 3,
          entityTypeId: 4,
        },
        query: {
          entityId: +route.params.id,
        },
      })
    }
    const taskData = computed(() => {
      return {
        name: correspondence.subject || correspondence.registrationNumber,
        id: correspondence.id,
        document: correspondence.documents,
        contractorId: correspondence.contractor?.id,
      }
    })

    const getCorrespondence = () => {
      return handleAsync('getCorrespondence', () => {
        return axios
          .get(urlCorrespondenceSingle(correspondenceId.value))
          .then(res => {
            correspondence.$set(res.data)
            res.data.address && addressList.value.push(res.data.address)
            breadcrumb.set([
              {
                title: correspondence.registrationNumber,
                index: 1,
              },
            ])
          })
      })
    }

    const updateCorrespondence = () => {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        return axios
          .post(urlCorrespondenceUpdate(correspondenceId.value), {
            ...correspondence.$object,
            contractorId: correspondence.contractor?.id,
          })
          .then(res => {
            correspondence.$set(res.data)
          })
      }
    }

    getCorrespondence()

    return {
      correspondence,
      v$,
      route,
      taskData,
      updateCorrespondence,
      createCorrespondenceTask,
    }
  },
}
</script>
