<template>
  <div class="mb-3 mt-1">
    <v-btn class="w-100 mb-3" @click="createClientAccess" variant="outlined">
      <v-icon class="mr-3">mdi-plus</v-icon>
      Надати доступ клієнту
    </v-btn>
    <div v-if="document.client_accesses?.length">
      <div v-for="access in document.client_accesses" :key="access.id">
        <v-btn
          class="mr-3"
          variant="plain"
          density="compact"
          icon="mdi-eye"
          @click="showClientAccessInfo(access)"></v-btn>
        <span>Дійсний до: {{ access.endDate }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { openDialog } from '@/dialog'

export default {
  name: 'ClientAccess',
  emits: ['create-access', 'delete-access'],
  props: {
    document: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const createClientAccess = () => {
      openDialog({
        name: 'ClientAccess',
        action: 'create',
        dialogData: {
          document: props.document,
          access: {
            documentId: props.document.id,
          },
          createHandler: access => {
            emit('create-access', access)
          },
          removeHandler: access => {
            emit('delete-access', access.id)
          },
        },
        params: {
          hideActionButtons: true,
        },
      })
    }
    const showClientAccessInfo = access => {
      openDialog({
        name: 'ClientAccess',
        action: 'preview',
        dialogData: {
          document: props.document,
          access,
          removeHandler: a => {
            emit('delete-access', a.id)
          },
        },
        params: {
          hideActionButtons: true,
        },
      })
    }
    return { createClientAccess, showClientAccessInfo }
  },
}
</script>

<style scoped lang="scss"></style>
