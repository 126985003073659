import { ref, watch } from 'vue'

function getBreadcrumbInstance() {
  const items = ref([])
  const clearItems = () => items.value.splice(0)

  const routesTrigger = ref(1)
  const routes = ref([])
  const setRoutes = r => {
    routesTrigger.value += 1
    routes.value.splice(0)
    routes.value.push(...r)
  }
  watch(
    () => routesTrigger.value,
    () => {
      clearItems()
      items.value.push(
        ...routes.value
          .filter(r => r.name !== 'eds' && r.meta.title)
          .map(r => {
            return {
              title: r.meta.title,
              to: { name: r.name },
            }
          })
      )
    }
  )

  const set = (customItems, params = { type: 'edit' }) => {
    setTimeout(() => {
      if (!customItems) return
      if (params.type === 'replace') clearItems()
      customItems.forEach(item => {
        if (items.value[item.index]) {
          Object.assign(items.value[item.index], item)
        } else {
          items.value.push(item)
        }
      })
    }, 0)
  }

  return { set, items, setRoutes }
}

const breadcrumbInstance = getBreadcrumbInstance()

const breadcrumbPlugin = {
  install(app) {
    app.config.globalProperties.$setBreadcrumb = breadcrumbInstance.set
  },
}
const useBreadcrumb = () => {
  return breadcrumbInstance
}

export { breadcrumbPlugin, useBreadcrumb }
