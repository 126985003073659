<template>
  <div>
    <b-autocomplete
      v-model="newUser.userId"
      :error-messages="getErrorMessages(v$.userId)"
      :items="allUsers"
      autocompleteStyle="width: 100%"
      class="mb-4"
      item-title="name"
      item-value="id"
      placeholder="оберіть користувача"
      @blur="v$.userId.$touch()" />
  </div>
</template>

<script>
import { urlChangeUser } from '@/utils/urls.js'
import { axios, required, useStore, useVuelidate } from '@/plugins/index.js'
import { computed, reactive } from 'vue'
import { getErrorMessages } from '@/utils/helpers.js'
import { setSnackbar } from 'best-modules/plugins'
import { fillDirectory, getDirectory } from '@/directory/index.ts'

export default {
  name: 'ChangeUser',
  methods: { getErrorMessages },
  async setup() {
    const store = useStore()

    await fillDirectory('userList')

    const currentUser = computed(() => store.state.user.userData)
    const allUsers = computed(() => {
      return getDirectory('userList').filter(u => {
        return u.id !== currentUser.value.id
      })
    })

    const newUser = reactive({
      userId: null,
    })

    const rules = computed(() => {
      return {
        userId: { required },
      }
    })

    const v$ = useVuelidate(rules, newUser)

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$error) {
        return axios.get(urlChangeUser(newUser.userId)).then(res => {
          store.commit('user/setUserData', res.data)
          setSnackbar({ text: 'Користувача успішно змінено' })

          return res.data
        })
      }
    }

    return {
      v$,
      submit,
      newUser,
      allUsers,
    }
  },
}
</script>

<style scoped></style>
