const regex = {
  ru_only: /[а-яА-ЯєіїЄІЇ]/, // only cyrillic letters
  en_only: /[a-zA-Z]/, // only english letters
  ru_not: /[^а-яёА-ЯЁєіїЄІЇ]/, // any symbols expect cyrillic
  number_only: /[0-9]/,
}

const phoneMask = {
  mask: '+38 (0N#) ### - ## - ##',
  tokens: {
    N: {
      pattern: /[1-9]/,
    },
  },
}

const innMask = {
  mask: '##########',
}

const emailMask = {
  mask: '@'.repeat(100),
  tokens: {
    '@': { pattern: regex.ru_not },
  },
}

const smsCode = {
  mask: '#####',
}

const dateMask = (time, range) => {
  const dateMask = `d#.M#.y###${time ? ', h#:m#' : ''}`
  return {
    mask: range ? [dateMask, dateMask].join(' - ') : dateMask,
    tokens: {
      d: { pattern: /[0-3]/ },
      M: { pattern: /[0-1]/ },
      y: { pattern: /[1-2]/ },
      h: { pattern: /[0-2]/ },
      m: { pattern: /[0-5]/ },
    },
  }
}

export { phoneMask, innMask, emailMask, smsCode, dateMask }
