<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn class="mb-3" @click="templateRoutingDocumentDialog('create')"
        >Створити шаблон
      </v-btn>
    </div>
    <v-data-table
      :disable-pagination="true"
      :headers="columns"
      :hide-default-footer="true"
      :hide-default-header="!templateList.length"
      :items="templateList"
      :items-per-page="-1"
      :loading="loading">
      <template #bottom></template>
      <template #item.name="{ item }">
        {{ item?.name || '---' }}
      </template>
      <template #item.document_type="{ item }">
        {{ item.document_type?.name || 'Не визначено' }}
      </template>

      <template #item.actions="{ item }">
        <TableActions
          :actions="[
            {
              label: 'Редагувати шаблон',
              action: () => templateRoutingDocumentDialog('update', item),
              icon: 'mdi-pencil',
            },
            {
              label: 'Налаштувати маршрути',
              action: () => openRouteDialog('update', item),
              icon: 'mdi-pencil',
            },
            {
              label: 'Видалити',
              action: () => templateRoutingDocumentDelete(item.id),
              icon: 'mdi-delete',
            },
          ]" />
      </template>
    </v-data-table>
    <Pagination :getItems="getTemplateDocuments" :trigger="pgTrigger" />
  </div>
</template>
<script>
import Pagination from '@/components/Pagination.vue'
import { ref } from 'vue'
import { axios } from '@/plugins'
import {
  urlDeleteTemplateRoutingDocument,
  urlGetTemplateRoutingDocuments,
} from '@/utils/urls'
import TableActions from '@/components/TableActions.vue'
import { navigateToItem } from '@/utils/helpers'
import { openDialog } from '@/dialog'

export default {
  name: 'TemplateRoutingDocumentList',
  components: { TableActions, Pagination },
  methods: { navigateToItem },
  setup() {
    const templateList = ref([])
    const loading = ref(true)
    const columns = ref([
      {
        key: 'name',
        title: 'Назва',
      },
      {
        key: 'document_type',
        title: 'Тип документу',
      },
      {
        key: 'actions',
        title: 'Дії',
        sortable: false,
        align: 'end',
      },
    ])

    const openRouteDialog = (action, item) => {
      openDialog({
        name: 'TemplateGroup',
        action: 'update',
        dialogData: { templateId: item.id },
        params: {
          hideActionButtons: true,
        },
      })
    }

    const getTemplateDocuments = page => {
      loading.value = true
      return axios.get(urlGetTemplateRoutingDocuments({ page })).then(res => {
        templateList.value = res.data.data
        loading.value = false
        return res.data
      })
    }
    const pgTrigger = ref(1)

    const templateRoutingDocumentDialog = (action, item) => {
      openDialog({
        name: 'TemplateRoutingDocument',
        action: action === 'update' ? 'update' : 'create',
        params: {
          title: `${action === 'update' ? 'Редагування' : 'Створення'} шаблону`,
          submitLabel: `${action === 'update' ? 'Змінити' : 'Створити'}`,
          cb: data => {
            if (data && action === 'create') {
              templateList.value.push(data)
            } else if (data && action === 'update') {
              const indexToUpdate = templateList.value.findIndex(
                item => item.id === data.id
              )

              if (indexToUpdate !== -1) {
                templateList.value[indexToUpdate] = {
                  ...templateList.value[indexToUpdate],
                  ...data,
                }
              }
            }
          },
        },
        dialogData: {
          documentData: action === 'update' ? item : {},
          isUpdateTemplate: action === 'update',
        },
      })
    }
    const templateRoutingDocumentDelete = id => {
      return axios.delete(urlDeleteTemplateRoutingDocument(id)).then(res => {
        templateList.value = templateList.value.filter(item => item.id !== id)
        return res
      })
    }

    return {
      templateList,
      columns,
      getTemplateDocuments,
      templateRoutingDocumentDialog,
      templateRoutingDocumentDelete,
      pgTrigger,
      openRouteDialog,
      loading,
    }
  },
}
</script>

<style lang="scss" scoped></style>
