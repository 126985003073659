import { computed, ComputedRef, inject, Ref, ref } from 'vue'
import { omit } from 'lodash'

type InputParams = {
  setValueHandler?: (_v) => any
  getValueHandler?: (_v) => any
}

function useInput(props, { emit, attrs }, params: InputParams = {}) {
  const { setValueHandler = v => v, getValueHandler = v => v } = params

  const value = computed({
    get: () => getValueHandler(props.modelValue),
    set: v => {
      emit('update:model-value', setValueHandler(v))
    },
  })

  const readonly: Ref<boolean> | ComputedRef<boolean> = inject(
    'readonly',
    ref(false)
  )

  const inputProps = computed(() => {
    return { readonly: readonly?.value, ...omit(attrs, ['label']) }
  })

  return { value, inputProps }
}

export { useInput }
