<template>
  <div>
    <ImageCropper
      v-if="uploadedPhoto"
      :image="uploadedPhoto"
      class="mb-4"
      :loading="$loading.isLoading('uploadFile_Пользователи_фото')"
      @change="editPhotoHandler" />
    <div v-if="!uploadedPhoto">
      <FileDropper
        :select-options="{
          multiple: false,
          accept: ['.png', '.jpg', '.jpeg', '.jpg', '.cvg'],
        }"
        dropper
        @select="uploadedPhoto = $event.path[0]" />
    </div>
  </div>
</template>

<script lang="ts">
import { Ref, ref } from 'vue'
import ImageCropper from '@/components/ImageCropper.vue'
import FileDropper from '@/components/filesUploader/FileDropper.vue'
import { uploadFiles } from '@/components/filesUploader/uploadFiles'

export default {
  name: 'SelectPhoto',
  components: { ImageCropper, FileDropper },
  emits: ['select'],
  props: {
    user: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const uploadedPhoto: Ref<string | null> = ref(null)

    const editPhotoHandler = (blob: Blob | null) => {
      if (!blob) {
        emit('select', null)
      }
      return uploadFiles({
        files: blob,
        mainFolder: 'Пользователи',
        nameFolder: props.user.secondName,
      }).then(res => {
        emit('select', res.paths[0])
      })
    }

    return { uploadedPhoto, editPhotoHandler }
  },
}
</script>
