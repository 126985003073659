<template>
  <div>
    <v-row>
      <v-col cols="12">
        <b-select
          v-model="correspondence.typeId"
          :error-messages="getErrorMessages(v$.typeId)"
          :items="$directory.get('correspondenceTypes')"
          :loading="$loading.isLoading('correspondenceTypes')"
          hide-details
          label="Тип кореспонденції"
          placeholder="оберіть тип"
          @blur="v$.typeId.$touch()"></b-select>
      </v-col>
      <v-col cols="6">
        <b-input
          v-model="correspondence.registrationNumber"
          :mask="'#'.repeat(7)"
          label="Реєстраційний номер"
          placeholder="введіть номер реєстрації">
        </b-input>
      </v-col>
      <v-col cols="6">
        <base-date-picker
          v-model="correspondence.registrationDate"
          :error-messages="getErrorMessages(v$.registrationDate)"
          label="Дата реєстрації"
          timePicker
          @blur="v$.registrationDate.$touch()"></base-date-picker>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { urlCorrespondenceCreate } from '@/utils/urls.js'
import { fillDirectory } from '@/directory/index.ts'
import { getErrorMessages } from 'best-modules/utils'
import axios from '@/plugins/axios'
import { reactive } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getCurrentDate } from '@/utils/helpers'

export default {
  name: 'Correspondence',
  components: { BaseDatePicker },
  methods: { getErrorMessages },
  setup() {
    fillDirectory(['correspondenceTypes'])
    const correspondence = reactive({
      typeId: null,
      registrationNumber: null,
      registrationDate: getCurrentDate(),
    })

    const rules = {
      typeId: { required },
      registrationDate: { required },
    }

    const v$ = useVuelidate(rules, correspondence)

    const submit = () => {
      return axios.post(urlCorrespondenceCreate(), correspondence)
    }

    return { correspondence, v$, submit }
  },
}
</script>
