<script lang="ts" setup>

</script>

<template>
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_521_344)">
      <path clip-rule="evenodd" d="M20 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3ZM10 17H5V15H10V17ZM10 13H5V11H10V13ZM10 9H5V7H10V9ZM14.82 15L12 12.16L13.41 10.75L14.82 12.17L17.99 9L19.41 10.42L14.82 15Z"
            fill="#4C535C"
            fill-rule="evenodd" />
    </g>
    <defs>
      <clipPath id="clip0_521_344">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>

</style>
