<template>
  <div>
    <b-form
      :actions-disabled="!contractor.$hasChanges"
      :reject="
        () => {
          contractor.$reset()
        }
      "
      :submit="{ title: 'Зберегти', handler: updateContractor }">
      <v-row>
        <v-col cols="4">
          <b-input
            v-model="contractorData.name"
            :error-messages="getErrorMessages(v$.name)"
            label="Повна назва"
            @blur="v$.name.$touch()"></b-input>
        </v-col>
        <v-col cols="4">
          <b-input
            v-model="contractorData.shortName"
            :error-messages="getErrorMessages(v$.shortName)"
            label="Коротка назва"
            @blur="v$.shortName.$touch()"></b-input>
        </v-col>
        <v-col cols="4">
          <b-input
            v-model="contractorData.code"
            :error-messages="getErrorMessages(v$.code)"
            :mask="'#'.repeat(10)"
            label="ЄДРПОУ/ІПН"
            @blur="v$.code.$touch()"></b-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <b-select
            v-model="contractorData.typeId"
            :error-messages="getErrorMessages(v$.typeId)"
            :items="$directory.get('contractorTypes', contractorData.type)"
            :loading="$loading.isLoading('contractorTypes')"
            label="Тип"
            placeholder="оберіть тип"
            @blur="v$.typeId.$touch()"
            @focus="$directory.fill('contractorTypes')"></b-select>
        </v-col>
        <v-col cols="4">
          <v-scroll-y-transition>
            <b-input
              v-show="contractorData.typeId === 2"
              v-model="contractorData.stateRegistrationNumber"
              :mask="'#'.repeat(20)"
              label="Номер реєстрації"></b-input>
          </v-scroll-y-transition>
        </v-col>
        <v-col cols="4">
          <v-scroll-y-transition>
            <base-date-picker
              v-show="contractorData.typeId === 2"
              v-model="contractorData.stateRegistrationDate"
              label="Дата реєстрації"></base-date-picker>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <b-select
            v-model="contractorData.directorId"
            :items="
              $directory
                .get('contractorList', contractorData.director)
                .filter(c => c.typeId === 1)
            "
            :loading="$loading.isLoading('contractorList')"
            label="Директор"
            placeholder="оберіть директора"
            @focus="$directory.fill('contractorList')"></b-select>
        </v-col>
      </v-row>
      <contact
        :contacts="contractor.addresses"
        :contractorId="contractor.id"
        type="address"></contact>
      <contact
        :contacts="contractor.phones"
        :contractorId="contractor.id"
        type="phone"></contact>
      <contact
        :contacts="contractor.emails"
        :contractorId="contractor.id"
        type="email"></contact>
    </b-form>
  </div>
</template>

<script>
import { computed } from 'vue'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { getErrorMessages } from 'best-modules/utils'
import Contact from '../Contact.vue'
import { BForm } from 'best-modules/components'

export default {
  components: { BForm, BaseDatePicker, Contact },
  emits: ['update:contractor'],
  props: {
    contractor: { type: Object },
    updateContractor: { type: Function },
    v$: { type: Object },
  },
  methods: { getErrorMessages },
  setup(props, { emit }) {
    const contractorData = computed({
      get() {
        return props.contractor
      },
      set(v) {
        emit('update:contractor', v)
      },
    })

    return { contractorData }
  },
}
</script>

<style lang="scss" scoped></style>
