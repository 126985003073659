import axios from 'axios'
import store from '@/store/index.ts'
import router from '@/router'
import { setData } from '@/plugins/axios/helpers.js'
import { setSnackbar } from 'best-modules/plugins'

function setInterceptor(/* crmRoute */) {
  axios.interceptors.request.use(
    // before request was send
    req => {
      const token = store.state.auth.token

      if (
        !req.headers['Authorization'] &&
        token &&
        (req.url.startsWith('/') || req.url.startsWith(location.origin))
      ) {
        req.headers['Authorization'] = `Bearer ${token}`
      }

      req.method === 'post' &&
        !req.headers.DontFormatting &&
        setData(req, 'request')

      return req
    }, // request err
    err => {
      return Promise.reject(err)
    }
  )

  axios.interceptors.response.use(
    // any status in range 200 trigger res
    res => {
      if (res.data.constructor.name === 'Object') {
        setData(res, 'response')
      }
      if (res.data.message && !res.config.headers.DontNotify) {
        setSnackbar({ text: res.data.message || 'worked' })
      }
      return res
    }, // any status outside range 200 trigger res error
    err => {
      const initRoute = new URL(location.href).origin
      const curRoute = location.href
      const unAuthRoutes = [
        initRoute,
        `${initRoute}/`,
        `${initRoute}/authorization`,
      ]

      console.log('Request Error', err)

      if (err.response?.data?.message) {
        setSnackbar({
          text: err.response.data.message,
          color: 'error',
        })
      }

      if (err.response?.status === 401 && !unAuthRoutes.includes(curRoute)) {
        store.commit('removeToken')
        router.push({ name: 'login' })
      }

      return Promise.reject({ ...err, isAxios: true })
    }
  )
  return axios
}

export default setInterceptor()
