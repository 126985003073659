<template>
  <div>
    <b-autocomplete
      v-model="department.departmentId"
      :error-messages="getErrorMessages(v$)"
      :items="$directory.get('departmentList')"
      :loading="$loading.isLoading('departmentList')"
      class="mb-3"
      placeholder="Оберіть зі списку"
      @blur="v$.$touch()"></b-autocomplete>
  </div>
</template>

<script lang="ts">
import { fillDirectory } from '@/directory'
import { reactive, defineComponent } from 'vue'
import { axios, required, useVuelidate } from '@/plugins/index.js'
import { urlUserChangeDepartment } from '@/utils/urls.js'
import { getErrorMessages } from '@/utils/helpers.js'

export default defineComponent({
  name: 'ChangeDepartment',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  async setup(props) {
    await fillDirectory('departmentList')

    const department = reactive({
      departmentId: props?.dialog?.dialogData?.departmentId || null,
    })
    const rules = {
      departmentId: { required },
    }
    const v$ = useVuelidate(rules, department)
    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return axios
          .post(
            urlUserChangeDepartment(props.dialog.dialogData.userId),
            department
          )
          .then(res => {
            return res.data.department
          })
      }
    }

    return {
      department,
      v$,
      submit,
    }
  },
})
</script>
