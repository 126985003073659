<template>
  <div class="attachment">
    <slot v-if="$slots.label" name="label"></slot>
    <span v-else class="font-weight-bold">{{ label }}:</span>
    <div class="mt-3">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String },
  },
}
</script>

<style lang="scss" scoped>
.attachment {
  border: 1px solid #49a2f4;
  border-radius: 8px;
  padding: 20px 16px;
}
</style>
