<template>
  <div v-if="user.roleId">
    <component :is="layout"></component>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import DefaultLayout from './DefaultLayout.vue'
import DocumentLayout from './DocumentLayout.vue'

export default {
  name: 'Index',
  components: { DefaultLayout, DocumentLayout },
  setup() {
    const store = useStore()
    const user = computed(() => store.state.user.userData || {})

    const layout = computed(() => {
      // eslint-disable-next-line no-constant-condition
      return [4, 1].includes(user.value?.roleId)
        ? 'DefaultLayout'
        : 'DocumentLayout'
    })

    return { layout, user }
  },
}
</script>
