<template>
  <Dialog />
  <b-snackbar />
  <Confirm />
  <RouterView />
</template>

<script>
import Dialog from '@/dialog/dialog.vue'
import { BSnackbar } from 'best-modules/plugins'
import Confirm from '@/plugins/confirm/Confirm.vue'

import { useBreadcrumb, useRoute } from '@/plugins'

import { onErrorCaptured, watch } from 'vue'

export default {
  components: { Dialog, BSnackbar, Confirm },
  setup() {
    const route = useRoute()
    const breadcrumb = useBreadcrumb()

    onErrorCaptured(err => {
      console.info(err)
    })

    // set breadcrumb
    watch(
      () => route.matched.find(r => r.meta.tabList)?.name || route.name,
      () => {
        breadcrumb.setRoutes(route.matched)
      }
    )
    // set breadcrumb part
    watch(
      () => route.matched.find(r => r.meta.breadcrumbIdx),
      r => {
        if (r) {
          breadcrumb.set([{ title: r.meta.title, index: r.meta.breadcrumbIdx }])
        }
      }
    )
  },
}
</script>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}
</style>
