<template>
  <div class="auth__container">
    <div class="auth__background">
      <div class="auth__logo">
        <NewLogo />
      </div>
      <div class="auth__text">
        <span class="auth__text__title">{{ title }}</span>
        <span class="auth__text__subtitle">{{ subtitle }}</span>
      </div>
    </div>
    <AuthFigurePicture class="auth__figure" />
    <div class="auth__form">
      <div>
        <slot name="default" v-if="$slots.default"></slot>
        <RouterView v-else />
      </div>
    </div>
  </div>
</template>

<script>
import AuthFigurePicture from '@/assets/images/AuthFigurePicture.vue'
import NewLogo from '@/components/icon/icons/new-logo.vue'

export default {
  name: 'AuthLayout',
  components: {
    AuthFigurePicture,
    NewLogo,
  },
  props: {
    title: {
      type: String,
      default: 'Авторизація в особистий кабінет',
    },
    subtitle: {
      type: String,
      default: 'Будь ласка, введіть свої дані для входу',
    },
  },
}
</script>

<style lang="scss" scoped>
.auth {
  &__container {
    height: 100vh;
  }

  &__background {
    height: 50vh;
    background: #0f6ac1;
    display: flex;
    flex-direction: column;
    padding: 40px;
  }

  &__figure {
    position: absolute;
    bottom: 10%;
    left: 10%;
  }

  &__text {
    margin-top: 60px;
    cursor: default;
    width: 400px;

    &__title {
      font-size: 34px;
      font-weight: 700;
      color: #fff;
      display: block;
      margin-bottom: 25px;
    }

    &__subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      display: block;
    }
  }

  &__form {
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
    width: 520px;
    position: absolute;
    right: 40px;
    top: 15%;
    background: #fff;
    padding: 28px 24px;
    border-radius: 12px;
  }
}
</style>
