<template>
  <div>
    <div :class="{ 'tree-reverse': reverse }" class="tree">
      <item v-for="item in tree" :key="item.id" :item="item"></item>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import Item from './item.vue'

export interface TreeItem {
  id: number
  name: string
  children?: TreeItem[]
}

export default {
  name: 'Index',
  components: { Item },
  props: {
    tree: {
      type: Array as PropType<TreeItem[]>,
      required: true,
    },
    reverse: {
      type: Boolean,
    },
  },
}
</script>
