<template>
  <div>
    <v-data-table
      v-if="tasks.length"
      :disable-pagination="true"
      :headers="headers"
      :hide-default-footer="true"
      :hide-default-header="!tasks.length"
      :items="tasks"
      :items-per-page="-1"
      dense
      @dblclick:row="openRow">
      <template #bottom></template>
      <template #item.responsible="{ item }">
        {{ getUserName(item.author, { initials: true }) }}
        {{ ` (${item.author?.post || 'Посада відсутня'})` }}
      </template>
      <template #item.actions="{ item }">
        <TableActions
          :actions="[
            {
              label: 'Відкріпити задачу',
              action: () => removeTask(item.id),
              icon: 'mdi-delete',
            },
          ]" />
      </template>
    </v-data-table>
    <div v-else class="text-center text-disabled">Дані відсутні</div>
  </div>
</template>

<script lang="ts">
import { axios } from '@/plugins'
import { correspondence } from '../correspondence'
import TableActions from '@/components/TableActions.vue'
import { urlTaskUpdate } from '@/utils/urls'
import { setSnackbar } from 'best-modules/plugins/index'
import { ref } from 'vue'
import { getUserName, navigateToItem } from '@/utils/helpers'

export default {
  name: 'Tasks',
  components: { TableActions },
  data: () => ({
    headers: [
      {
        key: 'status.name',
        title: 'Статус',
        align: 'start',
      },
      { key: 'type.name', title: 'Тип', align: 'start' },
      { key: 'topic', title: 'Назва', align: 'start' },
      { key: 'responsible', title: 'Відповідальний', align: 'start' },
      { key: 'deadline', title: 'Термін виконання', align: 'start' },
      { key: 'actions', title: 'Дії', align: 'end' },
    ],
  }),
  methods: { getUserName },
  setup() {
    const tasks = ref(correspondence.tasks || [])
    const removeTask = taskId => {
      return axios
        .post(urlTaskUpdate(taskId), {
          entityId: null,
          entityTypeId: null,
        })
        .then(res => {
          setSnackbar({
            color: 'success',
            text: 'Задача успішно відкріплена',
          })
          const filteredTasks = tasks.value.filter(item => item.id !== taskId)
          tasks.value = filteredTasks
          return res
        })
    }
    const openRow = (e, row) => {
      navigateToItem('single-task', row.item.id)
    }
    return {
      correspondence,
      removeTask,
      tasks,
      openRow,
    }
  },
}
</script>
