<template>
  <iframe
    :src="url"
    :style="iframeStyle"
    frameborder="0"
    height="100%"
    width="100%"></iframe>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'Iframe',
  props: {
    src: {
      type: String,
    },
    ext: {
      type: String,
    },
    fileName: {
      type: String,
    },
    iframeStyle: {
      type: [Object, String],
    },
  },
  setup(props) {
    const url = computed(() => {
      if (props.ext === 'pdf') {
        const dataUrl = encodeURIComponent(`${props.src}#${props.fileName}`)
        return `/pdfviewer/web/viewer.html?file=${dataUrl}#locale=uk`
      } else {
        return props.src
      }
    })

    return { url }
  },
}
</script>
