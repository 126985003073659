import { defineDirectory, DirectoryInstance } from 'best-modules/plugins'
import { DirectoryName } from './types'

const {
  DirectoryPlugin,
  fillDirectory,
  getDirectory,
  pushDirectoryItem,
  updateDirectoryItem,
  removeDirectoryItem,
  clearDirectory,
} = defineDirectory<DirectoryName>({
  contractorTypes: '/json/list/contractorTypes',
  addressTypes: '/json/list/addressTypes',
  phoneTypes: '/json/list/phoneTypes',
  emailTypes: '/json/list/emailTypes',
  contractorList: '/json/contractor?paginate=false',
  correspondenceTypes: '/json/list/correspondenceTypes',
  correspondenceDocumentTypes: '/json/list/correspondenceDocumentTypes',
  correspondencePriorityTypes: '/json/list/correspondenceDocumentPriorityTypes',
  correspondenceDeliveryTypes: '/json/list/correspondenceDocumentDeliveryTypes',
  correspondenceStatuses: '/json/list/correspondenceStatuses',
  departmentList: '/json/department',
  userList: {
    url: '/json/list/users',
    beforeSet: users => {
      return Array.from(users).sort((a, b) => {
        return a.name.localeCompare(b.name, 'uk-UA')
      })
    },
  },
  orderTypes: '/json/list/orderTypes',
  orderDirections: '/json/list/orderDirections',
  roles: '/json/list/roles',
  genders: '/json/list/genders',
  taskStatuses: '/json/list/taskStatuses',
  documentStatuses: {
    url: '/json/list/documentStatuses',
  },
  documentTypes: '/json/list/documentTypes',
  entityList: {
    url: '/json/list/entities',
    indexedKey: 'name',
  },
  absenceTypes: '/json/list/userAbsenceTypes',
  templateRouteAgreements: '/json/list/documentTemplateRouteAgreements',
  templateRouteActions: '/json/list/documentTemplateRouteActions',
  taskTypes: '/json/list/taskTypes',
  taskList: '/json/list/tasks',
  clientAccessLinkTypes: '/json/list/clientAccessLinkTypes',
  caList: '/supportedCertificateAuthority',
})

export default DirectoryPlugin

export {
  fillDirectory,
  getDirectory,
  pushDirectoryItem,
  updateDirectoryItem,
  removeDirectoryItem,
  clearDirectory,
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    // TODO
    // @ts-expect-error
    $directory: DirectoryInstance<DirectoryName>
  }
}
