import { useVuelidate } from '@vuelidate/core'
import * as validators from '@vuelidate/validators'

const t = (path, v$) => {
  console.group('validation', [path], [v$])
  const validator = path.split('.')[1]
  const messages = {
    required: 'Обов`язкове поле',
    requiredIf: 'Обов`язкове поле',
    or: 'Обов`язкове поле',
    and: 'Обов`язкове поле',
    minLength: `Мінімальна довжина поля: ${v$.min} символів`,
    maxLength: `Максимальна довжина поля: ${v$.max} символів`,
    minValue: `Мінімальне значення: ${v$.min}`,
    maxValue: `Максимальне значення: ${v$.max}`,
    phone: 'Номер недопустимого формату',
    email: 'Пошта недопустимого формату',
    sameAs: 'Паролі повинні співпадати',
  }
  console.groupEnd()
  return messages[validator]
}

const withI18nMessage = validators.createI18nMessage({ t })

const required = withI18nMessage(validators.required)
const requiredIf = withI18nMessage(validators.requiredIf, {
  withArguments: true,
})
const minLength = withI18nMessage(validators.minLength, { withArguments: true })
const minValue = withI18nMessage(validators.minValue, { withArguments: true })
const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
const maxValue = withI18nMessage(validators.maxValue, { withArguments: true })
const email = withI18nMessage(validators.email)
const sameAs = withI18nMessage(validators.sameAs, { withArguments: true })
const or = withI18nMessage(validators.or, { withArguments: true })
const and = withI18nMessage(validators.and, { withArguments: true })

const phone = withI18nMessage(val => {
  return val.replace('_', '').length === 23
})

export {
  useVuelidate,
  required,
  requiredIf,
  minLength,
  minValue,
  maxLength,
  maxValue,
  phone,
  email,
  or,
  and,
  sameAs,
}
