<template>
  <div>
    <v-data-table
      :items="dialog.dialogData.items"
      :headers="dialog.dialogData.headers"
      :items-per-page="-1">
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    dialog: {
      type: Object,
    },
  },
}
</script>
